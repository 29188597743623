import { Card } from "../../ui/card.jsx";
import { Label } from "../../ui/label.jsx";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group.jsx";

const MultipleChoice = ({ question, questionNumber, answers, setAnswers }) => {
  const handleOptionChange = (selectedOption) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question.id]: selectedOption,
    }));
  };

  const options = question.choices || [];

  return (
    <RadioGroup
      onValueChange={handleOptionChange}
      value={answers[question.id] || ""}
      className="space-y-3"
    >
      {options.map((option, index) => (
        <Card
          key={index}
          className={`border border-primary-400 flex items-center space-x-3 p-4 cursor-pointer hover:bg-primary-50 transition-colors ${
            answers[question.id] === option
              ? "bg-primary-100 border-primary-600"
              : ""
          }`}
          onClick={() => handleOptionChange(option)}
        >
          <RadioGroupItem
            value={option}
            id={`option-${questionNumber}-${index}`}
            className="w-5 h-5 border-2 border-primary-300 text-primary-600"
            checked={answers[question.id] === option}
          />
          <Label
            htmlFor={`option-${questionNumber}-${index}`}
            className="flex-grow text-lg font-medium text-primary-800 cursor-pointer"
          >
            {option}
          </Label>
        </Card>
      ))}
    </RadioGroup>
  );
};

export default MultipleChoice;

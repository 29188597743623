import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Contexts/userContext";
import api from "../../../api";
import { Dialog, DialogContent, DialogTrigger } from "../../ui/dialog.jsx";
import { Button } from "../../ui/button.jsx";
import { Plus, Pencil } from "lucide-react";
import CourseForm from './CourseForm';
import SuccessDialog from './SubDialogs/SuccessDialog';
import DeleteConfirmationDialog from './SubDialogs/DeleteConfirmationDialog';
import DeletionSuccessDialog from './SubDialogs/DeletionSuccessDialog';
import generateEnrollmentCode from "./EnrollmentCodeGenerator";

const CourseManagerPopup = ({ onAction, course }) => {
  const { user, courses } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showDeleteWindow, setShowDeleteWindow] = useState(false);
  const [showConfirmationWindow, setShowConfirmationWindow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    const { title, description, timezone, colorTheme } = formData;
    const enrollmentCode = generateEnrollmentCode();
    const instructorName = `${user.pronouns} ${user.lastName}`;

    try {
      if (course) {
        await api.put(`/courses/${course.id}`, {
          title,
          description,
          timezone,
          colorTheme,
        });
      } else {
        await api.post("/courses", {
          title,
          description,
          timezone,
          colorTheme,
          user,
          enrollmentCode,
          instructorName,
        });
      }
      await onAction();
      setIsSuccess(true);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      return {
        type: "error",
        message: "An error occurred. Please try again.",
      };
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    const studentIds = course.enrolledStudents.map(
      (ref) => ref._path.segments[1]
    );
    const parsedIds = JSON.stringify(studentIds);
    const ownerId = course.owner;
    try {
      await api.delete(`/courses/${course.id}`, {
        data: {
          userId: ownerId,
          roster: parsedIds,
        },
      });
    } catch (error) {
      console.error("Error deleting course, ", error);
    }
    setShowConfirmationWindow(true);
    setShowDeleteWindow(false);
    setDeleting(false);
  };

  const handleNavigate = async () => {
    await onAction();
    navigate("/homepage");
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(open) => setIsOpen(open)}
      >
        <DialogTrigger asChild>
          <Button className="bg-primary text-primary-foreground hover:bg-primary/90">
            {course ? (
              <Pencil className="mr-2 h-4 w-4" />
            ) : (
              <Plus className="mr-2 h-4 w-4" />
            )}{" "}
            {course ? "Edit Course" : "Create Course"}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[600px]">
          {!isSuccess && (
            <CourseForm
              course={course}
              onSubmit={handleSubmit}
              onDelete={() => setShowDeleteWindow(true)}
              excludedColorThemes={courses.map((c) => c.colorTheme)}
            />
          )}
        </DialogContent>
      </Dialog>

      <DeleteConfirmationDialog
        isOpen={showDeleteWindow}
        onClose={() => setShowDeleteWindow(false)}
        onConfirm={handleDelete}
        isDeleting={deleting}
      />

      <DeletionSuccessDialog
        isOpen={showConfirmationWindow}
        onClose={handleNavigate}
      />

      <SuccessDialog
        isOpen={isSuccess}
        onClose={() => setIsSuccess(false)}
        title={course ? "Course updated successfully!" : "Course Created Successfully!"}
        message={course ? "" : `Your new course has been added to your dashboard.`}
      />
    </>
  );
};

export default CourseManagerPopup;

import { useEffect, useState } from "react";
import { logCopyPaste } from "./CheatingDetection/CopyPaste";
import { logTabSwitch } from "./CheatingDetection/TabSwitch";

const useCheatingDetection = (userId, assignmentId, questionId) => {
  const [lastCutCopiedContent, setLastCutCopiedContent] = useState("");
  const [recentTabSwitch, setRecentTabSwitch] = useState(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        const timestamp = Date.now();
        setRecentTabSwitch(timestamp);
        logTabSwitch(userId, assignmentId, questionId);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userId, assignmentId, questionId]);

  const handleCutCopy = (event) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setLastCutCopiedContent(range.toString());
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    if (pastedText !== lastCutCopiedContent) {
      logCopyPaste(
        pastedText,
        Date.now(),
        recentTabSwitch,
        userId,
        assignmentId,
        questionId
      );
    }
  };

  return {
    lastCutCopiedContent,
    setLastCutCopiedContent,
    recentTabSwitch,
    setRecentTabSwitch,
    handleCutCopy,
    handlePaste,
  };
};

export default useCheatingDetection;

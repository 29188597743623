import { Card, CardContent } from "../../../ui/card.jsx";
import GradingForm from "./GradingForm.js";
import QuestionDetails from "./QuestionDetails.js";

const GradingColumn = ({
  question,
  questionIntegrityScore,
  report,
  chatLogs,
  onOpenTimelineDialog,
  onOpenChatLogPopup,
  onOpenVersionHistory,
  questionId,
  score,
  maxPoints,
  comment,
  onScoreChange,
  onCommentChange,
  assignmentId,
  userId,
}) => {
  return (
    <Card className="flex flex-col h-full">
      <CardContent className="flex-grow flex flex-col space-y-6">
        <div className="flex-shrink-0">
          <QuestionDetails
            question={question}
            questionIntegrityScore={questionIntegrityScore}
            report={report}
            chatLogs={chatLogs}
            onOpenTimelineDialog={onOpenTimelineDialog}
            onOpenChatLogPopup={onOpenChatLogPopup}
            onOpenVersionHistory={onOpenVersionHistory}
          />
        </div>
        <div className="flex-grow flex flex-col">
          <h3 className="text-lg font-semibold mb-2">Grading</h3>
          <div className="flex-grow">
            <GradingForm
              questionId={questionId}
              score={score}
              maxPoints={maxPoints}
              comment={comment}
              onScoreChange={onScoreChange}
              onCommentChange={onCommentChange}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default GradingColumn;

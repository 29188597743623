import api from "../../../api";

export const saveVersion = async (
  userId,
  assignmentId,
  questionId,
  content
) => {
  if (!content) {
    content = "";
  }
  const timestamp = Date.now();
  const versionId = `${userId}_${assignmentId}`;
  try {
    const [existenceResponse] = await Promise.all([
      api.get(`/versions/${versionId}/exists`, {
        params: { questionId },
      }),
    ]);
    const exists = existenceResponse.data.exists;
    const saveRequest = exists
      ? api.put(`/versions/${versionId}`, {
          questionId,
          content,
          timestamp,
        })
      : api.post(`/versions`, {
          userId,
          assignmentId,
          questionId,
          content,
          timestamp,
        });

    await saveRequest;
  } catch (error) {
    console.error("Error saving version: ", error);
  }
};

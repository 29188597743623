const extractCourseIdFromReference = (reference) => {
  if (reference) {
    const pathSegment = reference._path.segments.slice(-1)[0];
    return pathSegment;
  }
};

export const createStudentCourseMap = (courses, allStudents) => {
  const courseIds = courses.map((course) => course.id);
  return allStudents.reduce((map, student) => {
    const matchingCourses = student.enrollments
      .map(extractCourseIdFromReference)
      .filter((courseId) => courseId && courseIds.includes(courseId));

    if (matchingCourses.length > 0) {
      map[student.uid] = matchingCourses;
    }
    return map;
  }, {});
};

import { useEffect, useState } from "react";
import { Button } from "../../ui/button.jsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Slider } from "../../ui/slider.jsx";

import { diffChars } from "diff";
import DOMPurify from "dompurify";
import he from "he";
import api from "../../../api";

const VersionDisplay = ({ versionId, questionId, isOpen, onClose }) => {
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [diffContent, setDiffContent] = useState("");
  const [pastedSegments, setPastedSegments] = useState([]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const versionResponse = await api.get(`/versions/${versionId}`, {
          params: { questionId },
        });
        setVersions(
          versionResponse.data.map((version) => ({
            ...version,
            timestamp: new Date(version.timestamp),
          }))
        );
        const copyPasteResponse = await api.get(
          `/reports/${versionId}/copyPasteEvents`,
          {
            params: { questionId },
          }
        );
        if (copyPasteResponse.data && copyPasteResponse.data.length > 0) {
          const pastedSegmentsArray = copyPasteResponse.data.map(
            (event) => event.pastedSegment
          );
          setPastedSegments(pastedSegmentsArray);
        }
      } catch (error) {
        console.error("Error fetching versions: ", error);
      }
    };

    fetchVersions();
  }, [versionId, questionId]);

  useEffect(() => {
    if (versions.length > 0) {
      const currentContent = versions[currentVersion]?.content || "";
      const previousContent = versions[currentVersion - 1]?.content || "";
      const sanitizedCurrent = he.decode(DOMPurify.sanitize(currentContent));
      const sanitizedPrevious = he.decode(DOMPurify.sanitize(previousContent));
      const diff = diffChars(sanitizedPrevious, sanitizedCurrent);

      const formattedDiff = diff
        .map((part) => {
          if (part.added) {
            let highlightedValue = part.value;
            pastedSegments.forEach((segment) => {
              const cleanedSegment = segment
                .trim()
                .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
              const regex = new RegExp(
                cleanedSegment.split(/\s+/).join("\\s*"),
                "gi"
              );
              highlightedValue = highlightedValue.replace(
                regex,
                (match) => `<span class="bg-yellow-200">${match}</span>`
              );
            });
            return `<span class="bg-green-200">${highlightedValue}</span>`;
          } else if (part.removed) {
            return `<span class="bg-red-200">${part.value}</span>`;
          }
          return part.value;
        })
        .join("");

      setDiffContent(formattedDiff);
    }
  }, [versions, currentVersion, pastedSegments]);

  const handleSliderChange = (value) => {
    setCurrentVersion(value[0]);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px] max-h-[80vh] overflow-hidden flex flex-col">
        <DialogHeader>
          <DialogTitle>Document Version History</DialogTitle>
        </DialogHeader>
        <div className="mt-4 flex-grow overflow-auto">
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Document History</h3>
            <div className="flex items-center space-x-4">
              <Slider
                min={0}
                max={versions.length - 1}
                step={1}
                value={[currentVersion]}
                onValueChange={handleSliderChange}
                className="w-64"
              />
              <span>
                {versions[currentVersion]?.timestamp.toLocaleString()}
              </span>
            </div>
            <div className="mt-2 space-x-2">
              <Button onClick={() => setCurrentVersion(versions.length - 1)}>
                Latest Version
              </Button>
              <Button
                onClick={() =>
                  setCurrentVersion((prev) => Math.max(0, prev - 1))
                }
                disabled={currentVersion === 0}
              >
                Previous
              </Button>
              <Button
                onClick={() =>
                  setCurrentVersion((prev) =>
                    Math.min(versions.length - 1, prev + 1)
                  )
                }
                disabled={currentVersion === versions.length - 1}
              >
                Next
              </Button>
            </div>
            <div className="mt-4">
              <h4 className="font-semibold">Version Changes:</h4>
              <div className="mt-2 mb-4 flex space-x-4">
                <span className="inline-flex items-center">
                  <span className="w-4 h-4 inline-block bg-green-200 mr-1"></span>
                  Added text
                </span>
                <span className="inline-flex items-center">
                  <span className="w-4 h-4 inline-block bg-red-200 mr-1"></span>
                  Deleted text
                </span>
                <span className="inline-flex items-center">
                  <span className="w-4 h-4 inline-block bg-yellow-200 mr-1"></span>
                  Pasted text
                </span>
              </div>
              <div
                className="bg-gray-100 p-4 rounded mt-2"
                dangerouslySetInnerHTML={{ __html: diffContent }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VersionDisplay;

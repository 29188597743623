import { useParams } from "react-router-dom";
import BaseLayout from "../../components/layout/BaseLayout";
import NavigationLayout from "../../components/layout/NavigationLayout";
import AssignmentSubmissions from "../../components/teacher/Lists/SubmissionLists/AssignmentSubmissions";
import { useUser } from "../../Contexts/userContext";

const AllSubsPage = () => {
  const { courseId, assignmentId } = useParams();
  const { courses, assignments } = useUser();
  const course = courses.find((course) => course.id === courseId);
  const assignment = assignments.find(
    (assignment) => assignment.id === assignmentId
  );
  return (
    <div>
      <BaseLayout>
        <NavigationLayout>
          <div style={{ height: "60px" }}></div>
          <AssignmentSubmissions assignment={assignment} course={course} />
        </NavigationLayout>
      </BaseLayout>
    </div>
  );
};

export default AllSubsPage;

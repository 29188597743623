import { ChevronRight, Edit, FileText } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ListCard from "../../shared/ListCard";
import { convertToDate } from "../../shared/utils/Timestamp";
import { Button } from "../../ui/button";
import { Progress } from "../../ui/progress";

const CourseAssignmentList = ({ assignments, course, loading, error }) => {
  const renderAssignmentItem = (assignment) => (
    <div className="group hover:bg-primary/5 p-4 rounded-md transition-all duration-300">
      <div className="flex items-center space-x-4">
        <div className="bg-primary/10 p-3 rounded-full group-hover:bg-primary/20 transition-colors">
          <FileText className="h-6 w-6 text-primary" />
        </div>
        <div className="flex-grow">
          <h3 className="font-semibold text-lg group-hover:text-primary transition-colors">
            <Link to={`/assignment/submissions/${course.id}/${assignment.id}`}>
              {assignment.title}
            </Link>
          </h3>
          <p className="text-sm text-muted-foreground">
            Due: {convertToDate(assignment.dueDate)}
          </p>
          {assignment.progress && (
            <div className="mt-2">
              <Progress value={assignment.progress} className="h-2" />
              <p className="text-xs text-muted-foreground mt-1">
                {assignment.progress}% complete
              </p>
            </div>
          )}
        </div>
        <div className="flex space-x-2">
          <Button variant="outline" size="sm" asChild>
            <Link to={`/assignment/submissions/${course.id}/${assignment.id}`}>
              <FileText className="mr-2 h-4 w-4" />
              View
            </Link>
          </Button>
          <Button variant="outline" size="sm" asChild>
            <Link to={`/assignment/${assignment.id}/course/${course.id}/edit`}>
              <Edit className="mr-2 h-4 w-4" />
              Edit
            </Link>
          </Button>
        </div>
        <ChevronRight className="text-muted-foreground group-hover:text-primary transition-colors" />
      </div>
    </div>
  );

  return (
    <ListCard
      title="Assignments"
      items={assignments}
      isLoading={loading}
      error={error}
      renderItem={renderAssignmentItem}
      emptyMessage="No assignments created yet."
      icon={FileText}
      maxHeight={400}
    />
  );
};

export default CourseAssignmentList;

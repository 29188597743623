import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel.jsx";
import CourseCardSkeleton from "./CourseCardSkeleton";
import StudentCourseCard from "./StudentCourseCard";
import TeacherCourseCard from "./TeacherCourseCard";

const CourseCarousel = ({ courses, onCourseClick, role, loading }) => {
  const skeletonCount = 3;

  return (
    <div className="w-full max-w-7xl mx-auto px-4">
      <Carousel className="w-full">
        <CarouselContent className="-ml-4">
          {loading
            ? Array(skeletonCount)
                .fill()
                .map((_, index) => (
                  <CarouselItem
                    key={`skeleton-${index}`}
                    className="pl-4 basis-full sm:basis-1/2 lg:basis-1/3"
                  >
                    <div className="aspect-square w-full">
                      <CourseCardSkeleton />
                    </div>
                  </CarouselItem>
                ))
            : courses.map((course) => (
                <CarouselItem
                  key={course.id}
                  className="pl-4 basis-full sm:basis-1/2 lg:basis-1/3"
                >
                  <div className="aspect-square w-full">
                    {role === "student" && (
                      <StudentCourseCard
                        course={course}
                        onClick={onCourseClick}
                      />
                    )}
                    {role === "teacher" && (
                      <TeacherCourseCard
                        course={course}
                        onClick={onCourseClick}
                      />
                    )}
                  </div>
                </CarouselItem>
              ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default CourseCarousel;

import SubmissionTable from "./SubmissionTable";

const GradedTable = ({ submissions, lateSubmissions, userId }) => (
  <SubmissionTable
    submissions={submissions}
    title="Graded Submissions"
    showGrade={true}
    lateSubmissions={lateSubmissions}
    userId={userId}
  />
);

export default GradedTable;

import { ChevronLeft, ChevronRight } from "lucide-react";
import { useState } from "react";
import { Button } from "../ui/button.jsx";
import { Card, CardContent, CardHeader } from "../ui/card.jsx";

const DummyCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const currentYear = currentDate.getFullYear();

  const daysInMonth = new Date(
    currentYear,
    currentDate.getMonth() + 1,
    0
  ).getDate();
  const firstDayOfMonth = new Date(
    currentYear,
    currentDate.getMonth(),
    1
  ).getDay();

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const placeholderDays = Array.from(
    { length: firstDayOfMonth },
    (_, i) => null
  );

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentYear, currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentYear, currentDate.getMonth() + 1, 1));
  };

  return (
    <Card className="w-full h-full flex flex-col">
      <CardHeader className="bg-primary text-white p-4 rounded-lg">
        <div className="flex justify-between items-center">
          <Button
            variant="ghost"
            size="icon"
            className="text-white hover:bg-primary"
            onClick={handlePrevMonth}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <h2 className="text-xl font-bold text-center flex-grow">{`${currentMonth} ${currentYear}`}</h2>
          <Button
            variant="ghost"
            size="icon"
            className="text-white hover:bg-primary"
            onClick={handleNextMonth}
          >
            <ChevronRight className="h-6 w-6" />
          </Button>
        </div>
      </CardHeader>
      <CardContent className="flex-grow p-4 overflow-auto">
        <div className="grid grid-cols-7 gap-2 text-center h-full">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <div
              key={day}
              className="text-gray-500 text-[max(1vw,12px)] font-medium"
            >
              {day}
            </div>
          ))}
          {placeholderDays.map((_, index) => (
            <div key={`placeholder-${index}`} className="p-2"></div>
          ))}
          {days.map((day) => (
            <div
              key={day}
              className={`p-2 text-[max(1vw,12px)] aspect-square flex items-center justify-center ${
                day === currentDate.getDate()
                  ? "bg-primary text-white rounded-full"
                  : "hover:bg-gray-100 cursor-pointer"
              }`}
            >
              {day}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default DummyCalendar;

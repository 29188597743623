import { useEffect, useState } from "react";
import api from "../../../api";
import { Button } from "../../ui/button.jsx";
import CustomCarousel from "./CustomCarousel";
import CarouselSkeletonLoader from "./Helpers/SkeletonLoader";
import SubmissionCard from "./SubmissionCard";

const SubmissionCarousel = ({ userId, assignment, course }) => {
  const courseId = course.id;
  const assignmentId = assignment.id;
  const [submissions, setSubmissions] = useState([]);
  const [studentDataLoading, setStudentDataLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        setStudentDataLoading(true);
        const rosterResponse = await api.get(`/courses/${courseId}/roster`);
        const fetchedRoster = rosterResponse.data;

        fetchedRoster.sort((a, b) => a.lastName.localeCompare(b.lastName));

        const rosterIds = fetchedRoster.map((student) => student.uid);

        const submittedResponse = await api.get(
          `/assignments/${assignmentId}/submitted`,
          {
            params: { roster: rosterIds },
          }
        );
        const submittedStudentIds = submittedResponse.data;
        const [
          gradesResponse,
          submissionsResponse,
          reportsResponse,
          chatLogsResponse,
        ] = await Promise.all([
          Promise.all(
            submittedStudentIds.map(async (studentId) => {
              const gradeResponse = await api.get(
                `/grades/assignment/${assignmentId}/user/${studentId}`
              );
              const grade = gradeResponse.data || {};

              let questionMap = {};
              if (grade.questions) {
                questionMap = Object.fromEntries(
                  grade?.questions?.map((question) => [
                    question.questionId,
                    {
                      score: question.score,
                      comment: question.comment,
                    },
                  ])
                );
              }
              return [studentId, questionMap];
            })
          ),
          Promise.all(
            submittedStudentIds.map((studentId) =>
              api.get(`/submissions/${studentId}_${assignmentId}`)
            )
          ),
          Promise.all(
            submittedStudentIds.map((studentId) =>
              api.get(`/reports/${studentId}_${assignmentId}`)
            )
          ),
          Promise.all(
            submittedStudentIds.map((studentId) =>
              api.get(`/chatLogs/${studentId}_${assignmentId}`)
            )
          ),
        ]);

        const grades = Object.fromEntries(gradesResponse);
        const submissions = Object.fromEntries(
          submittedStudentIds.map((id, index) => [
            id,
            submissionsResponse[index].data,
          ])
        );
        const reports = Object.fromEntries(
          submittedStudentIds.map((id, index) => [
            id,
            reportsResponse[index].data,
          ])
        );

        const chatLogs = Object.fromEntries(
          submittedStudentIds.map((id, index) => [
            id,
            chatLogsResponse[index].data,
          ])
        );

        const submissionsArray = fetchedRoster
          .filter((student) => submittedStudentIds.includes(student.uid))
          .map((studentData) => {
            const studentId = studentData.uid;
            return {
              studentId,
              grade: grades[studentId] || null,
              submission: submissions[studentId],
              report: reports[studentId],
              studentDetails: studentData,
              chatLogs: chatLogs,
            };
          });

        setSubmissions(submissionsArray);
        setStudentDataLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError(error);
        setStudentDataLoading(false);
      }
    };

    fetchStudentData();
  }, [courseId, assignmentId, userId]);

  const handleGradeChange = (studentId, updatedGrade) => {
    setUnsavedChanges((prev) => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        ...updatedGrade,
      },
    }));
  };

  const submitAllGrades = async () => {
    setSubmitting(true);
    try {
      const submissionPromises = Object.keys(unsavedChanges).map((studentId) =>
        handleSaveGrade(studentId)
      );

      await Promise.all(submissionPromises);
    } catch (error) {
      console.error("Error submitting all grades:", error);
    } finally {
      setUnsavedChanges({});
      setSubmitting(false);
    }
  };

  const handleSaveGrade = async (studentId) => {
    try {
      const scoresObject = {};
      const commentsObject = {};
      const gradeData = unsavedChanges[studentId];
      const questions = assignment.questions;
      questions.forEach((question) => {
        if (gradeData[question.id]?.score) {
          scoresObject[question.id] = parseInt(gradeData[question.id]?.score);
        }
        if (
          gradeData[question.id]?.comment &&
          gradeData[question.id]?.comment.trim() !== ""
        ) {
          commentsObject[question.id] = gradeData[question.id]?.comment;
        }
      });
      await api.post("/grades", {
        userId: studentId,
        assignmentId,
        questions,
        scores: scoresObject,
        comments: commentsObject,
      });

      setUnsavedChanges((prev) => {
        const { [studentId]: _, ...rest } = prev;
        return Object.keys(rest).length > 0 ? rest : {};
      });

      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission.studentId === studentId
            ? { ...submission, grade: gradeData }
            : submission
        )
      );
    } catch (error) {
      console.error("Error saving grades:", error);
    }
  };

  if (studentDataLoading || !assignment || !course) {
    return <CarouselSkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="max-w-6xl mx-auto py-8 px-4 sm:px-6 lg:px-8 w-full">
      <div className="flex items-center justify-between mb-6">
        <div className="w-1/4"></div>
        <h1 className="text-3xl font-bold text-primary text-center flex-grow">
          Submissions for {assignment.title}
        </h1>
        <div className="w-1/4 flex justify-end">
          <Button
            onClick={submitAllGrades}
            disabled={Object.keys(unsavedChanges).length === 0 || submitting}
            className="whitespace-nowrap"
          >
            {submitting
              ? "Submitting..."
              : Object.keys(unsavedChanges).length === 0
              ? "Submit Changes"
              : `Submit All Changes (${Object.keys(unsavedChanges).length})`}
          </Button>
        </div>
      </div>
      <CustomCarousel
        items={submissions}
        initialItemId={userId}
        getItemId={(item) => item.studentId}
        renderItem={(data) => (
          <SubmissionCard
            assignment={assignment}
            submission={data.submission}
            report={data.report || {}}
            chatLogs={data.chatLogs[data.studentId] || {}}
            initialGrade={data.grade}
            student={data.studentDetails}
            onGradeChange={handleGradeChange}
            onSaveGrade={handleSaveGrade}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      />
    </div>
  );
};

export default SubmissionCarousel;

import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../../ui/button.jsx";

const CustomCarousel = ({ items, initialItemId, renderItem, getItemId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const initialIndex = items.findIndex(
        (item) => getItemId(item) === initialItemId
      );
      if (initialIndex !== -1) {
        setCurrentIndex(initialIndex);
      }
      setIsInitialized(true);
    }
  }, [items, initialItemId, getItemId, isInitialized]);

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : items.length - 1
    );
  }, [items.length]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex < items.length - 1 ? prevIndex + 1 : 0
    );
  }, [items.length]);

  return (
    <div className="relative max-w-8xl mx-auto">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {items.map((item, index) => (
            <div key={getItemId(item)} className="w-full flex-shrink-0">
              {renderItem(item)}
            </div>
          ))}
        </div>
      </div>
      <Button
        onClick={goToPrevious}
        className="absolute top-1/2 -left-16 transform -translate-y-1/2"
        variant="default"
        size="lg"
        disabled={currentIndex === 0}
      >
        <ChevronLeft className="h-6 w-6" />
      </Button>
      <Button
        onClick={goToNext}
        className="absolute top-1/2 -right-16 transform -translate-y-1/2"
        variant="default"
        size="lg"
        disabled={currentIndex + 1 === items.length}
      >
        <ChevronRight className="h-6 w-6" />
      </Button>
      <div className="text-center mt-4">
        <p className="text-sm text-gray-500">
          Item {currentIndex + 1} of {items.length}
        </p>
      </div>
    </div>
  );
};

export default CustomCarousel;

import api from "../../../../api";

export const logTabSwitch = async (userId, assignmentId, questionId) => {
  const reportId = `${userId}_${assignmentId}`;
  try {
    const createResponse = await api.post("/reports", {
      userId,
      assignmentId,
      questionId,
    });
    const logResponse = await api.post(`/reports/${reportId}/tabSwitchEvents`, {
      questionId,
    });
    return logResponse.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
    }
    throw error;
  }
};

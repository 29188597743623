import api from "../api";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const token = await firebaseUser.getIdToken();
          localStorage.setItem("authToken", token);

          // If it's a new user, don't fetch user data yet
          if (!isNewUser) {
            const response = await api.get("/auth/user");
            setUser(response.data.user);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          if (!isNewUser) {
            setUser(null);
            localStorage.removeItem("authToken");
          }
        }
      } else {
        setUser(null);
        localStorage.removeItem("authToken");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [isNewUser]);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      localStorage.setItem("authToken", token);
      const response = await api.get("/auth/user");
      setUser(response.data.user);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signup = async (
    firstName,
    lastName,
    email,
    password,
    role,
    gradeLevel = null,
    pronouns = null
  ) => {
    setLoading(true);
    setIsNewUser(true);
    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;

      // Get the ID token
      const token = await firebaseUser.getIdToken();
      localStorage.setItem("authToken", token);

      // Send additional user data to your backend
      const response = await api.post("/auth/register", {
        firstName,
        lastName,
        email,
        role,
        gradeLevel,
        pronouns,
        uid: firebaseUser.uid,
      });

      setUser(response.data.user);
    } catch (error) {
      console.error("Signup error:", error);
      throw error;
    } finally {
      setLoading(false);
      setIsNewUser(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await api.post("/auth/logout");
      await signOut(auth);
      localStorage.removeItem("authToken");
      setUser(null);
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { user, loading, login, signup, logout };
};

export default useAuth;

import { ClipboardList, FileCheck, FileX } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../../api";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card.jsx";
import { Skeleton } from "../../ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs.jsx";
import GradedTable from "./Tables/GradedTable";
import UngradedTable from "./Tables/UngradedTable";

const CourseSubmissions = ({ submissions, assignments, loading, userId }) => {
  const [error, setError] = useState(null);
  const [grades, setGrades] = useState([]);

  const assignmentMap = useMemo(
    () => new Map(assignments.map((assignment) => [assignment.id, assignment])),
    [assignments]
  );

  const fetchGrades = useCallback(async () => {
    try {
      const gradePromises = submissions.map((submission) =>
        api.get(`/grades/assignment/${submission.assignmentId}/user/${userId}`)
      );
      const responses = await Promise.all(gradePromises);
      const fetchedGrades = responses
        .map((response) => response.data)
        .filter(Boolean);
      setGrades(fetchedGrades);
    } catch (error) {
      console.error("Error fetching grades:", error);
      setError("Failed to fetch grades. Please try again later.");
    }
  }, [submissions]);

  useEffect(() => {
    if (!loading) {
      fetchGrades();
    }
  }, [fetchGrades, loading]);

  const { gradedSubmissions, ungradedSubmissions, lateSubmissions } =
    useMemo(() => {
      const graded = [];
      const ungraded = [];
      const late = [];
      const gradedMap = new Map(
        grades
          .filter((grade) => grade.published)
          .map((grade) => [grade.assignmentId, grade])
      );
      for (const submission of submissions) {
        const key = submission.assignmentId;
        const assignment = assignmentMap.get(submission.assignmentId);
        const grade = gradedMap.get(key);
        const totalScore = grade ? grade.totalScore : null;

        const formattedSubmission = {
          ...submission,
          assignmentTitle: assignment.title,
          assignmentId: assignment.id,
          totalPoints: assignment.totalPoints,
          dueDate: assignment.dueDate,
          totalScore,
        };

        if (gradedMap.has(key)) {
          graded.push(formattedSubmission);
        } else {
          ungraded.push(formattedSubmission);
        }

        if (assignment && assignment.dueDate < submission.timestamp) {
          late.push(formattedSubmission);
        }
      }
      return {
        gradedSubmissions: graded,
        ungradedSubmissions: ungraded,
        lateSubmissions: late,
      };
    }, [submissions, grades, assignmentMap]);

  return (
    <div className="container mx-auto py-8 px-4">
      <Card className="w-full mx-auto shadow-lg rounded-lg overflow-hidden">
        <CardHeader className="bg-primary text-primary-foreground p-6">
          <CardTitle className="flex items-center text-2xl font-bold">
            <FileCheck className="mr-2 h-6 w-6" aria-hidden="true" />
            Submissions
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="graded" className="w-full">
            <TabsList className="grid w-full grid-cols-2 mb-6">
              <TabsTrigger
                value="graded"
                className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground font-semibold"
              >
                <ClipboardList className="h-4 w-4" aria-hidden="true" />
                Graded
              </TabsTrigger>
              <TabsTrigger
                value="ungraded"
                className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground font-semibold"
              >
                <FileX className="h-4 w-4" aria-hidden="true" />
                Ungraded
              </TabsTrigger>
            </TabsList>
            <TabsContent value="graded">
              {loading ? (
                <SkeletonLoader />
              ) : error ? (
                <div className="text-center py-4 text-red-500">
                  Error: {error}
                </div>
              ) : (
                <GradedTable
                  submissions={gradedSubmissions}
                  assignments={assignments}
                  lateSubmissions={lateSubmissions}
                  userId={userId}
                />
              )}
            </TabsContent>
            <TabsContent value="ungraded">
              {loading ? (
                <SkeletonLoader />
              ) : error ? (
                <div className="text-center py-4 text-red-500">
                  Error: {error}
                </div>
              ) : (
                <UngradedTable
                  submissions={ungradedSubmissions}
                  lateSubmissions={lateSubmissions}
                  userId={userId}
                />
              )}
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

export default CourseSubmissions;

const SkeletonLoader = () => (
  <div className="space-y-4">
    <Skeleton className="h-12 w-3/4" />
    <Skeleton className="h-4 w-full" />
    <Skeleton className="h-4 w-full" />
    <Skeleton className="h-4 w-3/4" />
  </div>
);

import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../Contexts/userContext";
import api from "../api";
import { Button } from "../components/ui/button.jsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog.jsx";

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const WARNING_DURATION = 60 * 1000; // 60 seconds
const SERVER_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes
const SERVER_CHECK_INTERVAL = 1 * 60 * 1000; // 1 minute

const ActivityTracker = ({ children }) => {
  const [showWarning, setShowWarning] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useUser();
  const lastActivityTime = useRef(Date.now());
  const serverUpdateTimer = useRef(null);
  const serverCheckTimer = useRef(null);
  const initialActivityLoggedRef = useRef(false);

  const handleLogout = useCallback(async () => {
    await logout();
    navigate("/login?sessionExpired=true");
  }, [logout, navigate]);

  const resetTimer = useCallback(() => {
    setShowWarning(false);
    lastActivityTime.current = Date.now();
  }, []);

  const updateServerActivity = useCallback(async () => {
    if (user?.uid) {
      try {
        await api.post(`/users/${user.uid}/updateActivity`, {
          timestamp: Date.now(),
        });
      } catch (error) {
        console.error("Failed to update server activity:", error);
      }
    }
  }, [user?.uid]);

  const checkServerActivity = useCallback(async () => {
    if (user?.uid) {
      try {
        const response = await api.get(`/users/${user.uid}/recentActivity`);
        const serverLastActivity = parseInt(response.data);
        if (Date.now() - serverLastActivity > INACTIVITY_TIMEOUT) {
          handleLogout();
        }
      } catch (error) {
        console.error("Failed to check server activity:", error);
      }
    }
  }, [user?.uid, handleLogout]);

  useEffect(() => {
    let warningTimer;
    let logoutTimer;

    if (!initialActivityLoggedRef.current) {
      updateServerActivity();
      initialActivityLoggedRef.current = true;
    }

    const startTimers = () => {
      clearTimeout(warningTimer);
      clearTimeout(logoutTimer);

      warningTimer = setTimeout(() => {
        setShowWarning(true);

        logoutTimer = setTimeout(() => {
          setShowWarning(false);
          handleLogout();
        }, WARNING_DURATION);
      }, INACTIVITY_TIMEOUT - WARNING_DURATION);
    };

    startTimers();

    serverUpdateTimer.current = setInterval(
      updateServerActivity,
      SERVER_UPDATE_INTERVAL
    );

    serverCheckTimer.current = setInterval(
      checkServerActivity,
      SERVER_CHECK_INTERVAL
    );

    const activityEvents = ["mousemove", "keypress", "click", "scroll"];
    const handleActivity = () => {
      if (!showWarning) {
        resetTimer();
        startTimers();
      }
    };

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      clearTimeout(warningTimer);
      clearTimeout(logoutTimer);
      clearInterval(serverUpdateTimer.current);
      clearInterval(serverCheckTimer.current);
    };
  }, [
    handleLogout,
    showWarning,
    resetTimer,
    updateServerActivity,
    checkServerActivity,
  ]);

  return (
    <>
      {children}
      <Dialog open={showWarning} onOpenChange={(open) => !open && resetTimer()}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Session Expiring</DialogTitle>
            <DialogDescription>
              Your session will expire in 60 seconds. Do you want to extend it?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={resetTimer}>Extend Session</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActivityTracker;

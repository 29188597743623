import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import { convertToDate } from "../../shared/utils/Timestamp";
import { Progress } from "../../ui/progress.jsx";
import { Skeleton } from "../../ui/skeleton.jsx";

export const renderAssignmentList = (assignments, icon) =>
  assignments.length > 0 ? (
    <ul className="space-y-4">
      {assignments.map((assignment, index) => (
        <li key={index} className="group">
          <Link to={`/assignment/${assignment.id}`} className="block">
            <div className="flex items-center space-x-4">
              <div className="bg-primary/10 p-3 rounded-full group-hover:bg-primary/20 transition-colors">
                {icon}
              </div>
              <div className="flex-grow">
                <h3 className="font-semibold text-lg group-hover:text-primary transition-colors">
                  {assignment.title}
                </h3>
                <p className="text-sm text-muted-foreground">
                  Due: {convertToDate(assignment.dueDate)}
                </p>
                {assignment.progress && (
                  <div className="mt-2">
                    <Progress value={assignment.progress} className="h-2" />
                    <p className="text-xs text-muted-foreground mt-1">
                      {assignment.progress}% complete
                    </p>
                  </div>
                )}
              </div>
              <ChevronRight className="text-muted-foreground group-hover:text-primary transition-colors" />
            </div>
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    <p className="text-muted-foreground text-center">
      No assignments in this category.
    </p>
  );

export const renderSkeletons = (count = 5) => (
  <div className="space-y-4">
    {[...Array(count)].map((_, i) => (
      <div key={i} className="flex items-center space-x-4">
        <Skeleton className="h-12 w-12 rounded-full" />
        <div className="flex-grow">
          <Skeleton className="h-5 w-3/4 mb-2" />
          <Skeleton className="h-4 w-1/2" />
        </div>
        <Skeleton className="h-6 w-6" />
      </div>
    ))}
  </div>
);

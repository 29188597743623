import { useMemo, useState } from "react";
import { useUser } from "../Contexts/userContext";
import { useAssignments } from "./useAssignments";
import { useSubmissions } from "./useSubmissions";

export const useCourseData = (courseId) => {
  const { user, courses, assignments } = useUser();
  const { getCourseSubmissions } = useSubmissions();
  const {
    getCourseAssignments,
    getUpcomingAssignments,
    getUnstartedAssignments,
    getInProgressAssignments,
  } = useAssignments();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const course = useMemo(
    () => courses.find((course) => course.id === courseId),
    [courses, courseId]
  );

  const courseAssignments = useMemo(
    () => getCourseAssignments(course, assignments),
    [course, assignments, getCourseAssignments]
  );

  const upcomingAssignments = useMemo(
    () => getUpcomingAssignments(course),
    [course, getUpcomingAssignments]
  );

  const unstartedAssignments = useMemo(
    () => getUnstartedAssignments(course),
    [course, getUnstartedAssignments]
  );

  const inProgressAssignments = useMemo(
    () => getInProgressAssignments(course),
    [course, getInProgressAssignments]
  );

  const courseSubmissions = useMemo(
    () => getCourseSubmissions(course),
    [course, getCourseSubmissions]
  );

  return {
    course,
    courseSubmissions,
    courseAssignments,
    upcomingAssignments,
    unstartedAssignments,
    inProgressAssignments,
    loading,
    error,
  };
};

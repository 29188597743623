import { Card, CardContent } from "../../ui/card";
import { Separator } from "../../ui/separator";
import { TabsContent } from "../../ui/tabs.jsx";
import {
  AnswerCard,
  CardHeaderComponent,
  FooterButtons,
  GradingColumnWrapper,
  OverviewTabContent,
  QuestionTabContent,
  TabsComponent,
} from "./CardSections";
import DialogComponents from "./Helpers/DialogComponents";
import useDialogs from "./Helpers/useDialogs";

const SharedCard = ({
  title,
  questions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  activeTab,
  setActiveTab,
  currentQuestion,
  answers,
  student,
  chatLogs,
  questionIntegrityScores,
  report,
  localGrade,
  handleScoreChange,
  handleCommentChange,
  scoreArray,
  handleQuestionJump,
  handleSaveGrade,
  saving,
  previouslySaved,
  assignmentId,
}) => {
  const {
    isTimelineDialogOpen,
    isChatLogPopupVisible,
    isVersionHistoryDialogOpen,
    chatLogId,
    selectedQuestionId,
    toggleTimelineDialog,
    toggleChatLogPopup,
    toggleVersionHistoryDialog,
  } = useDialogs();

  const studentName = student.firstName + " " + student.lastName;
  const reportId = `${student.uid}_${assignmentId}`;

  const percentQuestionsGraded = Object.keys(localGrade).length / questions.length; 

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <Card className="bg-background shadow-xl rounded-xl overflow-hidden border border-border">
        <CardHeaderComponent title={title} gradedPercent={percentQuestionsGraded}/>
        <CardContent className="p-6">
          <TabsComponent activeTab={activeTab} setActiveTab={setActiveTab}>
            <TabsContent value="question" className="mt-6">
              <QuestionTabContent
                currentQuestionIndex={currentQuestionIndex}
                totalQuestions={questions.length}
                onNavigate={setCurrentQuestionIndex}
              >
                <AnswerCard
                  question={currentQuestion}
                  answer={answers[currentQuestion.id]}
                  studentName={studentName}
                />
                <GradingColumnWrapper
                  question={currentQuestion}
                  questionIntegrityScore={
                    questionIntegrityScores[currentQuestion.id]
                  }
                  report={report}
                  chatLogs={chatLogs}
                  onOpenTimelineDialog={() =>
                    toggleTimelineDialog(currentQuestion.id)
                  }
                  onOpenChatLogPopup={() =>
                    toggleChatLogPopup(reportId, currentQuestion.id)
                  }
                  onOpenVersionHistory={() =>
                    toggleVersionHistoryDialog(currentQuestion.id)
                  }
                  questionId={currentQuestion.id}
                  score={localGrade[currentQuestion.id]?.score ?? null}
                  comment={localGrade[currentQuestion.id]?.comment || ""}
                  onScoreChange={handleScoreChange}
                  onCommentChange={handleCommentChange}
                  maxPoints={currentQuestion.points}
                />
              </QuestionTabContent>
            </TabsContent>
            <TabsContent value="overview" className="mt-6">
              <OverviewTabContent
                questions={questions}
                scores={scoreArray}
                onQuestionSelect={handleQuestionJump}
                questionIntegrityScores={questionIntegrityScores}
              />
            </TabsContent>
          </TabsComponent>
          <Separator className="my-8" />
          <FooterButtons
            onBackToFirst={() => setCurrentQuestionIndex(0)}
            onSave={handleSaveGrade}
            isSaveDisabled={saving || previouslySaved}
            saving={saving}
            previouslySaved={previouslySaved}
          />
        </CardContent>
      </Card>
      <DialogComponents
        reportId={reportId}
        selectedQuestionId={selectedQuestionId}
        isTimelineDialogOpen={isTimelineDialogOpen}
        setIsTimelineDialogOpen={toggleTimelineDialog}
        isChatLogPopupVisible={isChatLogPopupVisible}
        chatLogId={chatLogId}
        toggleChatLogPopup={toggleChatLogPopup}
        studentName={studentName}
        isVersionHistoryDialogOpen={isVersionHistoryDialogOpen}
        setIsVersionHistoryDialogOpen={toggleVersionHistoryDialog}
      />
    </div>
  );
};

export default SharedCard;

import { Card, CardContent } from "../../../ui/card.jsx";

const HeaderSkeleton = () => {
  return (
    <Card className="mb-6 bg-white shadow-sm animate-pulse">
      <CardContent className="p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="h-8 bg-primary/15 rounded w-48"></div>
          <div className="space-x-2 flex">
            <div className="h-10 bg-primary/15 rounded w-28"></div>
            <div className="h-10 bg-primary/15 rounded w-36"></div>
          </div>
        </div>
        <div className="h-4 bg-primary/15 rounded w-full"></div>
      </CardContent>
    </Card>
  );
};

export default HeaderSkeleton;

import { Checkbox } from "../../../ui/checkbox.jsx";
import { Label } from "../../../ui/label.jsx";

const CorrectChoiceBox = ({
  index,
  correctChoices,
  onCorrectChoicesChange,
  questionType,
}) => {
  const handleChange = (checked) => {
    if (checked) {
      if (questionType === "multiple-choice") {
        onCorrectChoicesChange([index]);
      } else {
        onCorrectChoicesChange([...correctChoices, index]);
      }
    } else {
      onCorrectChoicesChange(
        correctChoices.filter((choiceIndex) => choiceIndex !== index)
      );
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`correct-${index}`}
        checked={correctChoices.includes(index)}
        onCheckedChange={handleChange}
      />
      <Label
        htmlFor={`correct-${index}`}
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Correct
      </Label>
    </div>
  );
};

export default CorrectChoiceBox;

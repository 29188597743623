import { Badge } from "../../ui/badge.jsx";
import { Button } from "../../ui/button.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card.jsx";
import { Tabs, TabsList, TabsTrigger } from "../../ui/tabs.jsx";
import AnswerDisplay from "./GradingInterface/AnswerDisplay.js";
import GradingColumn from "./GradingInterface/GradingColumn.js";
import OverviewList from "./GradingInterface/OverviewList.js";
import QuestionNavigation from "./GradingInterface/QuestionNavigation.js";

export const CardHeaderComponent = ({ title, gradedPercent }) => (
  <CardHeader className="bg-gradient-to-r from-primary to-secondary p-8">
    <CardTitle className="flex font-bold text-3xl text-primary-foreground justify-between">
      <div>{title}</div>
      <div>{getStatusBadge(gradedPercent)}</div>
    </CardTitle>
  </CardHeader>
);

const getStatusBadge = (gradedPercent) => {
  if (gradedPercent === 1.0) {
    return <Badge variant="success">Graded</Badge>;
  } else if (gradedPercent === 0) {
    return <Badge variant="destructive">Ungraded</Badge>;
  } else {
    return (
      <Badge className="bg-yellow-300 hover:bg-yellow-400 text-yellow-800">
        Partially Graded
      </Badge>
    );
  }
};

export const TabsComponent = ({ activeTab, setActiveTab, children }) => (
  <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
    <TabsList className="grid w-full grid-cols-2 mb-6">
      <TabsTrigger value="question" className="py-3 text-lg">
        Question
      </TabsTrigger>
      <TabsTrigger value="overview" className="py-3 text-lg">
        Overview
      </TabsTrigger>
    </TabsList>
    {children}
  </Tabs>
);

export const QuestionTabContent = ({
  currentQuestionIndex,
  totalQuestions,
  onNavigate,
  children,
}) => (
  <div className="space-y-8">
    <QuestionNavigation
      currentIndex={currentQuestionIndex}
      totalQuestions={totalQuestions}
      onNavigate={onNavigate}
    />
    <div className="flex gap-8">{children}</div>
  </div>
);

export const AnswerCard = ({ question, answer, studentName }) => (
  <div className="w-2/3">
    <Card className="h-full flex flex-col shadow-md">
      <CardHeader className="bg-secondary/10">
        <CardTitle className="text-xl">
          {studentName === "Your" ? "Your Answer" : "Student's Answer"}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-grow overflow-auto p-6">
        <AnswerDisplay
          question={question}
          answer={answer}
          studentName={studentName}
        />
      </CardContent>
    </Card>
  </div>
);

export const GradingColumnWrapper = ({
  question,
  questionIntegrityScore,
  report,
  chatLogs,
  onOpenTimelineDialog,
  onOpenChatLogPopup,
  onOpenVersionHistory,
  questionId,
  score,
  comment,
  onScoreChange,
  onCommentChange,
  maxPoints,
}) => (
  <div className="w-1/3">
    <GradingColumn
      question={question}
      questionIntegrityScore={questionIntegrityScore}
      report={report}
      chatLogs={chatLogs}
      onOpenTimelineDialog={onOpenTimelineDialog}
      onOpenChatLogPopup={onOpenChatLogPopup}
      onOpenVersionHistory={onOpenVersionHistory}
      questionId={questionId}
      score={score}
      comment={comment}
      onScoreChange={onScoreChange}
      onCommentChange={onCommentChange}
      maxPoints={maxPoints}
    />
  </div>
);

export const OverviewTabContent = ({
  questions,
  scores,
  onQuestionSelect,
  questionIntegrityScores,
}) => (
  <OverviewList
    questions={questions}
    scores={scores}
    onQuestionSelect={onQuestionSelect}
    questionIntegrityScores={questionIntegrityScores}
  />
);

export const FooterButtons = ({
  onBackToFirst,
  onSave,
  isSaveDisabled,
  saving,
  previouslySaved,
}) => (
  <div className="flex justify-between items-center">
    <Button onClick={onBackToFirst} variant="outline" className="px-6 py-2">
      Back to First Question
    </Button>
    <Button
      onClick={onSave}
      disabled={isSaveDisabled}
      variant={previouslySaved ? "success" : "default"}
      className="px-6 py-2"
    >
      {saving ? "Saving..." : previouslySaved ? "Saved!" : "Save Changes"}
    </Button>
  </div>
);

import html2pdf from "html2pdf.js";
import { Download } from "lucide-react";
import { Button } from "../../ui/button.jsx";

const PDFPrinter = ({ question, answer, studentName }) => {
  const handleDownload = () => {
    const pdfContent = document.createElement("div");
    pdfContent.innerHTML = `
      <div style="font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px;">
        <header style="border-bottom: 2px solid #333; padding-bottom: 10px; margin-bottom: 20px;">
          <h1 style="font-size: 24px; color: #333;">${question.text}</h1>
          <div style="display: flex; justify-content: space-between; font-size: 14px; color: #666;">
            <span>Student: ${studentName}</span>
            <span>Date: ${new Date().toLocaleDateString()}</span>
          </div>
        </header>
        <main>
          <div style="line-height: 1.6; color: #333;">
            ${answer}
          </div>
        </main>
      </div>
    `;

    const opt = {
      margin: 1,
      filename: "essay_response.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(pdfContent).set(opt).save();
  };

  return (
    <Button onClick={handleDownload} variant="outline" size="sm">
      <Download size={16} className="mr-2" />
      Download Question PDF
    </Button>
  );
};

export default PDFPrinter;

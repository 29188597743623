import { useEffect, useRef, useState } from "react";
import api from "../../../api";
import { useUser } from "../../../Contexts/userContext";
import { useThrowToastError } from "../../shared/utils/utils";
import useChatHistory from "./useChatHistory";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import { getInstructions } from "./InstructionFormatter.js";
import TypingIndicator from "./TypingIndicator";

const ChatComponent = ({ chatLogId, question, exiting }) => {
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const chatContainerRef = useRef(null);
  const throwToastError = useThrowToastError();
  const {
    user: { firstName: studentFirstName, gradeLevel },
  } = useUser();
  const [chatCount, setChatCount] = useState(0);

  const { chatLogs, updateChatLog, loading, saveLogs } =
    useChatHistory(chatLogId);

  const saveChatHistory = async () => {
    try {
      await saveLogs();
    } catch (error) {
      throwToastError("Error logging chat history");
    }
  };

  useEffect(() => {
    if (exiting) {
      saveChatHistory();
    }
  }, [exiting]);

  useEffect(() => { //save chats every 4 chats
    if (chatCount >= 4) {
      saveChatHistory();
      setChatCount(0);
    }
  }, [chatCount]);

  const handleChat = async () => {
    try {
      updateChatLog(question.id, "user", message);
      setMessage("");
      setIsTyping(true);

      const instructions = getInstructions(
        studentFirstName,
        gradeLevel,
        1,
        question
      );

      const response = await api.post("/chat", {
        chatLogId: chatLogId,
        questionId: question.id,
        message,
        instructions,
      });

      const reply = response.data.reply;
      setIsTyping(false);
      updateChatLog(question.id, "bot", reply);
      setChatCount(chatCount + 2)
    } catch (error) {
      setIsTyping(false);
      throwToastError("Chat is not responding");
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLogs, question, isTyping]);

  return (
    <div className="flex flex-col h-full">
      {question.chatbotPermitted ? (
        <>
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">Loading...</p>
            </div>
          ) : (
            <>
              <div
                ref={chatContainerRef}
                className="flex-grow overflow-y-auto mb-4 p-4 space-y-4 bg-gray-50 rounded-lg"
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#CBD5E0 #EDF2F7",
                }}
              >
                {chatLogs[question.id]?.map((chat, index) => (
                  <ChatMessage
                    key={index}
                    type={chat.type}
                    content={chat.chat}
                    timestamp={chat.timestamp}
                  />
                ))}
                {isTyping && <TypingIndicator />}
              </div>
              <ChatInput
                message={message}
                setMessage={setMessage}
                handleChat={handleChat}
              />
            </>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p className="text-center text-sm text-gray-900">
            Your instructor has disabled chat permissions for this question.
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatComponent;

import { Link } from "react-router-dom";
import { Input } from "../../../ui/input.jsx";
import { ScrollArea } from "../../../ui/scroll-area.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table.jsx";

const RosterTable = ({
  loading,
  filteredRoster,
  searchTerm,
  setSearchTerm,
  renderLink,
}) => {
  const renderSkeletons = () => (
    <TableBody>
      {[...Array(5)].map((_, index) => (
        <TableRow key={index}>
          <TableCell className="animate-pulse bg-gray-200 h-8"></TableCell>
          <TableCell className="animate-pulse bg-gray-200 h-8"></TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <>
      <Input
        type="text"
        placeholder="Search students..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 rounded-full border-gray-300"
      />
      <ScrollArea className="h-[400px] w-full">
        <Table>
          <TableHeader>
            <TableRow className="bg-primary-500 text-primary-foreground">
              <TableHead className="font-semibold">Name</TableHead>
              <TableHead className="font-semibold">Email</TableHead>
            </TableRow>
          </TableHeader>
          {loading ? (
            renderSkeletons()
          ) : filteredRoster.length > 0 ? (
            <TableBody>
              {filteredRoster.map((student) => (
                <TableRow key={student.uid} className="hover:bg-gray-50">
                  <TableCell className="font-medium">
                    <Link
                      to={renderLink(student)}
                      className="text-blue-600 hover:underline"
                    >
                      {student.lastName}, {student.firstName}
                    </Link>
                  </TableCell>
                  <TableCell>{student.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} className="text-center text-gray-500">
                  {searchTerm
                    ? "No students found matching the search criteria."
                    : "No students enrolled in this course."}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </ScrollArea>
    </>
  );
};

export default RosterTable;

export const stripColorStyling = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const elements = tempDiv.getElementsByTagName("*");
  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute("style");
  }

  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute("class");
  }

  return tempDiv.innerHTML;
};

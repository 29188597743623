import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import { Bot } from "lucide-react";

const ChatCardSkeleton = () => {
  return (
    <Card className="w-1/3 bg-white shadow-sm animate-pulse">
      <CardHeader>
        <CardTitle className="flex items-center font-semibold text-primary">
          <Bot className="h-8 w-8 text-primary" />
          <div className="h-6 bg-primary/15 rounded w-24 ml-2"></div>
        </CardTitle>
      </CardHeader>
      <CardContent className="h-[736px] overflow-y-auto">
        <div className="space-y-4">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="flex items-start">
              <div className="w-8 h-8 rounded-full bg-primary/15 mr-2"></div>
              <div className="flex-1 space-y-2">
                <div className="h-4 bg-primary/15 rounded w-1/4"></div>
                <div className="h-16 bg-primary/15 rounded w-full"></div>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ChatCardSkeleton;

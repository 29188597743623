import React, { useEffect, useState } from "react";
import api from "../../../../api";
import SubmissionRow from "./SubmissionRow";
import { Card, CardTitle, CardHeader, CardContent } from "../../../ui/card";
import {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
} from "../../../ui/table";
import SubmissionListSkeleton from "./SubmissionListSkeleton";

const StudentSubmissions = ({ studentId, assignments, courseId }) => {
  const [submissionsData, setSubmissionsData] = useState([]);
  const [studentInfo, setStudentInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const studentResponse = await api.get(`/users/${studentId}`);

        setStudentInfo(studentResponse.data);

        const submissionsAndGrades = await Promise.all(
          assignments.map(async (assignment) => {
            const submissionId = `${studentId}_${assignment.id}`;
            const [submissionResponse, gradeResponse] = await Promise.all([
              api.get(`/submissions/${submissionId}`),
              api.get(`/grades/assignment/${assignment.id}/user/${studentId}`),
            ]);

            return {
              assignment,
              submission: submissionResponse.data,
              grade: gradeResponse.data,
            };
          })
        );

        setSubmissionsData(submissionsAndGrades);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("An error occurred while fetching the data.");
        setLoading(false);
      }
    };

    fetchData();
  }, [assignments, studentId]);

  if (loading) return <SubmissionListSkeleton />;
  if (error) return <div>{error}</div>;

  return (
    <Card className="w-full shadow-lg">
      <CardHeader className="bg-gray-50">
        <CardTitle className="text-2xl font-bold text-primary">
          Submissions for {studentInfo?.firstName} {studentInfo?.lastName}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Assignment</TableHead>
              <TableHead>Submission Status</TableHead>
              <TableHead>Integrity Score</TableHead>
              <TableHead>Graded Status</TableHead>
              <TableHead>Published Status</TableHead>
              <TableHead>Grade</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {submissionsData.length > 0 ? (
              submissionsData.map((data) => (
                <SubmissionRow
                  key={data.assignment.id}
                  oneStudent={true}
                  studentId={studentId}
                  submission={data.submission}
                  initialGrade={data.grade}
                  assignment={data.assignment}
                  courseId={courseId}
                />
              ))
            ) : (
              <TableRow className="text-sm text-gray-500 items-center">
                No elements available for this table
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default StudentSubmissions;

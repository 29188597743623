import { useCallback } from "react";
import { useUser } from "../Contexts/userContext";

export const useSubmissions = () => {
  const { user, submissions } = useUser();

  const getCourseSubmissions = useCallback(
    (course) => {
      const courseAssignmentsSet = new Set(
        course.assignments.map(
          (assignmentRef) => assignmentRef._path.segments.slice(-1)[0]
        )
      );
      return submissions?.filter(
        (submission) =>
          courseAssignmentsSet.has(submission.assignmentId) &&
          submission.submitted
      );
    },
    [submissions]
  );

  return { getCourseSubmissions };
};

import { AlertTriangle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button.jsx";
import { useUser } from "../../Contexts/userContext";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { user, loading } = useUser();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    navigate("/login?showPrompt=true");
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-16rem)] px-4 text-center">
      <AlertTriangle className="w-16 h-16 text-yellow-500 mb-4" />
      <h1 className="text-4xl font-bold text-gray-900 mb-2">
        Oops! Access Denied
      </h1>
      <p className="text-xl text-gray-600 mb-8">
        Sorry, you don't have permission to view this page or it doesn't exist.
      </p>
      <div className="space-x-4">
        <Button
          onClick={() => navigate("/homepage")}
          className="bg-primary hover:bg-primary/15 focus:ring-primary"
        >
          Go to Dashboard
        </Button>
        <Button
          onClick={() => navigate(-1)}
          variant="outline"
          className="border-primary text-priamry hover:bg-primary"
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default UnauthorizedPage;

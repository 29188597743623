import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const rainbowTheme = {
  ...atomOneDark,
  hljs: {
    ...atomOneDark.hljs,
    background: 'transparent',
  },
  'hljs-keyword': { color: '#FF6B6B' },
  'hljs-string': { color: '#4ECDC4' },
  'hljs-number': { color: '#FFD93D' },
  'hljs-function': { color: '#FF8C42' },
  'hljs-title': { color: '#C780E8' }, 
  'hljs-params': { color: '#4CB944' },
  'hljs-comment': { color: '#95A5A6' },
  'hljs-variable': { color: '#D6A2E8' },  
  'hljs-built_in': { color: '#3498DB' },
  'hljs-name': { color: '#E74C3C' },
  'hljs-attr': { color: '#F39C12' },
  'hljs-symbol': { color: '#1ABC9C' },
  'hljs-meta': { color: '#FFA07A' },  
  'hljs-literal': { color: '#BB8FCE' }, 
};
import { Users } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table.jsx";

const SkeletonRow = () => (
  <TableRow>
    {[...Array(2)].map((_, index) => (
      <TableCell key={index}>
        <div className="h-4 bg-primary/15 rounded animate-pulse"></div>
      </TableCell>
    ))}
  </TableRow>
);

const RosterSkeleton = (master = false) => {
  return (
    <Card className="w-full max-w-3xl mx-auto shadow-xl border-2 border-primary/20">
      <CardHeader className="bg-primary">
        <CardTitle className="text-2xl font-bold text-primary-foreground">
          <Users className="mr-2 h-6 w-6" />
        </CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        <div className="mb-4 h-10 bg-primary/15 rounded animate-pulse"></div>
        <Table>
          <TableHeader>
            <TableRow>
              {["Name", "Email"].map((header) => (
                <TableHead key={header}>
                  <div className="h-4 bg-secondary rounded animate-pulse"></div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {[...Array(5)].map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default RosterSkeleton;

import React from "react";
import { Input } from "../../../ui/input.jsx";
import { Textarea } from "../../../ui/textarea.jsx";

const GradingForm = ({
  questionId,
  score,
  maxPoints,
  comment,
  onScoreChange,
  onCommentChange,
}) => {
  return (
    <div>
      <div className="flex items-center justify-end space-x-2 mb-4">
        <Input
          type="number"
          value={score ?? ""}
          onChange={(e) => onScoreChange(questionId, parseInt(e.target.value))}
          className="w-16 text-right"
        />
        <span className="text-sm text-muted-foreground">/ {maxPoints}</span>
      </div>
      <Textarea
        placeholder="Enter your feedback here..."
        value={comment ?? ""}
        onChange={(e) => onCommentChange(questionId, e.target.value)}
        className="min-h-[100px] resize-vertical"
      />
    </div>
  );
};

export default GradingForm;

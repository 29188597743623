import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { cn } from "../../shared/utils/utils";
import { Button } from "../../ui/button.jsx";
import { Calendar } from "../../ui/calendar.jsx";
import { Checkbox } from "../../ui/checkbox.jsx";
import { Input } from "../../ui/input.jsx";
import { Label } from "../../ui/label.jsx";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover.jsx";
import { Textarea } from "../../ui/textarea.jsx";
import TimeSelect from "./FormModules/TimeSelect.js";

const AssignmentForm = ({
  title,
  setTitle,
  description,
  setDescription,
  dueDate,
  setDueDate,
  botPermissions,
  setBotPermissions,
}) => {
  const handleDateChange = (date) => {
    if (date) {
      const currentTime = dueDate ? new Date(dueDate) : new Date();
      const newDateTime = new Date(date);
      newDateTime.setHours(currentTime.getHours(), currentTime.getMinutes());
      setDueDate(newDateTime.getTime());
    } else {
      setDueDate(null);
    }
  };

  const handleTimeChange = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const newDateTime = dueDate ? new Date(dueDate) : new Date();
    newDateTime.setHours(hours, minutes);
    setDueDate(newDateTime.getTime());
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    return format(new Date(timestamp), "PPP");
  };

  const formatTime = (timestamp) => {
    if (!timestamp) return "";
    return format(new Date(timestamp), "HH:mm");
  };

  const handleBotPermissionsChange = (checked) => {
    setBotPermissions(checked);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <Label htmlFor="title">Title</Label>
        <Input
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter assignment title"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter assignment description"
        />
      </div>

      <div className="space-y-2">
        <Label>Due Date and Time</Label>
        <div className="flex space-x-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] justify-start text-left font-normal",
                  !dueDate && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {dueDate ? formatDate(dueDate) : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={dueDate ? new Date(dueDate) : undefined}
                onSelect={handleDateChange}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <TimeSelect
            value={dueDate}
            onValueChange={(newValue) => setDueDate(newValue)}
          />
        </div>
      </div>
      <div className="space-y-2">
        <Label className="flex items-center text-sm text-gray-600 whitespace-nowrap">
          <Checkbox
            type="checkbox"
            onCheckedChange={handleBotPermissionsChange}
            checked={botPermissions}
          />
          <span className="ml-2"> Bot Assistance </span>
        </Label>
      </div>
    </div>
  );
};

export default AssignmentForm;

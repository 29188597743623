import React from "react";
import CopyPasteTimelineDialog from "../../../shared/Report/TimelineDialog";
import ChatLogPopup from "../../../shared/Report/ChatLogPopup";
import VersionDisplay from "../../../shared/VersionControl/VersionDisplay";

const DialogComponents = ({
  reportId,
  selectedQuestionId,
  isTimelineDialogOpen,
  setIsTimelineDialogOpen,
  isChatLogPopupVisible,
  chatLogId,
  toggleChatLogPopup,
  studentName,
  isVersionHistoryDialogOpen,
  setIsVersionHistoryDialogOpen,
}) => (
  <>
    <CopyPasteTimelineDialog
      reportId={reportId}
      questionId={selectedQuestionId}
      open={isTimelineDialogOpen}
      onOpenChange={setIsTimelineDialogOpen}
    />
    {isChatLogPopupVisible && (
      <ChatLogPopup
        chatLogId={chatLogId}
        questionId={selectedQuestionId}
        onClose={toggleChatLogPopup}
        studentName={studentName}
      />
    )}
    {isVersionHistoryDialogOpen && (
      <VersionDisplay
        versionId={reportId}
        questionId={selectedQuestionId}
        isOpen={isVersionHistoryDialogOpen}
        onClose={() => setIsVersionHistoryDialogOpen(false)}
      />
    )}
  </>
);

export default DialogComponents;
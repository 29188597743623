import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Skeleton } from "../ui/skeleton.jsx";

const ListCard = ({
  title,
  items,
  isLoading,
  error,
  renderItem,
  emptyMessage = "No items to display.",
  icon: Icon,
  maxHeight = 300,
}) => {
  const renderList = () => (
    <ScrollArea className={`h-[${maxHeight}px] w-full pr-4`}>
      {items.length > 0 ? (
        <ul className="space-y-2">
          {items.map((item, index) => (
            <li key={index}>{renderItem(item)}</li>
          ))}
        </ul>
      ) : !isLoading ? (
        <div className="text-center text-muted-foreground py-4">
          {emptyMessage}
        </div>
      ) : null}
    </ScrollArea>
  );

  const renderSkeletons = (count = 5) => (
    <div className="space-y-4">
      {[...Array(count)].map((_, i) => (
        <div key={i} className="flex items-center space-x-4">
          <Skeleton className="h-12 w-12 rounded-full" />
          <div className="flex-grow">
            <Skeleton className="h-5 w-3/4 mb-2" />
            <Skeleton className="h-4 w-1/2" />
          </div>
          <Skeleton className="h-6 w-6" />
        </div>
      ))}
    </div>
  );

  return (
    <Card className="w-full max-w-3xl mx-auto shadow-xl border-2 border-primary/20 hover:border-primary/30 transition-all duration-300">
      <CardHeader className="bg-primary text-primary-foreground rounded-md ">
        <CardTitle className="text-2xl font-bold flex items-center">
          {Icon && <Icon className="mr-2" />}
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        {error ? (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        ) : isLoading ? (
          renderSkeletons()
        ) : (
          renderList()
        )}
      </CardContent>
    </Card>
  );
};

export default ListCard;

import api from "../../../../api";

export const logCopyPaste = async (
  pastedSegment,
  timestamp,
  recentTabSwitch,
  userId,
  assignmentId,
  questionId
) => {
  const reportId = `${userId}_${assignmentId}`;
  try {
    const createResponse = await api.post("/reports", {
      userId,
      assignmentId,
      questionId,
    });

    const logResponse = await api.post(`/reports/${reportId}/copyPasteEvents`, {
      pastedSegment,
      timestamp,
      recentTabSwitch,
      questionId,
    });
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
    }
  }
};

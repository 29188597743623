import { Clock, Copy, ExternalLink } from "lucide-react";
import { useEffect, useState } from "react";
import api from "../../../api";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog.jsx";
import { ScrollArea } from "../../ui/scroll-area.jsx";
import { Separator } from "../../ui/separator.jsx";
import { formatTimeDifference } from "../utils/TimeDifference";

const TimelineDialog = ({ reportId, questionId, open, onOpenChange }) => {
  const [copyPasteEvents, setCopyPasteEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      if (reportId && open) {
        try {
          setIsLoading(true);
          const response = await api.get(
            `/reports/${reportId}/copyPasteEvents`,
            { params: { questionId } }
          );
          setCopyPasteEvents(response.data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchEvents();
  }, [reportId, questionId, open]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center text-xl font-semibold">
            <Copy className="mr-2 h-5 w-5" />
            Copy/Paste Timeline
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[400px] w-full rounded-md border p-4">
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : copyPasteEvents?.length > 0 ? (
            copyPasteEvents.map((event, index) => (
              <div key={index} className="mb-6 relative">
                <div className="absolute left-0 w-px h-full bg-gray-200 -ml-4"></div>
                <div className="flex items-start mb-2">
                  <div className="bg-blue-500 rounded-full w-2 h-2 mt-2 -ml-5 mr-3"></div>
                  <div className="ml-4 flex-grow">
                    <div className="text-sm font-medium break-words">{event.pastedSegment}</div>
                  </div>
                </div>
                <div className="ml-4 text-xs text-gray-500 flex items-center">
                  <Clock className="mr-1 h-3 w-3" />
                  {new Date(event.timestamp).toLocaleString()}
                </div>
                {event.recentTabSwitch && (
                  <div className="ml-4 text-xs text-gray-500 flex items-center mt-1">
                    <ExternalLink className="mr-1 h-3 w-3" />
                    Tab switched {formatTimeDifference(event.timestamp - event.recentTabSwitch)} ago
                  </div>
                )}
                {index < copyPasteEvents.length - 1 && (
                  <Separator className="my-4" />
                )}
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">No copy-paste events found.</div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default TimelineDialog;

import React from 'react';
import { Dialog, DialogContent } from "../../../ui/dialog.jsx";
import { Button } from "../../../ui/button.jsx";
import { OctagonX } from "lucide-react";

const DeleteConfirmationDialog = ({ isOpen, onClose, onConfirm, isDeleting }) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-[425px]">
      <div className="flex flex-col items-center justify-center p-6 text-center">
        <div className="rounded-full bg-red-100 p-3 mb-4">
          <OctagonX className="h-6 w-6 text-red-500" />
        </div>
        <h2 className="text-2xl font-semibold text-red-500 mb-2">
          Confirm Delete
        </h2>
        <p className="text-muted-foreground mb-4">
          Are you sure you want to delete this course? This action cannot
          be undone.
        </p>

        <Button
          disabled={isDeleting}
          onClick={onConfirm}
          variant="destructive"
        >
          {isDeleting ? "Deleting..." : "Delete Course"}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default DeleteConfirmationDialog;

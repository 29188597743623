import { motion } from "framer-motion";
import { UserPlus } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Contexts/userContext";
import ValidationChecker from "../../components/shared/Login/ValidationChecker.mjs";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

const CreateAccountPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { signup } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateAccount = async (e) => {
    localStorage.removeItem("userInfoCache");
    e.preventDefault();

    const validationErrors = {};

    if (!ValidationChecker.isValidName(firstName)) {
      validationErrors.firstName = "Please enter a valid first name.";
    }
    if (!ValidationChecker.isValidName(lastName)) {
      validationErrors.lastName = "Please enter a valid last name.";
    }
    if (!ValidationChecker.isValidEmail(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }
    if (!ValidationChecker.isValidPassword(password)) {
      validationErrors.password =
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.";
    }
    if (password !== confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }
    if (role === "teacher" && !pronouns) {
      validationErrors.pronouns = "Please select your pronouns.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsLoading(true);
      await signup(
        firstName,
        lastName,
        email,
        password,
        role,
        gradeLevel,
        pronouns
      );
      navigate("/homepage");
    } catch (error) {
      setErrors({
        general:
          error.message ||
          "An error occurred while creating the account. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary via-primary/80 to-primary/15 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl"
      >
        <div>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="mx-auto h-16 w-16 bg-primary rounded-full flex items-center justify-center"
          >
            <UserPlus className="h-8 w-8 text-white" />
          </motion.div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Join Aila Today!
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Start your learning journey with us
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleCreateAccount}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="flex space-x-4">
              <div className="flex-1">
                <Label htmlFor="first_name" className="sr-only">
                  First name
                </Label>
                <Input
                  id="first_name"
                  name="first_name"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary  focus:z-10 sm:text-sm"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex-1">
                <Label htmlFor="last_name" className="sr-only">
                  Last name
                </Label>
                <Input
                  id="last_name"
                  name="last_name"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary  focus:z-10 sm:text-sm"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="email" className="sr-only">
                Email address
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="password" className="sr-only">
                Password
              </Label>
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="confirmPassword" className="sr-only">
                Confirm Password
              </Label>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <Label htmlFor="role">I am a...</Label>
              <Select onValueChange={setRole} value={role}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select your role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="student">Student</SelectItem>
                  <SelectItem value="teacher">Teacher</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {role === "student" && (
              <div>
                <Label htmlFor="gradeLevel">Grade Level</Label>
                <Input
                  id="gradeLevel"
                  value={gradeLevel}
                  onChange={(e) => setGradeLevel(e.target.value)}
                  required
                  className="w-full"
                  placeholder="Enter your grade level"
                />
              </div>
            )}

            {role === "teacher" && (
              <div>
                <Label htmlFor="pronouns">Pronouns</Label>
                <Select onValueChange={setPronouns} value={pronouns}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select your pronouns" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Mr.">Mr.</SelectItem>
                    <SelectItem value="Ms.">Ms.</SelectItem>
                    <SelectItem value="Mrs.">Mrs.</SelectItem>
                    <SelectItem value="Dr.">Dr.</SelectItem>
                    <SelectItem value="Mx.">Mx.</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>

          {Object.keys(errors).map((key) => (
            <Alert key={key} variant="destructive">
              <AlertDescription>{errors[key]}</AlertDescription>
            </Alert>
          ))}

          <div>
            <Button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary/90  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              disabled={isLoading}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <UserPlus
                  className="h-5 w-5 text-white group-hover:text-primary-foreground/90"
                  aria-hidden="true"
                />
              </span>
              {isLoading ? "Creating your account..." : "Create Account"}
            </Button>
          </div>
        </form>

        <div className="text-center">
          <p className="mt-2 text-sm text-gray-600">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-medium text-primary hover:text-primary/80"
            >
              Sign in
            </a>
          </p>
        </div>

        <div className="text-center text-xs text-gray-500">
          © {new Date().getFullYear()} Aila. All rights reserved.
        </div>
      </motion.div>
    </div>
  );
};

export default CreateAccountPage;

import { CardHeader } from "../../ui/card.jsx";

const CodeEditorHeader = ({ language }) => (
  <CardHeader className="bg-gray-800 p-2 rounded-t-lg">
    <div className="flex items-center">
      <div className="flex space-x-2">
        <div className="w-3 h-3 rounded-full bg-red-500"></div>
        <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
        <div className="w-3 h-3 rounded-full bg-green-500"></div>
      </div>
      <div className="flex-grow text-right">
        <span className="text-xs text-gray-400">{language}</span>
      </div>
    </div>
  </CardHeader>
);

export default CodeEditorHeader;

const ProgressBar = ({ currentIndex, numberOfQuestions }) => {
  const progress = Math.round((currentIndex / numberOfQuestions) * 100);

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
      <div
        className="bg-primary h-2.5 rounded-full transition-all duration-300 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
      <div className="text-sm text-gray-600 mt-1">
        Question {currentIndex} of {numberOfQuestions}
      </div>
    </div>
  );
};

export default ProgressBar;

import { Navigate, useLocation, useParams } from "react-router-dom";
import { useUser } from "./Contexts/userContext";
import LoadingScreen from "./pages/LoadingPage.js";

const ProtectedRoute = ({ children, allowedRoles, accessRule }) => {
  const { user, loading, courses, assignments, submissions } = useUser();
  const submittedSubmissions = submissions?.filter(
    (submission) => submission.submitted
  );
  const location = useLocation();
  const params = useParams();

  if (loading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return (
      <Navigate
        to="/login?showPrompt=true"
        state={{ from: location }}
        replace
      />
    );
  }

  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  const hasAccess = accessRule
    ? accessRule(
        { ...user, courses, assignments, submissions: submittedSubmissions },
        params
      )
    : true;

  if (!hasAccess) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;

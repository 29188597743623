import { useNavigate } from "react-router-dom";
import { useUser } from "../../Contexts/userContext";
import { Button } from "../ui/button";

const LogoutButton = () => {
  const navigate = useNavigate();
  const { logout } = useUser();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login", { replace: true, state: { isLogout: true } });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Button onClick={handleLogout} variant="outline">
      Logout
    </Button>
  );
};

export default LogoutButton;

import { Card, CardContent } from "../../ui/card.jsx";
import { Skeleton } from "../../ui/skeleton.jsx";

const CourseCardSkeleton = () => {
  return (
    <Card className="w-full h-full flex flex-col shadow-lg overflow-hidden">
      <div className="flex-shrink-0 p-6 flex flex-col justify-center bg-gradient-to-br from-gray-200 to-gray-300 animate-pulse">
        <Skeleton className="h-8 w-3/4 mb-2" />
        <Skeleton className="h-4 w-1/2" />
      </div>
      <CardContent className="flex-grow bg-card p-6 space-y-4">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-5/6" />
        <Skeleton className="h-4 w-4/6" />
      </CardContent>
      <div className="flex-shrink-0 bg-white p-6 border-t border-gray-200">
        <Skeleton className="h-6 w-1/3" />
      </div>
    </Card>
  );
};

export default CourseCardSkeleton;

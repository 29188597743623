import { Link } from "react-router-dom";
import { Card, CardContent } from "../../ui/card.jsx";
import { getLighterShade } from "../utils/utils";

const CourseCard = ({
  course,
  headerContent,
  bodyContent,
  footerContent,
  linkTo,
  onClick,
}) => {
  const accentColor = course.colorTheme || "#4F46E5";
  const lighterAccentColor = getLighterShade(accentColor, 30);

  return (
    <Card
      className="w-full h-full flex flex-col shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out overflow-hidden group"
      style={{ borderColor: accentColor, borderWidth: "2px" }}
    >
      <Link
        to={linkTo}
        className="flex flex-col h-full"
        onClick={onClick}
        aria-label={`View details for ${course.title}`}
      >
        <div
          className="flex-shrink-0 p-6 flex flex-col justify-center transition-colors duration-300"
          style={{
            background: `linear-gradient(135deg, ${accentColor}, ${lighterAccentColor})`,
            color: "#ffffff",
          }}
        >
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2 group-hover:scale-105 transition-transform duration-300">
            {course.title}
          </h3>
          {headerContent}
        </div>
        <CardContent className="flex-grow bg-card p-6 overflow-auto">
          {bodyContent}
        </CardContent>
        {footerContent && (
          <div
            className="flex-shrink-0 bg-white p-6 border-t"
            style={{ borderColor: accentColor }}
          >
            {footerContent}
          </div>
        )}
      </Link>
    </Card>
  );
};

export default CourseCard;

export function getInstructions(name, gradeLevel, sliderValue, question) {
  const formattedQuestion = question ? formatQuestion(question) : "";

  switch (sliderValue) {
    case 1:
      // return `You are a tutor for grade ${gradeLevel}.
      //   Your student's name is ${name}. Use clear and simple language.
      //   Be kind and address the student by name when you can.
      //   The assignment question you're helping your student with is
      //   ${formattedQuestion}.  If the student asks for the answer, mention you
      //   cannot give them the answer outright, but you can guide them toward it.
      //   Offer very limited help, only defining terms and clearing up confusion.
      //   Do not provide the answer. Respond to questions and comments only if
      //   they pertain to the assignment question. Otherwise, apologize and
      //   redirect them to focus on the assignment question`;

      // return `The assignment question ${formattedQuestion}. Do not give me the answer to this question. Do
      // not tell me what is correct or what is wrong. You must only offer to clear up confusion. If I tell
      // or ask you something else, say you can only clear up confusion.`;
      // return `The assignment question ${formattedQuestion}. Do not give me the answer to this question.
      //   Do not tell me what is correct and what is wrong. You must only offer to clear up confusion or format
      //   my answer. If I tell or ask you something else, say you can only clear up confusion or format my answer.`

      return `Assist me with the assignment question: ${formattedQuestion}. Instead of giving me the answer, have a conversation 
      with me, asking thought-provoking questions along the way. My name is ${name}, and I am in grade ${gradeLevel}. Use vocabulary and 
      knowledge at that grade level. I have to solve this by myself, without you giving me the answer. 
       If I get off track from answering the question, redirect me back toward it.`;

    case 2:
      return `Assist me with the assignment question: ${formattedQuestion}. Instead of giving me the answer, have a conversation 
      with me, providing hints and asking me thought-provoking questions. My name is ${name}, and I am in grade ${gradeLevel}. 
      Use vocabulary and knowledge at that grade level. If I get off track from answering the question, redirect me back toward it.`;

    case 3:
      return `Assist me with the assignment question: ${formattedQuestion}. Instead of giving me the answer outright, have a conversation 
      with me, providing helpful hints and asking me thought-provoking questions. If I ask for the answer, tell me, and teach me 
      why it is right. My name is ${name}, and I am in grade ${gradeLevel}. Use vocabulary and knowledge at that grade level. If I get off track from answering the question, redirect me back toward it.`;

    default:
      return `Assist me with the assignment question: ${formattedQuestion}. Instead of giving me the answer, have a conversation 
      with me, asking thought-provoking questions along the way. My name is ${name}, and I am in grade ${gradeLevel}. Use vocabulary and 
      knowledge at that grade level. I have to solve this by myself, without you giving me the answer. 
       If I get off track from answering the question, redirect me back toward it.`;
  }
}

//Based on the question type, format the question so GPT understands it better

function formatQuestion(question) {
  if (question.type === "short-answer") {
    return `In a short answer format, answer: ${question.text}`;
  }
  if (question.type === "multiple-choice") {
    var choicesString = "";
    var charArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (var i = 0; i < question.choices.length; i++) {
      choicesString += charArray[i] + ": " + question.choices[i] + "; ";
    }
    return `Answer the following multiple choice question: ${question.text}. The choices are: ${choicesString}`;
  }
  if (question.type === "select-all") {
    var choicesString = "";
    var charArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (var i = 0; i < question.choices.length; i++) {
      choicesString += charArray[i] + ": " + question.choices[i] + "; ";
    }
    return `Answer the following select-all-that-apply question: ${question.text}. The choices are: ${choicesString}`;
  }
  if (question.type === "essay") {
    return `In an essay format, answer: ${question.text}`;
  }
  if (question.type === "coding") {
    return `This is a coding question in ${question.codingLanguage}. The task is to ${question.text}. The starter code 
    is ${question.rawStarterCode}`;
  } else {
    //currently no specific response for file uploads. Don't see the point
    return question.text;
  }
}

import React from "react";
import { ScrollArea } from "../../../ui/scroll-area";
import { renderQuestion } from "../../../shared/Responses/ResponseRenderer";

const AnswerDisplay = ({ question, answer, studentName }) => (
  <ScrollArea className="h-full pr-4">
    {renderQuestion(question, { [question.id]: answer }, studentName)}
  </ScrollArea>
);

export default AnswerDisplay;

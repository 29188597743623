import { useState, useEffect, useMemo } from "react";

const useAssignmentInterface = (assignment, initialAnswers) => {
  const [currentQuestion, setCurrentQuestion] = useState(
    assignment.questions[0]
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState(initialAnswers);
  const questions = useMemo(() => assignment.questions, [assignment]);

  useEffect(() => {
    if (questions?.length > 0) {
      setCurrentQuestion(questions[currentIndex]);
    }
  }, [currentIndex, questions]);

  return {
    questions,
    answers,
    setAnswers,
    currentQuestion,
    setCurrentQuestion,
    currentIndex,
    setCurrentIndex,
  };
};

export default useAssignmentInterface;

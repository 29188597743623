import { Card } from "../../ui/card.jsx";
import { Checkbox } from "../../ui/checkbox.jsx";
import { Label } from "../../ui/label.jsx";

const SelectAll = ({ question, questionNumber, answers, setAnswers }) => {
  const handleOptionChange = (selectedOption) => {
    setAnswers((prevAnswers) => {
      const existingAnswers = prevAnswers[question.id] || [];
      const updatedAnswers = existingAnswers.includes(selectedOption)
        ? existingAnswers.filter((answer) => answer !== selectedOption)
        : [...existingAnswers, selectedOption];

      return {
        ...prevAnswers,
        [question.id]: updatedAnswers,
      };
    });
  };

  const options = question.choices || [];

  return (
    <div className="space-y-3">
      {options.map((option, index) => {
        const isChecked = (answers[question.id] || []).includes(option);
        return (
          <Card
            key={index}
            className={`border border-primary flex items-center space-x-3 p-4 cursor-pointer hover:bg-primary transition-colors ${
              isChecked ? "bg-primary/15 border-primary" : ""
            }`}
            onClick={() => handleOptionChange(option)}
          >
            <Checkbox
              id={`option-${questionNumber}-${index}`}
              checked={isChecked}
              onCheckedChange={() => handleOptionChange(option)}
              className="w-5 h-5 border-2 border-primary text-primary"
            />
            <Label
              htmlFor={`option-${questionNumber}-${index}`}
              className="flex-grow text-lg font-medium text-primary-800 cursor-pointer"
            >
              {option}
            </Label>
          </Card>
        );
      })}
    </div>
  );
};

export default SelectAll;

import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table.jsx";

const SkeletonRow = () => (
  <TableRow>
    {[...Array(7)].map((_, index) => (
      <TableCell key={index}>
        <div className="h-4 bg-primary/15 rounded animate-pulse"></div>
      </TableCell>
    ))}
  </TableRow>
);

const SubmissionListSkeleton = () => {
  return (
    <Card className="w-full shadow-lg">
      <CardHeader className="bg-gray-50">
        <CardTitle className="text-2xl font-bold text-primary">
          <div className="h-8 w-3/4 bg-primary rounded animate-pulse"></div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              {[
                "Student Name",
                "Submission Status",
                "Integrity Score",
                "Graded Status",
                "Published Status",
                "Grade",
                "Actions",
              ].map((header) => (
                <TableHead key={header}>
                  <div className="h-4 bg-secondary rounded animate-pulse"></div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {[...Array(5)].map((_, index) => (
              <SkeletonRow key={index} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default SubmissionListSkeleton;

export function getStarterCode(language, userType) {
  const student = userType === "student";

  switch (language) {
    case "python":
      if (student) {
        return "#Start coding here";
      } else {
        return "#Enter any starter code here";
      }

    case "java":
      if (student) {
        return "//Start coding here";
      } else {
        return "//Enter any starter code here";
      }

    case "cpp":
      if (student) {
        return "//Start coding here";
      } else {
        return "//Enter any starter code here";
      }

    case "csharp":
      if (student) {
        return "//Start coding here";
      } else {
        return "//Enter any starter code here";
      }

    default:
      if (student) {
        return "//Start coding here";
      } else {
        return "//Enter any starter code here";
      }
  }
}

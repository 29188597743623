import { PlusCircle, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "../../../ui/button.jsx";
import { Card, CardContent } from "../../../ui/card.jsx";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../ui/carousel.jsx";
import Question from "./Question";
import SearchBar from "./SearchBar";

const QuestionList = ({
  questions,
  setQuestions,
  assignmentBotPermissions,
}) => {
  const [api, setApi] = useState(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setQuestions(
      questions.map((question) => ({
        ...question,
        chatbotPermitted: assignmentBotPermissions,
      }))
    );
  }, [assignmentBotPermissions]);

  useEffect(() => {
    if (api) {
      const updateCurrent = () => {
        setCurrent(api.selectedScrollSnap());
      };

      updateCurrent();
      const intervalId = setInterval(updateCurrent, 100);
      return () => clearInterval(intervalId);
    }
  }, [api]);

  const onAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: "",
        instruction: "",
        points: "",
        type: "",
        choices: [],
        correctChoices: [],
        chatbotPermitted: assignmentBotPermissions,
      },
    ]);
    setTimeout(() => {
      if (api) {
        api.scrollTo(questions.length);
      }
    }, 0);
  };

  const onDeleteQuestion = (indexToDelete) => {
    const updatedQuestions = questions.filter(
      (_, index) => index !== indexToDelete
    );
    setQuestions(updatedQuestions);
    if (api && current >= updatedQuestions.length) {
      api.scrollTo(Math.max(0, updatedQuestions.length - 1));
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
        e.stopPropagation();
      }
    };

    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto">
      <SearchBar questions={questions} api={api} />
      <Carousel setApi={setApi} className="w-full">
        <CarouselContent>
          {questions.map((question, index) => (
            <CarouselItem key={index}>
              <Card className="p-4 relative">
                <Button
                  onClick={() => onDeleteQuestion(index)}
                  className="absolute top-2 right-2 p-1 h-8 w-8"
                  variant="destructive"
                >
                  <X className="h-4 w-4" />
                </Button>
                <CardContent>
                  <Question
                    question={question}
                    setQuestions={setQuestions}
                    questions={questions}
                    index={index}
                    assignmentBotPermissions={assignmentBotPermissions}
                  />
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <div className="flex justify-between items-center mt-4">
        <Button onClick={onAddQuestion} className="flex items-center">
          <PlusCircle className="mr-2 h-4 w-4" /> Add Question
        </Button>
        <p className="text-sm text-gray-500">
          {questions.length > 0 &&
            `Question ${current + 1} of ${questions.length}`}
        </p>
      </div>
    </div>
  );
};

export default QuestionList;

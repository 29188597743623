import React from 'react';
import { Dialog, DialogContent } from "../../../ui/dialog.jsx";
import { Button } from "../../../ui/button.jsx";
import { CircleCheck } from "lucide-react";

const DeletionSuccessDialog = ({ isOpen, onClose }) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-[425px]">
      <div className="flex flex-col items-center justify-center p-6 text-center">
        <div className="rounded-full bg-green-100 p-3 mb-4">
          <CircleCheck className="h-6 w-6 text-green-500" />
        </div>
        <h2 className="text-2xl font-semibold text-green-500 mb-2">
          Course Successfully Deleted
        </h2>
        <p className="text-muted-foreground mb-4">
          Click below to return to the homepage
        </p>
        <Button onClick={onClose} variant="success">
          Go to homepage
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default DeletionSuccessDialog;
import { Loader2 } from "lucide-react";
import { useState } from "react";
import api from "../../../api";
import { Button } from "../../ui/button.jsx";
import { Card, CardContent } from "../../ui/card.jsx";
import { Input } from "../../ui/input.jsx";
import { Label } from "../../ui/label.jsx";
import FileUploader from "./FileUploader.js";

const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB

const QuestionGenerator = ({
  questions,
  setQuestions,
  assignmentBotPermissions,
}) => {
  const [questionCounts, setQuestionCounts] = useState({
    shortAnswer: "",
    multipleChoice: "",
  });
  const [file, setFile] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState("");

  const generateQuestions = async (type, count) => {
    if (!file || !count) return [];

    if (file.size > MAX_FILE_SIZE) {
      setError(
        `File size exceeds the maximum limit of ${
          MAX_FILE_SIZE / (1024 * 1024)
        }MB.`
      );
      return [];
    }

    try {
      const base64File = await fileToBase64(file);

      const generateResponse = await api.post(
        `/generate/${type}`,
        { file: base64File, fileName: file.name },
        {
          params: { numQuestions: count },
          headers: { "Content-Type": "application/json" },
        }
      );

      return generateResponse.data.questions.map((questionObject) => ({
        text: questionObject.question,
        ...(type === "short-answer"
          ? { instructions: questionObject.instructions }
          : {
              choices: questionObject.choices,
              correctChoices: [questionObject.correctChoice],
            }),
        points: "",
        type: type,
        chatbotPermitted: assignmentBotPermissions,
      }));
    } catch (error) {
      console.error(`Error generating ${type} questions:`, error);
      if (error.response && error.response.status === 413) {
        setError("File size too large. Please try a smaller file.");
      } else {
        setError(
          `An error occurred while generating ${type} questions. Please try again.`
        );
      }
      return [];
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleGenerate = async () => {
    setGenerating(true);
    setError(null);

    const newShortAnswerQuestions = await generateQuestions(
      "short-answer",
      questionCounts.shortAnswer
    );
    const newMultipleChoiceQuestions = await generateQuestions(
      "multiple-choice",
      questionCounts.multipleChoice
    );

    const allNewQuestions = [
      ...newShortAnswerQuestions,
      ...newMultipleChoiceQuestions,
    ];
    const shuffledQuestions = shuffleArray(allNewQuestions);

    setQuestions([...questions, ...shuffledQuestions]);

    setGenerating(false);
    resetForm();
  };

  const handleQuestionCountChange = (type) => (e) => {
    const value = e.target.value;
    const numValue = parseInt(value);

    setQuestionCounts((prev) => ({
      ...prev,
      [type]: value === "" ? "" : Math.min(Math.max(numValue, 1), 20),
    }));
  };

  const handleFileChange = (selectedFile) => {
    if (selectedFile) {
      setFile(selectedFile);
      setError("");
    } else {
      setError(
        "Unsupported file type. Please upload a PDF, DOC, or DOCX file."
      );
      resetForm();
    }
  };

  const resetForm = () => {
    setQuestionCounts({ shortAnswer: "", multipleChoice: "" });
    setFile(null);
  };

  const isFormValid =
    file &&
    (questionCounts.shortAnswer || questionCounts.multipleChoice) &&
    !generating;

  return (
    <Card className="w-full max-w-md">
      <CardContent className="p-6 space-y-4">
        {["shortAnswer", "multipleChoice"].map((type) => (
          <div key={type} className="space-y-2">
            <Label htmlFor={`num${type}Questions`}>
              Number of{" "}
              {type === "shortAnswer" ? "short-answer" : "multiple-choice"}{" "}
              questions
            </Label>
            <Input
              id={`num${type}Questions`}
              type="number"
              value={questionCounts[type]}
              placeholder={"Enter number here..."}
              onChange={handleQuestionCountChange(type)}
            />
          </div>
        ))}
        <FileUploader onFileChange={handleFileChange} error={error} />
        <Button
          onClick={handleGenerate}
          disabled={!isFormValid}
          className="w-full"
        >
          {generating ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Generating...
            </>
          ) : (
            "Generate Questions"
          )}
        </Button>
      </CardContent>
    </Card>
  );
};

export default QuestionGenerator;

import { Input } from "../../../ui/input.jsx";
import { Label } from "../../../ui/label.jsx";

const Points = ({ points, onChange }) => {
  return (
    <div className="question-field">
      <Label htmlFor="points">Points:</Label>
      <Input
        type="number"
        id="points"
        value={points}
        placeholder="Enter points"
        onChange={onChange}
      />
    </div>
  );
};

export default Points;

import { useEffect, useRef, useState } from "react";
import VersionControl from "../../shared/VersionControl/VersionControl";
import EssayEditor from "../TextEditors/EssayEditor";

const EssayQuestion = ({
  question,
  answers,
  setAnswers,
  assignmentId,
  studentId,
  handleCutCopy,
  handlePaste,
}) => {
  const [currentContent, setCurrentContent] = useState("");
  const versionControlRef = useRef(null);
  const [initialContentInitialized, setInitialContentRendered] =
  useState(false);

  useEffect(() => {
    setCurrentContent(answers[question.id] || "");
  }, [question.id, answers]);

  useEffect(() => {
    setInitialContentRendered(false);
  }, [question.id]);

  const handleChange = (answer) => {
    setCurrentContent(answer);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question.id]: answer,
    }));
  };

  const handlePasteEvent = (pastedContent) => {
    if (versionControlRef.current) {
      versionControlRef.current.handlePaste(pastedContent);
    }
    handlePaste(pastedContent);
  };

  return (
    <div>
      <EssayEditor
        onChange={handleChange}
        content={currentContent}
        handleCutCopy={handleCutCopy}
        handlePaste={handlePasteEvent}
        initialContentRendered={initialContentInitialized}
        setInitialContentRendered={setInitialContentRendered}
      />
      <VersionControl
        ref={versionControlRef}
        userId={studentId}
        assignmentId={assignmentId}
        questionId={question.id}
        currentContent={currentContent}
      />
    </div>
  );
};

export default EssayQuestion;

import { Book } from "lucide-react";
import { Card, CardContent } from "../../ui/card.jsx";
import { useUser } from "../../../Contexts/userContext";
import CourseManagerPopup from "../../teacher/CourseManagement/CourseManagerPopup.js";

const TeacherCourseHeader = ({ course, loading }) => {
  const { refreshUserInfo } = useUser();

  const handleRefresh = async () => {
    await refreshUserInfo();
  };

  return (
    <Card className="mb-8 overflow-hidden">
      <div className="bg-gradient-to-r from-primary via-primary/80 to-primary/15 h-2" />
      <CardContent className="p-6">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="flex justify-between items-start">
              <div className="flex items-center space-x-4">
                <div className="bg-blue-100 p-2 rounded-full">
                  <Book className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold text-gray-800">
                    {course.title}
                  </h2>
                  <div className="text-sm text-gray-500 mt-1">
                    Course Code:{" "}
                    <span className="font-bold">{course.enrollmentCode}</span>
                  </div>
                </div>
              </div>
              <CourseManagerPopup onAction={handleRefresh} course={course} />
            </div>
            <p className="text-gray-600 mt-4">{course.description}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const SkeletonLoader = () => (
  <div className="animate-pulse">
    <div className="bg-primary/15-200 h-6 w-3/4 mb-2 rounded"></div>
    <div className="bg-primary/15-200 h-4 w-1/2 mb-4 rounded"></div>
    <div className="bg-primary/15-200 h-4 w-full mb-2 rounded"></div>
    <div className="bg-primary/15-200 h-4 w-full mb-2 rounded"></div>
    <div className="bg-primary/15-200 h-4 w-3/4 rounded"></div>
  </div>
);

export default TeacherCourseHeader;

import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../api";
import { useUser } from "../../../Contexts/userContext";
import { Badge } from "../../ui/badge.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select.jsx";

const CourseDropdown = ({ onSelectedCourseIdsChange }) => {
  const { courseId } = useParams();
  const [courses, setCourses] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [selectedCourseTitles, setSelectedCourseTitles] = useState([]);
  const [selectKey, setSelectKey] = useState(0);
  const { user } = useUser();
  const userId = user.uid;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await api.get(`/users/${userId}/owned-courses`);
        setCourses(response.data);

        if (
          courseId &&
          response.data.some((course) => course.id === courseId)
        ) {
          const course = response.data.find((course) => course.id === courseId);
          setSelectedCourseIds([courseId]);
          setSelectedCourseTitles([course.title]);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [userId, courseId]);

  useEffect(() => {
    onSelectedCourseIdsChange(selectedCourseIds);
  }, [selectedCourseIds, onSelectedCourseIdsChange]);

  const handleCourseSelect = (selectedCourseId) => {
    const selectedCourse = courses.find(
      (course) => course.id === selectedCourseId
    );

    if (selectedCourse && !selectedCourseIds.includes(selectedCourseId)) {
      setSelectedCourseIds([...selectedCourseIds, selectedCourseId]);
      setSelectedCourseTitles([...selectedCourseTitles, selectedCourse.title]);
      setSelectKey((prevKey) => prevKey + 1);
    }
  };

  const handleRemoveCourse = (courseId) => {
    setSelectedCourseIds(selectedCourseIds.filter((id) => id !== courseId));
    setSelectedCourseTitles(
      selectedCourseTitles.filter(
        (title) =>
          title !== courses.find((course) => course.id === courseId).title
      )
    );
  };

  const allCoursesSelected =
    courses.length > 0 && selectedCourseIds.length === courses.length;

  return (
    <div className="space-y-4">
      <Select
        key={selectKey}
        onValueChange={handleCourseSelect}
        disabled={allCoursesSelected}
      >
        <SelectTrigger className="w-full">
          <SelectValue
            placeholder={
              allCoursesSelected ? "All courses selected" : "Select a course"
            }
          />
        </SelectTrigger>
        <SelectContent>
          {allCoursesSelected ? (
            <SelectItem value="disabled" disabled>
              All courses selected
            </SelectItem>
          ) : (
            courses
              .filter((course) => !selectedCourseIds.includes(course.id))
              .map((course) => (
                <SelectItem key={course.id} value={course.id}>
                  {course.title}
                </SelectItem>
              ))
          )}
        </SelectContent>
      </Select>
      <div>
        <h4 className="mb-2 font-semibold">Selected Courses:</h4>
        <div className="flex flex-wrap gap-2">
          {selectedCourseTitles.map((title, index) => (
            <Badge
              key={selectedCourseIds[index]}
              variant="secondary"
              className="px-3 py-1 text-sm font-medium"
            >
              {title}
              <button
                onClick={() => handleRemoveCourse(selectedCourseIds[index])}
                className="ml-2 rounded-full p-1 hover:bg-gray-200"
              >
                <X size={14} />
              </button>
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseDropdown;

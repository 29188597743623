import { useLocation } from "react-router-dom";
import ActivityTracker from "./ActivityTracker";

const ConditionalActivityTracker = ({ children }) => {
  const location = useLocation();
  const noTrackingPaths = ["/", "/login", "/create-account", "/unauthorized"];

  if (noTrackingPaths.includes(location.pathname)) {
    return <>{children}</>;
  }

  return <ActivityTracker>{children}</ActivityTracker>;
};

export default ConditionalActivityTracker;

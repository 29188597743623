import { AlertCircle, CheckCircle2, UserPlus } from "lucide-react";
import { useState } from "react";
import api from "../../../api";
import { useUser } from "../../../Contexts/userContext";
import { Alert, AlertDescription } from "../../ui/alert.jsx";
import { Button } from "../../ui/button.jsx";
import { Card, CardContent } from "../../ui/card.jsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog.jsx";
import { Input } from "../../ui/input.jsx";
import { Label } from "../../ui/label.jsx";

const CourseEnroller = ({ onJoin }) => {
  const [enrollmentCode, setEnrollmentCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [enrolledCourseTitle, setEnrolledCourseTitle] = useState(null);
  const {
    user: { uid: userId },
  } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post("/enrollments", {
        userId,
        enrollmentCode,
      });

      await onJoin();
      setEnrolledCourseTitle(response.data.enrollment.courseTitle);
      setIsSuccess(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again later.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      setAlert({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setEnrollmentCode("");
    setAlert(null);
    setIsSuccess(false);
    setEnrolledCourseTitle(null);
  };

  const handleDialogClose = () => {
    setIsSuccess(false);
    resetForm();
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
          if (!open) resetForm();
        }}
      >
        <DialogTrigger asChild>
          <Button className="bg-primary text-primary-foreground hover:bg-primary/90">
            <UserPlus className="mr-2 h-4 w-4" /> Join Course
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <Card className="border-0 shadow-none">
            <DialogHeader>
              <DialogTitle className="text-primary">
                Course Enrollment
              </DialogTitle>
              <DialogDescription>
                Enter the enrollment code to join a course
              </DialogDescription>
            </DialogHeader>
            <form onSubmit={handleSubmit}>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="enrollmentCode" className="text-right">
                    Code
                  </Label>
                  <Input
                    id="enrollmentCode"
                    value={enrollmentCode}
                    onChange={(e) => setEnrollmentCode(e.target.value)}
                    className="col-span-3 border-secondary"
                    placeholder="Enter course enrollment key"
                    required
                  />
                </div>
              </div>
              {alert && (
                <Alert variant="destructive" className="mt-2 mb-4">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{alert.message}</AlertDescription>
                </Alert>
              )}
              <div className="flex justify-end">
                <Button
                  type="submit"
                  disabled={loading}
                  className="bg-secondary text-secondary-foreground hover:bg-secondary/90"
                >
                  {loading ? "Joining Course... " : "Join Course"}
                </Button>
              </div>
            </form>
          </Card>
        </DialogContent>
      </Dialog>

      {isSuccess && (
        <Dialog open={isSuccess} onOpenChange={handleDialogClose}>
          <DialogContent className="sm:max-w-[425px] bg-background shadow-none border-none">
            <div className="flex flex-col items-center justify-center p-6 text-center">
              <div className="rounded-full bg-primary/20 p-3 mb-4">
                <CheckCircle2 className="h-6 w-6 text-primary" />
              </div>
              <h2 className="text-2xl font-semibold text-primary mb-2">
                Course Joined Successfully!
              </h2>
              <p className="text-muted-foreground mb-4">
                You have successfully joined the course: {enrolledCourseTitle}
              </p>
              <Button
                onClick={() => handleDialogClose()}
                className="bg-primary text-primary-foreground hover:bg-primary/90"
              >
                Close
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CourseEnroller;

import { Atom, Book, Lightbulb } from "lucide-react";

const LoadingScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-primary mb-4">Aila</h1>
        <div className="flex justify-center space-x-4 mb-8">
          <Book className="w-8 h-8 text-primary animate-bounce" />
          <Lightbulb
            className="w-8 h-8 text-primary-400 animate-bounce"
            style={{ animationDelay: "0.1s" }}
          />
          <Atom
            className="w-8 h-8 text-primary animate-bounce"
            style={{ animationDelay: "0.2s" }}
          />
        </div>
        <div className="w-64 h-2 bg-gray-200 rounded-full mx-auto overflow-hidden">
          <div className="w-full h-full bg-gradient-to-r from-primary via-primary/80 to-primary/15 animate-pulse"></div>
        </div>
        <p className="mt-4 text-lg text-gray-600">
          Preparing your learning adventure...
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;

import BaseLayout from "../../components/layout/BaseLayout";
import DummyCalendar from "../../components/shared/DummyCalendar";
import TeacherCourseList from "../../components/teacher/Lists/CourseList";
import MasterRoster from "../../components/teacher/Lists/Rosters/MasterRoster";
import { useUser } from "../../Contexts/userContext";

const TeacherHomepage = () => {
  const { courses, loading } = useUser();
  return (
    <BaseLayout>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Your Dashboard</h1>
        </div>
        <TeacherCourseList courses={courses} loading={loading} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <MasterRoster courses={courses} coursesLoading={loading} />
          <DummyCalendar />
        </div>
      </div>
    </BaseLayout>
  );
};

export default TeacherHomepage;

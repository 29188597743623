class ValidationChecker {
    static isValidName(name) {
      // Check if the name contains only letters and spaces
      const nameRegex = /^[A-Za-z\s]+$/;
      return nameRegex.test(name);
    }
    static isValidEmail = (entry) => {
        return String(entry)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
  
    static isValidPassword(password) {
      //Minimum length of 8 characters, at least one uppercase letter, one lowercase letter, and one digit
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
      return passwordRegex.test(password);
    }
  }
  
  export default ValidationChecker;
import { Badge } from "../../ui/badge";
import CodeRenderer from "./CodeRenderer";

const CodingResponse = ({ question, answer }) => {
  return (
    <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
      <div className="mb-4">
        <Badge
          variant="secondary"
          className="mb-2 text-xs font-semibold bg-secondary text-secondary-foreground"
        >
          Code
        </Badge>
        <h3 className="text-lg font-semibold text-foreground">{question.text}</h3>
      </div>
      <div>
      <p className="font-medium text-sm text-muted-foreground">Response:</p>
      </div>
      <CodeRenderer question={question} answer={answer} />
    </div>
  );
};

export default CodingResponse;

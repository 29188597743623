import { Card, CardDescription, CardHeader, CardTitle } from "../ui/card.jsx";

const QuestionHeader = ({ question }) => {
  const { text, instructions } = question;

  return (
    <Card className="mb-6">
      <CardHeader className="relative">
        <CardTitle className="text-xl font-semibold text-primary">
          {text}
        </CardTitle>
        {instructions && (
          <CardDescription className="mt-2 text-sm text-primary/80">
            {instructions}
          </CardDescription>
        )}
      </CardHeader>
    </Card>
  );
};

export default QuestionHeader;

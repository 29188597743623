import { X } from "lucide-react";
import { useState } from "react";
import { Badge } from "../../../ui/badge.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";

const FileTypeSelector = ({ acceptedTypes, onAcceptedTypesChange }) => {
  const [selectKey, setSelectKey] = useState(0);

  const commonFileTypes = [
    { value: "all", label: "All File Types" },
    { value: ".pdf", label: "PDF" },
    { value: ".doc,.docx", label: "Word Document" },
    { value: ".jpg,.jpeg,.png", label: "Image" },
    { value: ".txt", label: "Text File" },
    { value: ".csv", label: "CSV" },
  ];

  const handleFileTypeSelect = (selectedType) => {
    if (selectedType && !acceptedTypes.includes(selectedType)) {
      onAcceptedTypesChange([...acceptedTypes, selectedType]);
      setSelectKey((prevKey) => prevKey + 1);
    }
  };

  const handleRemoveFileType = (typeToRemove) => {
    onAcceptedTypesChange(
      acceptedTypes.filter((type) => type !== typeToRemove)
    );
  };

  const allTypesSelected = acceptedTypes.length === commonFileTypes.length;

  return (
    <div className="space-y-4">
      <Select
        key={selectKey}
        onValueChange={handleFileTypeSelect}
        disabled={allTypesSelected}
      >
        <SelectTrigger className="w-full">
          <SelectValue
            placeholder={
              allTypesSelected ? "All types selected" : "Select a file type"
            }
          />
        </SelectTrigger>
        <SelectContent>
          {allTypesSelected ? (
            <SelectItem value="disabled" disabled>
              All file types selected
            </SelectItem>
          ) : (
            commonFileTypes
              .filter((type) => !acceptedTypes.includes(type.value))
              .map((type) => (
                <SelectItem key={type.value} value={type.value}>
                  {type.label}
                </SelectItem>
              ))
          )}
        </SelectContent>
      </Select>
      <div>
        <h4 className="mb-2 font-semibold">Accepted File Types:</h4>
        <div className="flex flex-wrap gap-2">
          {acceptedTypes.map((type) => (
            <Badge
              key={type}
              variant="secondary"
              className="px-3 py-1 text-sm font-medium"
            >
              {commonFileTypes.find((t) => t.value === type)?.label || type}
              <button
                onClick={() => handleRemoveFileType(type)}
                className="ml-2 rounded-full p-1 hover:bg-gray-200"
              >
                <X size={14} />
              </button>
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileTypeSelector;

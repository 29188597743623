import React, { useState, useEffect } from "react";
import api from "../../../api";
import { Button } from "../../ui/button";
import AssignmentCard from "./AssignmentCard";
import CarouselSkeletonLoader from "./Helpers/SkeletonLoader";
import CustomCarousel from "./CustomCarousel";

const AssignmentCarousel = ({ userId, assignments, initialAssignmentId }) => {
  const [assignmentData, setAssignmentData] = useState({});
  const [student, setStudent] = useState(null);
  const [assignmentDataLoading, setAssignmentDataLoading] = useState(true);
  const [studentLoading, setStudentLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchAssignmentData = async () => {
      try {
        setAssignmentDataLoading(true);
        const [submissionsResponse, gradesResponse, reportsResponse] =
          await Promise.all([
            Promise.all(
              assignments.map((assignment) =>
                api
                  .get(`/submissions/${userId}_${assignment.id}`)
                  .catch(() => ({ data: null }))
              )
            ),
            Promise.all(
              assignments.map((assignment) =>
                api
                  .get(`/grades/assignment/${assignment.id}/user/${userId}`)
                  .catch(() => ({ data: null }))
              )
            ),
            Promise.all(
              assignments.map((assignment) =>
                api
                  .get(`/reports/${userId}_${assignment.id}`)
                  .catch(() => ({ data: null }))
              )
            ),
          ]);

        const assignmentsData = assignments.map((assignment, index) => ({
          ...assignment,
          submission: submissionsResponse[index].data,
          grade: gradesResponse[index].data,
          report: reportsResponse[index].data,
        }));

        setAssignmentData(assignmentsData);
        setAssignmentDataLoading(false);
      } catch (error) {
        console.error("Error fetching assignment data: ", error);
        setError(error);
        setAssignmentDataLoading(false);
      }
    };

    const fetchStudentData = async () => {
      try {
        setStudentLoading(true);
        const studentResponse = await api.get(`/users/${userId}`);
        setStudent(studentResponse.data);
        setStudentLoading(false);
      } catch (error) {
        console.error("Error fetching student data: ", error);
        setError(error);
        setStudentLoading(false);
      }
    };

    fetchAssignmentData();
    fetchStudentData();
  }, [userId, assignments]);

  const handleGradeChange = (assignmentId, updatedGrade) => {
    setUnsavedChanges((prev) => ({
      ...prev,
      [assignmentId]: updatedGrade,
    }));
  };

  const submitAllGrades = async () => {
    setSubmitting(true);
    try {
      const submissionPromises = Object.entries(unsavedChanges).map(
        ([assignmentId]) => handleSaveGrade(assignmentId)
      );

      await Promise.all(submissionPromises);
    } catch (error) {
      console.error("Error submitting all grades:", error);
    } finally {
      setUnsavedChanges({});
      setSubmitting(false);
    }
  };

  const handleSaveGrade = async (assignmentId) => {
    try {
      const scoresObject = {};
      const commentsObject = {};
      const gradeData = unsavedChanges[assignmentId];
      const questions = assignments.find(
        (a) => a.id === assignmentId
      ).questions;

      questions.forEach((question) => {
        if (gradeData[question.id]?.score) {
          scoresObject[question.id] = parseInt(gradeData[question.id]?.score);
        }
        if (gradeData[question.id]?.comment) {
          commentsObject[question.id] = gradeData[question.id].comment;
        }
      });

      await api.post("/grades", {
        userId,
        assignmentId,
        questions,
        scores: scoresObject,
        comments: commentsObject,
      });

      setUnsavedChanges((prev) => {
        const { [assignmentId]: _, ...rest } = prev;
        return Object.keys(rest).length > 0 ? rest : {};
      });

      setAssignmentData((prevAssignments) =>
        prevAssignments.map((assignment) =>
          assignment.id === assignmentId
            ? { ...assignment, grade: gradeData }
            : assignment
        )
      );
    } catch (error) {
      console.error("Error saving grades:", error);
      throw error;
    }
  };

  if (assignmentDataLoading || studentLoading) {
    return <CarouselSkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const mapifyGrades = (gradeData) => {
    let gradeMap = {};
    if (gradeData.questions) {
      gradeMap = Object.fromEntries(
        gradeData?.questions?.map((question) => [
          question.questionId,
          {
            score: question.score,
            comment: question.comment,
          },
        ])
      );
    }
    return gradeMap;
  };

  return (
    <div className="max-w-6xl mx-auto py-8 px-4 sm:px-6 lg:px-8 w-full">
      <div className="flex items-center justify-between mb-6">
        <div className="w-1/4"></div>
        <h1 className="text-3xl font-bold text-primary text-center flex-grow">
          {student.firstName} {student.lastName}'s Submissions
        </h1>
        <div className="w-1/4 flex justify-end">
          <Button
            onClick={submitAllGrades}
            disabled={Object.keys(unsavedChanges).length === 0 || submitting}
            className="whitespace-nowrap"
          >
            {submitting
              ? "Submitting..."
              : Object.keys(unsavedChanges).length === 0
              ? "Submit Changes"
              : `Submit All Changes (${Object.keys(unsavedChanges).length})`}
          </Button>
        </div>
      </div>
      <CustomCarousel
        items={assignmentData}
        initialItemId={initialAssignmentId}
        getItemId={(item) => item.id}
        renderItem={(assignment) => (
          <AssignmentCard
            assignment={assignment}
            submission={assignment.submission}
            initialGrade={mapifyGrades(assignment.grade)}
            report={assignment.report || {}}
            chatLogs={assignment.chatLogs || {}}
            student={student}
            onGradeChange={handleGradeChange}
            onSaveGrade={handleSaveGrade}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      />
    </div>
  );
};

export default AssignmentCarousel;

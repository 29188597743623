import { useEffect, useState } from "react";
import SharedCard from "./SharedCard";

const SubmissionCard = ({
  assignment,
  submission,
  report,
  chatLogs,
  initialGrade,
  student,
  onGradeChange,
  onSaveGrade,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  const questions = assignment.questions;
  const answers = submission.answers;
  const questionIntegrityScores = submission.integrityScores || {};
  const studentName = student.firstName + " " + student.lastName;
  const userId = student.uid;
  const assignmentId = assignment.id;
  const reportId = `${userId}_${assignmentId}`;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [activeTab, setActiveTab] = useState("question");
  const [saving, setSaving] = useState(false);
  const [localGrade, setLocalGrade] = useState(initialGrade);
  const scoreArray = Object.keys(localGrade).map((id) => localGrade[id].score);
  const [previouslySaved, setPreviouslySaved] = useState(true);

  useEffect(() => {
    if (localGrade && initialGrade) {
      if (localAndInitialAreEqual(localGrade)) {
        setPreviouslySaved(true);
      } else {
        setPreviouslySaved(false);
      }
    }
  }, [localGrade, initialGrade]);

  useEffect(() => {
    if (unsavedChanges[student.uid]) {
      setLocalGrade((prevGrade) => ({
        ...prevGrade,
        ...unsavedChanges[student.uid],
      }));
    }
  }, [unsavedChanges, student.uid]);

  const handleScoreChange = (questionId, score) => {
    const updatedGrade = {
      ...localGrade,
      [questionId]: { ...localGrade[questionId], score },
    };
    setLocalGrade(updatedGrade);

    if (localAndInitialAreEqual(updatedGrade)) {
      setUnsavedChanges((prev) => {
        const { [student.uid]: _, ...rest } = prev;
        return Object.keys(rest).length > 0 ? rest : {};
      });
      setPreviouslySaved(true);
    } else {
      onGradeChange(student.uid, updatedGrade);
      setPreviouslySaved(false);
    }
  };

  useEffect(() => {
    setCurrentQuestion(questions[currentQuestionIndex]);
  }, [currentQuestionIndex, questions]);

  const handleQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleQuestionJump = (index) => {
    setCurrentQuestionIndex(index);
    setActiveTab("question");
  };

  const handleCommentChange = (questionId, comment) => {
    const updatedGrade = {
      ...localGrade,
      [questionId]: { ...localGrade[questionId], comment },
    };
    setLocalGrade(updatedGrade);

    if (localAndInitialAreEqual(updatedGrade)) {
      setUnsavedChanges((prev) => {
        const { [student.uid]: _, ...rest } = prev;
        return Object.keys(rest).length > 0 ? rest : {};
      });
      setPreviouslySaved(true);
    } else {
      onGradeChange(student.uid, updatedGrade);
      setPreviouslySaved(false);
    }
  };

  const localAndInitialAreEqual = (updatedGrade) => {
    if (Object.keys(updatedGrade).length !== Object.keys(initialGrade).length) {
      return false;
    }
    return Object.keys(updatedGrade).every((key) => {
      return (
        updatedGrade[key].score === initialGrade[key].score &&
        updatedGrade[key].comment === initialGrade[key].comment
      );
    });
  };

  const handleSaveGrade = async () => {
    setSaving(true);
    await onSaveGrade(student.uid);
    setPreviouslySaved(true);
    setSaving(false);
  };
  return (
    <SharedCard
      title={studentName}
      questions={questions}
      currentQuestionIndex={currentQuestionIndex}
      setCurrentQuestionIndex={setCurrentQuestionIndex}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      currentQuestion={currentQuestion}
      answers={answers}
      student={student}
      chatLogs={chatLogs}
      questionIntegrityScores={questionIntegrityScores}
      report={report}
      localGrade={localGrade}
      handleScoreChange={handleScoreChange}
      handleCommentChange={handleCommentChange}
      scoreArray={scoreArray}
      handleQuestionJump={handleQuestionJump}
      handleSaveGrade={handleSaveGrade}
      saving={saving}
      previouslySaved={previouslySaved}
      assignmentId={assignmentId}
    />
  );
};

export default SubmissionCard;

import React from "react";
import { Stamp } from "lucide-react";

const getQuestionIntegrityScore = (score) => {
  if (score === null) {
    score = 100;
  }
  let color;
  if (score >= 75) {
    color = "text-green-500";
  } else if (score >= 50) {
    color = "text-yellow-500";
  } else if (score >= 25) {
    color = "text-orange-500";
  } else {
    color = "text-red-500";
  }

  return (
    <div className="flex items-center space-x-2" title="Integrity Score">
      <Stamp className={`w-5 h-5 ${color}`} />
      <span className={`font-semibold ${color}`}>{score}</span>
    </div>
  );
};

export default getQuestionIntegrityScore;

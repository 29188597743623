import QuestionHeader from "../../layout/QuestionHeader";
import Coding from "../Questions/Coding";
import Essay from "../Questions/Essay";
import FileUpload from "../Questions/FileUpload";
import MultipleChoice from "../Questions/MultipleChoice";
import SelectAll from "../Questions/SelectAll";
import ShortAnswer from "../Questions/ShortAnswer";

const QuestionObject = ({
  question,
  questionIndex,
  answers,
  setAnswers,
  assignmentId,
  studentId,
  handleCutCopy,
  handlePaste,
}) => {
  switch (question.type) {
    case "short-answer":
      return (
        <ShortAnswer
          question={question}
          answers={answers}
          setAnswers={setAnswers}
          assignmentId={assignmentId}
          studentId={studentId}
          handleCutCopy={handleCutCopy}
          handlePaste={handlePaste}
        />
      );
    case "essay":
      return (
        <Essay
          question={question}
          answers={answers}
          setAnswers={setAnswers}
          assignmentId={assignmentId}
          studentId={studentId}
          handleCutCopy={handleCutCopy}
          handlePaste={handlePaste}
        />
      );
    case "multiple-choice":
      return (
        <MultipleChoice
          question={question}
          questionNumber={questionIndex}
          answers={answers}
          setAnswers={setAnswers}
        />
      );
    case "select-all":
      return (
        <SelectAll
          question={question}
          questionNumber={questionIndex}
          answers={answers}
          setAnswers={setAnswers}
        />
      );
    case "file-upload":
      return (
        <FileUpload
          question={question}
          questionNumber={questionIndex}
          answers={answers}
          setAnswers={setAnswers}
        />
      );
    case "coding":
      return (
        <Coding
          question={question}
          answers={answers}
          setAnswers={setAnswers}
          assignmentId={assignmentId}
          studentId={studentId}
          handleCutCopy={handleCutCopy}
          handlePaste={handlePaste}
        />
      );
    default:
      return <div>Unsupported question type.</div>;
  }
};

const QuestionRenderer = ({
  question,
  questionIndex,
  answers,
  setAnswers,
  assignmentId,
  recentTabSwitch,
  studentId,
  handleCutCopy,
  handlePaste,
}) => {
  if (!question) {
    return <div>No question found.</div>;
  }

  return (
    <div>
      <QuestionHeader question={question} />
      <QuestionObject
        question={question}
        questionIndex={questionIndex}
        answers={answers}
        setAnswers={setAnswers}
        assignmentId={assignmentId}
        studentId={studentId}
        handleCutCopy={handleCutCopy}
        handlePaste={handlePaste}
      />
    </div>
  );
};

export default QuestionRenderer;

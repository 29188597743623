import { Button } from "../../ui/button.jsx";

const colorOptions = [
  { name: "Cherry Red", value: "#D2042D" },
  { name: "Coral", value: "#FF7F50" },
  { name: "Tangerine", value: "#F28500" },
  { name: "Sunflower Yellow", value: "#FFD700" },
  { name: "Lime Green", value: "#32CD32" },
  { name: "Forest Green", value: "#228B22" },
  { name: "Sky Blue", value: "#87CEEB" },
  { name: "Royal Blue", value: "#4169E1" },
  { name: "Indigo", value: "#4B0082" },
  { name: "Plum", value: "#8E4585" },
  { name: "Pink", value: "#FF69B4" },
  { name: "Slate Gray", value: "#708090" },
  { name: "Charcoal", value: "#36454F" },
];

const getAvailableThemes = (excludedColorThemes) => {
  return colorOptions.filter(
    (color) => !excludedColorThemes.includes(color.value)
  );
};

const ColorSelector = ({
  selectedColor,
  onColorChange,
  excludedColorThemes,
}) => {
  const availableColors = getAvailableThemes(excludedColorThemes);
  return (
    <div className="flex flex-wrap gap-1 max-w-xs">
      {availableColors.map((color) => (
        <Button
          key={color.name}
          type="button"
          className={`w-6 h-6 rounded-full p-0 ${
            selectedColor === color.value
              ? "ring-2 ring-offset-2 ring-black"
              : ""
          }`}
          style={{ backgroundColor: color.value }}
          onClick={() => onColorChange(color.value)}
          title={color.name}
        />
      ))}
    </div>
  );
};

export default ColorSelector;

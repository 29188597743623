import { useEffect, useState } from "react";
import api from "../../../api";
import { ScrollArea } from "../../ui/scroll-area.jsx";
import { Skeleton } from "../../ui/skeleton.jsx";
import ChatMessage from "./ChatMessage";

const ChatLogRenderer = ({ chatLogId, questionId, studentName }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await api.get(
          `/chatLogs/${chatLogId}/question`,
          { params: { questionId } }
        );
        setHistory(response.data);
      } catch (error) {
        console.error("Error fetching history: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchHistory();
  }, [chatLogId, questionId]);

  return (
    <ScrollArea className="h-full w-full">
      <div className="space-y-4 p-4">
        {loading ? (
          <div className="space-y-4">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="space-y-2">
                <Skeleton className="h-4 w-[100px]" />
                <Skeleton className="h-4 w-[200px]" />
                <Skeleton className="h-4 w-[150px]" />
              </div>
            ))}
          </div>
        ) : (
          <>
            {history.map((chat, index) => (
              <ChatMessage
                key={index}
                type={chat.type}
                content={chat.chat}
                timestamp={chat.timestamp}
                studentName={studentName}
              />
            ))}
          </>
        )}
      </div>
    </ScrollArea>
  );
};

export default ChatLogRenderer;

import { Trash2 } from "lucide-react";
import { Button } from "../../../ui/button.jsx";
import { Card, CardContent } from "../../../ui/card.jsx";
import ChoiceInput from "./ChoiceInput";
import CorrectChoiceBox from "./CorrectChoiceBox";

const Choice = ({
  choices,
  onChoicesChange,
  correctChoices,
  onCorrectChoicesChange,
  index,
  questionType,
}) => {
  const handleDelete = () => {
    const updatedChoices = choices.filter((_, i) => i !== index);
    onChoicesChange(updatedChoices);

    const updatedCorrectChoices = correctChoices
      .filter((choiceIndex) => choiceIndex !== index)
      .map((choiceIndex) =>
        choiceIndex > index ? choiceIndex - 1 : choiceIndex
      );
    onCorrectChoicesChange(updatedCorrectChoices);
  };

  return (
    <Card className="mb-4">
      <CardContent className="p-4">
        <div className="flex items-center w-full">
          <div className="flex-grow mr-4">
            <ChoiceInput
              choices={choices}
              onChange={onChoicesChange}
              index={index}
            />
          </div>
          <div className="flex items-center">
            <div className="flex-grow mr-2">
              <CorrectChoiceBox
                index={index}
                onCorrectChoicesChange={onCorrectChoicesChange}
                correctChoices={correctChoices}
                questionType={questionType}
              />
            </div>
            <Button variant="destructive" size="icon" onClick={handleDelete}>
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Choice;

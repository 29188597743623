import { BookText } from "lucide-react";
import SharedAssignmentList from "./SharedAssignmentList";

const AllAssignments = ({
  inProgressAssignments,
  unstartedAssignments,
  loading,
  error,
}) => {
  return (
    <SharedAssignmentList
      title="All Assignments"
      icon={<BookText className="mr-2" />}
      isLoading={loading}
      error={error}
      inProgressAssignments={inProgressAssignments}
      unstartedAssignments={unstartedAssignments}
    />
  );
};

export default AllAssignments;

import { Button } from "../../ui/button.jsx";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog.jsx";
import ChatLogRenderer from "./ChatLogRenderer";

const ChatLogPopup = ({ chatLogId, questionId, studentName, onClose }) => {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] h-[90vh] max-h-[90vh] flex flex-col">
        <DialogHeader className="flex-shrink-0 pb-4 border-b">
          <DialogTitle className="text-xl font-semibold text-primary">
            Chat log for {studentName}
          </DialogTitle>
        </DialogHeader>
        <div className="flex-grow overflow-hidden py-4">
          <ChatLogRenderer chatLogId={chatLogId} questionId={questionId} studentName={studentName} />
        </div>
        <DialogClose asChild>
          <Button variant="outline" className="mt-4 w-full sm:w-auto">
            Close
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default ChatLogPopup;

import { BookCheck, ChevronRight, FileCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ListCard from "../../shared/ListCard";
import { formatTimestamp } from "../../shared/utils/Timestamp";

const RecentSubmissions = ({ submissions, assignments, loading }) => {
  const [recentSubmissions, setRecentSubmissions] = useState([]);
  const [matchingAssignments, setMatchingAssignments] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!loading) {
      const sortedSubmissions = submissions.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      const recent = sortedSubmissions.slice(0, 5);
      setRecentSubmissions(recent);

      const assignmentMap = {};
      recent.forEach((submission) => {
        const matchingAssignment = assignments.find(
          (assignment) => assignment.id === submission.assignmentId
        );
        if (matchingAssignment) {
          assignmentMap[submission.assignmentId] = matchingAssignment.title;
        }
      });
      setMatchingAssignments(assignmentMap);
    }
  }, [submissions, assignments, loading]);

  const renderSubmissionItem = (submission) => (
    <Link
      key={submission.id}
      to={`/assignment/submissions/review/${submission.assignmentId}`}
      className="block"
    >
      <div className="flex items-center space-x-4 p-4 bg-card hover:bg-accent rounded-lg transition-colors duration-200">
        <div className="bg-primary/10 p-3 rounded-full group-hover:bg-primary/20 transition-colors">
          <FileCheck className="h-5 w-5 text-primary" />
        </div>
        <div className="flex-grow">
          <h3 className="font-semibold text-lg group-hover:text-primary transition-colors truncate">
            {matchingAssignments[submission.assignmentId] ||
              "Unknown Assignment"}
          </h3>
          <p className="text-sm text-muted-foreground">
            Submitted {formatTimestamp(submission.timestamp)}
          </p>
        </div>
        <ChevronRight className="text-muted-foreground group-hover:text-primary transition-colors" />
      </div>
    </Link>
  );

  return (
    <ListCard
      title="Recent Submissions"
      items={recentSubmissions}
      isLoading={loading}
      error={error}
      renderItem={renderSubmissionItem}
      emptyMessage="No recent submissions."
      icon={BookCheck}
      maxHeight={400}
    />
  );
};

export default RecentSubmissions;

import { useRef } from "react";

const TeacherCodingTextArea = ({
  localCode,
  handleChange,
  handleKeyDown,
  syncScroll,
  disabled,
}) => {
  const textareaRef = useRef(null);

  return (
    <textarea
      ref={textareaRef}
      value={localCode}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onScroll={syncScroll}
      className="w-full h-64 bg-transparent text-transparent caret-white font-mono p-4 absolute top-0 left-0 resize-none outline-none z-10"
      spellCheck="false"
      style={{
        caretColor: "white",
        WebkitTextFillColor: "transparent",
      }}
      readOnly={disabled}
    />
  );
};

export default TeacherCodingTextArea;

import CourseCard from "./CourseCard";

const TeacherCourseCard = ({ course }) => {
  const headerContent = (
    <p className="text-lg md:text-xl lg:text-2xl text-primary-foreground/80 group-hover:text-primary-foreground transition-colors duration-300">
      {course.code}
    </p>
  );

  const bodyContent = (
    <p className="text-muted-foreground">No recent submissions</p>
    // Add recent submissions or other data later
  );

  return (
    <CourseCard
      course={course}
      headerContent={headerContent}
      bodyContent={bodyContent}
      linkTo={`/course/${course.id}/teacher`}
    />
  );
};

export default TeacherCourseCard;

import { useParams } from "react-router-dom";
import BaseLayout from "../../components/layout/BaseLayout";
import NavigationLayout from "../../components/layout/NavigationLayout";
import StudentSubmissions from "../../components/teacher/Lists/SubmissionLists/StudentSubmissions";
import { useUser } from "../../Contexts/userContext";

const StudentSubPage = () => {
  const { courseId, studentId } = useParams();
  const { courses, assignments } = useUser();
  const course = courses.find((course) => course.id === courseId);

  const assignmentIds = course.assignments.map(
    (assignmentRef) => assignmentRef._path.segments.slice(-1)[0]
  );
  const courseAssignments = assignments.filter((assignment) =>
    assignmentIds.some((id) => id === assignment.id)
  );

  return (
    <div>
      <BaseLayout>
        <NavigationLayout>
          <div style={{ height: "60px" }}></div>
          <StudentSubmissions
            assignments={courseAssignments}
            studentId={studentId}
            courseId={courseId}
          />
        </NavigationLayout>
      </BaseLayout>
    </div>
  );
};

export default StudentSubPage;

import { useEffect, useMemo, useState } from "react";
import api from "../../../api";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card.jsx";
import { Skeleton } from "../../ui/skeleton.jsx";
import { toast } from "../../ui/use-toast";
import SubmissionAccordion from "./SubmissionAccordion";

const StudentSubmissionReview = ({ assignment, submission }) => {
  const questions = useMemo(() => assignment?.questions, [assignment]);
  const answers = useMemo(() => submission?.answers, [submission]);
  const userId = submission.userId;
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [grade, setGrade] = useState({});
  const [isGraded, setIsGraded] = useState(false);
  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    const fetchGrade = async () => {
      if (submission && assignment) {
        try {
          setIsLoading(true);
          const gradeResponse = await api.get(
            `/grades/assignment/${assignment.id}/user/${submission.userId}`
          );
          const grade = gradeResponse.data;
          const graded = grade.gradeStatus === "graded";
          const pastDueDate = Date.now() > assignment.dueDate; //might include later

          if (grade && graded && grade.published) {
            setGrade(gradeResponse.data);
            setIsGraded(true);
            setTotalScore(totalScore);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          toast({
            title: "Error",
            description:
              "There was an error loading the submission data. Please try again.",
            variant: "destructive",
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchGrade();
  }, [submission, assignment]);

  return (
    <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <Card className="bg-white shadow-lg rounded-lg overflow-hidden">
        <CardHeader className="bg-primary text-white p-6">
          <CardTitle className="text-2xl">
            {isGraded
              ? `Submission for ${assignment?.title} (${grade.totalScore}/${assignment.totalPoints})`
              : `Submission for ${assignment?.title} (Not yet graded)`}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          {isLoading ? (
            <div className="space-y-4">
              <Skeleton className="h-4 w-full bg-gray-200" />
              <Skeleton className="h-4 w-full bg-gray-200" />
              <Skeleton className="h-4 w-full bg-gray-200" />
            </div>
          ) : questions.length > 0 && answers !== null ? (
            <div className="space-y-6">
              <SubmissionAccordion
                questions={questions}
                answers={answers}
                expandedQuestions={expandedQuestions}
                setExpandedQuestions={setExpandedQuestions}
                userId={userId}
                assignmentId={assignment.id}
                setSelectedQuestionId={setSelectedQuestionId}
                grade={grade}
                isGraded={isGraded}
              />
            </div>
          ) : (
            <div className="text-center text-gray-600">
              No questions or answers available.
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default StudentSubmissionReview;

import { useEffect, useState } from "react";
import { renderQuestion } from "../../shared/Responses/ResponseRenderer";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion.jsx";
import { Button } from "../../ui/button.jsx";
import { Label } from "../../ui/label.jsx";

const SubmissionAccordion = ({
  questions,
  answers,
  expandedQuestions,
  setExpandedQuestions,
  userId,
  assignmentId,
  setSelectedQuestionId,
  grade,
  isGraded,
}) => {
  let questionMap = {};
  if (grade.questions) {
    questionMap = Object.fromEntries(
      grade?.questions?.map((question) => [
        question.questionId,
        {
          score: question.score,
          comment: question.comment,
        },
      ])
    );
  }

  const [allExpanded, setAllExpanded] = useState(false);

  useEffect(() => {
    if (allExpanded) {
      setExpandedQuestions(questions.map((question) => question.id));
    } else {
      setExpandedQuestions([]);
    }
  }, [allExpanded, questions, setExpandedQuestions]);

  useEffect(() => {
    if (expandedQuestions.length === questions.length) {
      setAllExpanded(true);
    } else {
      setAllExpanded(false);
    }
  }, [expandedQuestions]);

  return (
    <div className="space-y-6">
      <Button
        variant="outline"
        size="sm"
        onClick={() => {
          setAllExpanded(!allExpanded);
          setExpandedQuestions(
            allExpanded ? [] : questions.map((question) => question.id)
          );
        }}
        className="w-full sm:w-auto mb-4 text-primary border-primary hover:bg-primary"
      >
        {allExpanded ? "Collapse All" : "Expand All"}
      </Button>
      <Accordion
        type="multiple"
        value={expandedQuestions}
        onValueChange={setExpandedQuestions}
        className="space-y-4"
      >
        {questions.map((question, index) => (
          <AccordionItem
            key={question.id}
            value={question.id}
            className="border border-gray-200 rounded-lg overflow-hidden shadow-sm"
          >
            <AccordionTrigger className="hover:no-underline px-4 py-3 bg-gray-50 hover:bg-gray-100 transition-colors duration-200">
              <div className="flex items-center justify-between w-full">
                <span className="text-lg font-semibold text-primary">{`Question ${
                  index + 1
                }`}</span>
                {isGraded && (
                  <div className="score-display text-sm font-medium text-gray-600">
                    Score: {questionMap[question.id]?.score || 0}/
                    {question.points}
                  </div>
                )}
                {!isGraded && (
                  <div className="score-display bold text-sm font-medium text-gray-600">
                    Points: {question.points}{" "}
                  </div>
                )}
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-4 py-3 bg-white">
              <div className="space-y-4">
                {renderQuestion(question, answers, null, isGraded)}
                {isGraded && (
                  <div className="comment-section mt-4 p-3 bg-gray-50 rounded border border-gray-200">
                    <Label className="text-sm font-medium text-gray-700">
                      Comment:
                    </Label>
                    <p className="mt-1 text-sm text-gray-600">
                      {questionMap[question.id]?.comment || "No comment"}
                    </p>
                  </div>
                )}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default SubmissionAccordion;

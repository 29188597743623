import BaseLayout from "../../components/layout/BaseLayout";
import MasterAssignmentList from "../../components/student/Lists/AllAssignments";
import StudentCourseList from "../../components/student/Lists/Courses";
import RecentSubmissions from "../../components/student/Lists/RecentSubmissions";
import { useUser } from "../../Contexts/userContext";
import { useAssignments } from "../../hooks/useAssignments";

const StudentHomepage = () => {
  const {
    error,
    courses,
    submissions,
    assignments,
    loading: userLoading,
  } = useUser();
  const { getUnstartedAssignments, getInProgressAssignments } =
    useAssignments();

  const unstartedAssignments =
    assignments && submissions ? getUnstartedAssignments() : [];
  const inProgressAssignments =
    assignments && submissions ? getInProgressAssignments() : [];

  return (
    <BaseLayout>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Your Dashboard</h1>
        </div>
        <StudentCourseList courses={courses} loading={userLoading} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <MasterAssignmentList
            unstartedAssignments={unstartedAssignments}
            inProgressAssignments={inProgressAssignments}
            loading={userLoading}
            error={error}
          />
          <RecentSubmissions
            submissions={submissions}
            assignments={assignments}
            loading={userLoading}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

export default StudentHomepage;

import { useEffect, useRef, useState } from "react";
import { useUser } from "../../../Contexts/userContext";
import { Card, CardContent } from "../../ui/card.jsx";
import CodeEditorHeader from "./CodeEditorHeader";
import { stripColorStyling } from "./ColorStripper";
import { getStarterCode } from "./StarterCode";
import StudentCodingTextArea from "./StudentCodingTextArea";
import SyntaxHighlighter from "./SyntaxHighlighter";
import TeacherCodingTextArea from "./TeacherCodingTextArea";

const CodeEditor = ({
  initialCode,
  onChange,
  language,
  handleCutCopy,
  handlePaste,
  disabled = false,
}) => {
  const [localCode, setLocalCode] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const preRef = useRef(null);
  const isInitialMount = useRef(true);
  const { user } = useUser();
  const userRole = user.role;
  const previousLanguageRef = useRef(language);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      processInitialCode(initialCode || getStarterCode(language, userRole));
    } else if (
      initialCode !== undefined &&
      initialCode !== null &&
      initialCode !== localCode
    ) {
    } else if (language !== previousLanguageRef.current) {
      processInitialCode(getStarterCode(language, userRole));
    }
    previousLanguageRef.current = language;
  }, [initialCode, language, userRole]);

  const processInitialCode = (code) => {
    if (code.startsWith("<pre") || code.startsWith("<code")) {
      const strippedHtml = stripColorStyling(code);
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = strippedHtml;
      const processedCode = tempDiv.textContent || tempDiv.innerText;
      setLocalCode(processedCode);
      setHtmlContent(strippedHtml);
    } else {
      setLocalCode(code);
      updateHtmlContent(code);
    }
  };

  const handleChange = (e) => {
    const newCode = e.target.value;
    setLocalCode(newCode);
    updateHtmlContent(newCode);
  };

  const updateHtmlContent = (code) => {
    if (preRef.current) {
      const rawHtml = preRef.current.innerHTML;
      const strippedHtml = stripColorStyling(rawHtml);
      setHtmlContent(strippedHtml);
      onChange(strippedHtml);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      const newCode =
        localCode.substring(0, start) + "  " + localCode.substring(end);
      setLocalCode(newCode);
      updateHtmlContent(newCode);

      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = start + 2;
      }, 0);
    }
  };

  const syncScroll = (e) => {
    if (preRef.current) {
      preRef.current.scrollTop = e.target.scrollTop;
      preRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  useEffect(() => {
    updateHtmlContent(localCode);
  }, [localCode]);

  return (
    <Card className="w-full max-w-2xl mx-auto bg-gray-900 border-gray-800">
      <CodeEditorHeader language={language} />
      <CardContent className="p-0 relative">
        {userRole === "student" ? (
          <>
            <StudentCodingTextArea
              localCode={localCode}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              syncScroll={syncScroll}
              handleCutCopy={handleCutCopy}
              handlePaste={handlePaste}
              disabled={disabled}
            />
          </>
        ) : (
          <TeacherCodingTextArea
            localCode={localCode}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            syncScroll={syncScroll}
            disabled={disabled}
          />
        )}
        <SyntaxHighlighter
          localCode={localCode}
          preRef={preRef}
          language={language}
        />
      </CardContent>
    </Card>
  );
};

export default CodeEditor;

import { AlarmClock, Clock, FileCheck } from "lucide-react";
import { Link } from "react-router-dom";
import ListCard from "../../shared/ListCard";
import { convertToDate } from "../../shared/utils/Timestamp";
import { Badge } from "../../ui/badge.jsx";

const UpcomingAssignments = ({ assignments, submissions, loading, error }) => {
  const assignmentSubmitted = (assignment) => {
    return submissions.some(
      (submission) => submission.assignmentId === assignment.id &&
      submission.submitted
    );
  };

  const renderAssignment = (assignment) => {
    const submitted = assignmentSubmitted(assignment);
    const url = submitted
      ? `/assignment/submissions/review/${assignment.id}`
      : `/assignment/${assignment.id}`;
    return (
      <Link to={url} className="block">
        <div className="flex items-center space-x-4 p-4 ">
          <div className="bg-primary/10 p-3 rounded-full group-hover:bg-primary/20 transition-colors">
            {submitted ? (
              <FileCheck className="text-primary" />
            ) : (
              <AlarmClock className="text-primary" />
            )}
          </div>
          <div className="flex-grow">
            <h3 className="font-semibold text-lg group-hover:text-primary transition-colors">
              {assignment.title}
            </h3>
            <p className="text-sm text-muted-foreground">
              Due: {convertToDate(assignment.dueDate)}
            </p>
          </div>
          {submitted ? (
            <Badge variant="success">Submitted</Badge>
          ) : (
            <Badge variant="destructive">Not Submitted</Badge>
          )}
        </div>
      </Link>
    );
  };

  return (
    <ListCard
      title="Upcoming Assignments"
      items={assignments}
      isLoading={loading}
      error={error}
      renderItem={renderAssignment}
      emptyMessage="Nothing due in the next week."
      icon={Clock}
      maxHeight={300}
    />
  );
};

export default UpcomingAssignments;

import { useParams } from "react-router-dom";
import BaseLayout from "../../components/layout/BaseLayout";
import StudentCourseHeader from "../../components/layout/student/CourseHeader";
import CourseAssignmentList from "../../components/student/Lists/CourseAssignments";
import UpcomingAssignments from "../../components/student/Lists/UpcomingAssignments";
import CourseSubmissions from "../../components/student/Submissions/CourseSubmissions";
import { useUser } from "../../Contexts/userContext";
import { useCourseData } from "../../hooks/useCourseData";

const StudentCourseView = () => {
  const { courseId } = useParams();
  const { user, loading: userLoading, error } = useUser();
  const {
    course,
    courseSubmissions,
    courseAssignments,
    upcomingAssignments,
    unstartedAssignments,
    inProgressAssignments,
  } = useCourseData(courseId);

  const loading = userLoading;

  return (
    <BaseLayout>
      <div className="container mx-auto px-4 py-8">
        <StudentCourseHeader course={course} loading={loading} />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <CourseAssignmentList
            inProgressAssignments={inProgressAssignments}
            unstartedAssignments={unstartedAssignments}
            loading={loading}
          />
          <UpcomingAssignments
            assignments={upcomingAssignments}
            submissions={courseSubmissions}
            loading={loading}
          />
          <div className="lg:col-span-2">
            <CourseSubmissions
              submissions={courseSubmissions}
              assignments={courseAssignments}
              loading={loading}
              userId={user?.uid}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default StudentCourseView;

import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "../../../ui/button.jsx";

const QuestionNavigation = ({ currentIndex, totalQuestions, onNavigate }) => (
  <div className="flex justify-between items-center">
    <Button
      variant="outline"
      onClick={() => onNavigate(currentIndex - 1)}
      disabled={currentIndex === 0}
    >
      <ChevronLeft className="w-4 h-4 mr-2" /> Previous
    </Button>
    <span className="font-semibold">
      Question {currentIndex + 1} of {totalQuestions}
    </span>
    <Button
      variant="outline"
      onClick={() => onNavigate(currentIndex + 1)}
      disabled={currentIndex === totalQuestions - 1}
    >
      Next <ChevronRight className="w-4 h-4 ml-2" />
    </Button>
  </div>
);

export default QuestionNavigation;

import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button.jsx";

const NavigationLayout = ({ children }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen">
      <Button
        variant="ghost"
        size="icon"
        className="absolute top-4 left-4 z-10 shadow-sm hover:bg-background/90"
        onClick={handleGoBack}
      >
        <ArrowLeft className="h-6 w-6" />
      </Button>
      <div>{children}</div>
    </div>
  );
};

export default NavigationLayout;

import DOMPurify from "dompurify";
import { Badge } from "../../ui/badge";

const EssayRenderer = ({ question, answer }) => {
  const sanitizedAnswer = DOMPurify.sanitize(answer);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Badge 
          variant="secondary" 
          className="mb-2 text-xs font-semibold bg-secondary text-secondary-foreground"
        >
          Essay
        </Badge>
        <h3 className="text-lg font-semibold text-foreground">{question.text}</h3>
      </div>
      <div className="space-y-2">
        <p className="font-medium text-sm text-muted-foreground">Response:</p>
        <div
          className="bg-accent/10 border border-border p-4 rounded-md text-foreground text-sm leading-relaxed"
          dangerouslySetInnerHTML={{ __html: sanitizedAnswer }}
        />
      </div>
    </div>
  );
};

export default EssayRenderer;

import { useEffect, useState } from "react";
import SharedCard from "./SharedCard";

const AssignmentCard = ({
  assignment,
  submission,
  initialGrade,
  report,
  chatLogs,
  student,
  onGradeChange,
  onSaveGrade,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const questions = assignment.questions;
  const answers = submission?.answers || {};
  const questionIntegrityScores = submission?.integrityScores || {};
  const assignmentId = assignment.id;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [activeTab, setActiveTab] = useState("question");
  const [saving, setSaving] = useState(false);
  const [localGrade, setLocalGrade] = useState(initialGrade);
  const scoreArray = Object.keys(localGrade).map((id) => localGrade[id].score);
  const [previouslySaved, setPreviouslySaved] = useState(true);

  useEffect(() => {
    if (localGrade && initialGrade) {
      if (
        localAndInitialAreEqual(localGrade) ||
        !unsavedChanges[assignment.id]
      ) {
        setPreviouslySaved(true);
      } else {
        setPreviouslySaved(false);
      }
    }
  }, [localGrade, initialGrade]);

  useEffect(() => {
    if (unsavedChanges[assignmentId]) {
      setLocalGrade((prevGrade) => ({
        ...prevGrade,
        ...unsavedChanges[assignmentId],
      }));
    }
  }, [unsavedChanges, assignmentId]);

  useEffect(() => {
    setCurrentQuestion(questions[currentQuestionIndex]);
  }, [currentQuestionIndex, questions]);

  const handleQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleQuestionJump = (index) => {
    setCurrentQuestionIndex(index);
    setActiveTab("question");
  };

  const handleScoreChange = (questionId, score) => {
    const updatedGrade = {
      ...localGrade,
      [questionId]: { ...localGrade[questionId], score },
    };
    setLocalGrade(updatedGrade);

    if (localAndInitialAreEqual(updatedGrade)) {
      setUnsavedChanges((prev) => {
        const { [assignmentId]: _, ...rest } = prev;
        return Object.keys(rest).length > 0 ? rest : {};
      });
      setPreviouslySaved(true);
    } else {
      onGradeChange(assignmentId, updatedGrade);
      setPreviouslySaved(false);
    }
  };

  const handleCommentChange = (questionId, comment) => {
    const updatedGrade = {
      ...localGrade,
      [questionId]: { ...localGrade[questionId], comment },
    };
    setLocalGrade(updatedGrade);

    if (localAndInitialAreEqual(updatedGrade)) {
      setUnsavedChanges((prev) => {
        const { [assignmentId]: _, ...rest } = prev;
        return Object.keys(rest).length > 0 ? rest : {};
      });
      setPreviouslySaved(true);
    } else {
      onGradeChange(assignmentId, updatedGrade);
      setPreviouslySaved(false);
    }
  };

  const localAndInitialAreEqual = (updatedGrade) => {
    if (Object.keys(updatedGrade).length !== Object.keys(initialGrade).length) {
      return false;
    }

    return Object.keys(updatedGrade).every((key) => {
      return (
        updatedGrade[key].score === initialGrade[key].score &&
        updatedGrade[key].comment === initialGrade[key].comment
      );
    });
  };

  const handleSaveGrade = async () => {
    setSaving(true);
    await onSaveGrade(assignmentId);
    setPreviouslySaved(true);
    setSaving(false);
  };

  return (
    <SharedCard
      title={assignment.title}
      questions={questions}
      currentQuestionIndex={currentQuestionIndex}
      setCurrentQuestionIndex={setCurrentQuestionIndex}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      currentQuestion={currentQuestion}
      answers={answers}
      student={student}
      questionIntegrityScores={questionIntegrityScores}
      report={report}
      chatLogs={chatLogs}
      localGrade={localGrade}
      handleScoreChange={handleScoreChange}
      handleCommentChange={handleCommentChange}
      scoreArray={scoreArray}
      handleQuestionJump={handleQuestionJump}
      handleSaveGrade={handleSaveGrade}
      saving={saving}
      previouslySaved={previouslySaved}
      userId={student.uid}
      assignmentId={assignmentId}
    />
  );
};

export default AssignmentCard;

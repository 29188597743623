export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
};

export function generateTimestamp() {
  const now = new Date();
  return now.getTime();
}

export function convertToDate(milliseconds) {
  const date = new Date(milliseconds);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: undefined,
    hour12: true
  });
}

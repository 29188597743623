import { useEffect, useRef, useState } from "react";
import CodeEditor from "../../shared/Coding/CodeEditor";

const Coding = ({
  question,
  answers,
  setAnswers,
  assignmentId,
  studentId,
  handleCutCopy,
  handlePaste,
}) => {
  const [code, setCode] = useState(question.codeHtml);
  // const [currentCode, setCurrentCode] = useState("");
  const prevQuestionIdRef = useRef();

  useEffect(() => {
    if (question.id !== prevQuestionIdRef.current) {
      const answerCode = answers[question.id] || question.codeHtml || "";
      setCode(answerCode);
      // setCurrentCode(answerCode);
      prevQuestionIdRef.current = question.id;
    }
  }, [question.id, answers, question.codeHtml]);

  const handleChange = (code) => {
    setCode(code);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question.id]: code,
    }));
  };

  return (
    <div>
      <CodeEditor
        onChange={handleChange}
        language={question.codingLanguage}
        initialCode={code}
        key={question.id}
        handleCutCopy={handleCutCopy}
        handlePaste={handlePaste}
      />
      {/* <VersionControl
        documentId={`${studentId}_${assignmentId}_${question.id}`}
        currentContent={currentCode}
        threshold={5}
      /> */}
    </div>
  );
};

export default Coding;

import { Link } from "react-router-dom";
import { useCourseData } from "../../../hooks/useCourseData";
import CourseCard from "./CourseCard";

const StudentCourseCard = ({ course, onClick }) => {
  const { upcomingAssignments, courseSubmissions } = useCourseData(course.id);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayMs = today.getTime();

  const submissionsSet = new Set(
    courseSubmissions.map((submission) => submission.assignmentId)
  );

  const assignments = upcomingAssignments.filter(
    (assignment) => !submissionsSet.has(assignment.id)
  );

  const headerContent = (
    <p className="text-lg md:text-xl lg:text-2xl text-primary-foreground/80 group-hover:text-primary-foreground transition-colors duration-300">
      {course.instructorName}
    </p>
  );

  const bodyContent = (
    <>
      <h4 className="text-xl font-semibold mb-4">Upcoming Assignments</h4>
      <div
        className="flex-grow flex flex-col justify-start"
        style={{ minHeight: "6rem" }}
      >
        {assignments.length > 0 ? (
          assignments.map((assignment, index) => (
            <div key={index} className="mb-2 truncate">
              <Link
                to={`/assignment/${assignment.id}`}
                onClick={(e) => e.stopPropagation()}
                className="transition-all duration-200 ease-in-out hover:text-blue-600 hover:font-bold"
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  width: "100%",
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                <p className="font-medium">
                  {assignment.title} Due:{" "}
                  {new Date(assignment.dueDate).getTime() === todayMs
                    ? "Today"
                    : new Date(assignment.dueDate).toLocaleDateString()}
                </p>
              </Link>
            </div>
          ))
        ) : (
          <p>No upcoming assignments</p>
        )}
      </div>
    </>
  );

  return (
    <CourseCard
      course={course}
      headerContent={headerContent}
      bodyContent={bodyContent}
      linkTo={`/course/${course.id}/student`}
      onClick={onClick}
    />
  );
};

export default StudentCourseCard;

import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import { ScrollArea } from "../../../ui/scroll-area.jsx";
import getQuestionIntegrityScore from "../Helpers/QuestionIntegrityScore.js";

const OverviewList = ({
  questions,
  scores,
  onQuestionSelect,
  questionIntegrityScores,
}) => (
  <ScrollArea className="h-[600px]">
    {questions.map((question, index) => (
      <Card
        key={question.id}
        className="mb-4 cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={() => onQuestionSelect(index)}
      >
        <CardHeader>
          <CardTitle className="text-lg flex items-center justify-between">
            Question {index + 1}
            {getQuestionIntegrityScore(questionIntegrityScores[question.id])}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="mb-2">{question.text}</p>
          <div className="flex justify-between items-center">
            <span>
              Score: {scores[index] || "Not graded"} / {question.points}
            </span>
          </div>
        </CardContent>
      </Card>
    ))}
  </ScrollArea>
);

export default OverviewList;

import { CheckCircle2, XCircle } from "lucide-react";
import { Badge } from "../../ui/badge";
import { Checkbox } from "../../ui/checkbox.jsx";
import { Label } from "../../ui/label.jsx";

const SelectAllResponse = ({ question, answer, showCorrectAnswer }) => {
  return (
    <div className="space-y-4">
      <Badge
        variant="secondary"
        className="mb-2 text-xs font-semibold bg-secondary text-secondary-foreground"
      >
        Select All
      </Badge>
      <h3 className="text-lg font-semibold text-foreground">{question.text}</h3>
      <p className="font-medium text-sm text-muted-foreground">Response:</p>
      <div className="space-y-2">
        {question.choices.map((choice, index) => {
          const isCorrectChoice = question.correctChoices.includes(index);
          const isSelectedChoice = answer?.includes(choice) || false;
          const isIncorrectSelection = isSelectedChoice && !isCorrectChoice;
          const isMissedCorrectChoice = !isSelectedChoice && isCorrectChoice;

          return (
            <div
              key={index}
              className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100"
            >
              <Checkbox
                id={`choice-${index}`}
                checked={isSelectedChoice}
                readOnly
                className="h-4 w-4"
              />
              <Label
                htmlFor={`choice-${index}`}
                className={`flex-grow ${
                  showCorrectAnswer
                    ? `${isCorrectChoice ? "font-semibold" : ""}
                       ${isIncorrectSelection ? "text-red-500" : ""}
                       ${isMissedCorrectChoice ? "text-green-500" : ""}`
                    : ""
                }`}
              >
                {choice}
              </Label>
              {showCorrectAnswer ? (
                <>
                  {isSelectedChoice &&
                    (isCorrectChoice ? (
                      <CheckCircle2 className="text-green-500" size={20} />
                    ) : (
                      <XCircle className="text-red-500" size={20} />
                    ))}
                  {isMissedCorrectChoice && (
                    <CheckCircle2
                      className="text-green-500 opacity-50"
                      size={20}
                    />
                  )}
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectAllResponse;

import he from "he";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import api from "../../../api";

const VersionControl = forwardRef(
  ({ userId, assignmentId, questionId, currentContent }, ref) => {
    const lastSavedContentRef = useRef("");
    const isSavingRef = useRef(false);
    const lastCompleteSentencesRef = useRef([]);
    const lastPasteTimeRef = useRef(0);
    const lastPasteContentRef = useRef("");

    const PASTE_TIMEOUT = 1000;

    const extractTextContent = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      const rawText = tempDiv.textContent || tempDiv.innerText || "";
      return he.decode(rawText).trim();
    };

    const versionId = `${userId}_${assignmentId}`;

    const saveVersion = useCallback(
      async (content) => {
        if (isSavingRef.current) return;

        isSavingRef.current = true;
        try {
          const existenceResponse = await api.get(
            `/versions/${versionId}/exists`,
            { params: { questionId } }
          );
          const exists = existenceResponse.data.exists;
          if (exists) {
            await api.put(`/versions/${versionId}`, {
              questionId,
              content,
              timestamp: Date.now(),
            });
          } else {
            await api.post(`/versions`, {
              userId,
              assignmentId,
              questionId,
              content,
              timestamp: Date.now(),
            });
          }
          lastSavedContentRef.current = content;
        } catch (error) {
          console.error("Error saving version: ", error);
        } finally {
          isSavingRef.current = false;
        }
      },
      [userId, assignmentId, questionId]
    );

    function getCompleteSentences(text) {
      const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
      return sentences.map((sentence) => sentence.trim());
    }

    const shouldSaveVersion = (newContent, oldContent) => {
      const newText = extractTextContent(newContent);
      const oldText = extractTextContent(oldContent);

      const newSentences = getCompleteSentences(newText);
      const oldSentences = lastCompleteSentencesRef.current;

      const isPasteEvent =
        Date.now() - lastPasteTimeRef.current < PASTE_TIMEOUT;

      if (isPasteEvent) {
        lastCompleteSentencesRef.current = newSentences;
        lastPasteContentRef.current = "";
        return true;
      } else if (newSentences.length != oldSentences.length) {
        lastCompleteSentencesRef.current = newSentences;
        return true;
      }

      return false;
    };

    const handlePaste = useCallback((pastedContent) => {
      lastPasteTimeRef.current = Date.now();
      lastPasteContentRef.current = pastedContent;
    }, []);

    useImperativeHandle(ref, () => ({
      handlePaste,
    }));

    useEffect(() => {
      if (
        currentContent &&
        shouldSaveVersion(currentContent, lastSavedContentRef.current)
      ) {
        saveVersion(extractTextContent(currentContent));
      }
    }, [currentContent, saveVersion]);

    return null;
  }
);

export default VersionControl;

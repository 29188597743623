import SubmissionTable from "./SubmissionTable";

const UngradedTable = ({ submissions, lateSubmissions, userId }) => (
  <SubmissionTable
    submissions={submissions}
    title="Ungraded Submissions"
    showGrade={false}
    lateSubmissions={lateSubmissions}
    userId={userId}
  />
);

export default UngradedTable;

import { Button } from "../../ui/button.jsx";
import { Input } from "../../ui/input.jsx";

const ChatInput = ({ message, setMessage, handleChat }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      handleChat();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-5 flex items-center space-x-2">
      <Input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Enter your message"
        className="flex-grow"
      />
      <Button type="submit" variant="default" size="default">
        Send Message
      </Button>
    </form>
  );
};

export default ChatInput;

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../ui/card.jsx";

const QuestionCardSkeleton = () => {
  return (
    <Card className="w-2/3 flex flex-col h-[800px] bg-white shadow-sm animate-pulse">
      <CardHeader>
        <CardTitle className="text-xl font-semibold text-gray-900">
          <div className="h-6 bg-primary/15 rounded w-1/3"></div>
        </CardTitle>
        <p className="text-sm text-gray-500">
          <div className="h-4 bg-primary/15 rounded w-1/4"></div>
        </p>
      </CardHeader>
      <CardContent className="flex-grow overflow-y-auto">
        <div className="space-y-4">
          <div className="h-4 bg-primary/15 rounded w-3/4"></div>
          <div className="h-4 bg-primary/15 rounded w-1/2"></div>
          <div className="h-4 bg-primary/15 rounded w-2/3"></div>
          <div className="h-20 bg-primary/15 rounded w-full"></div>
        </div>
      </CardContent>
      <CardFooter className="mt-auto">
        <div className="w-full">
          <div className="flex justify-between items-center p-4">
            <div className="h-8 bg-primary/15 rounded w-24"></div>
            <div className="h-4 bg-primary/15 rounded w-32"></div>
            <div className="h-8 bg-primary/15 rounded w-24"></div>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};

export default QuestionCardSkeleton;

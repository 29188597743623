import { useState } from "react";
import { Button } from "../../../ui/button.jsx";
import { Input } from "../../../ui/input.jsx";
import { useToast } from "../../../ui/use-toast";

import { Search } from "lucide-react";

const SearchBar = ({ questions, api }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const disabled = questions.length < 2;
  const { toast } = useToast();

  const handleSearch = (e) => {
    e.preventDefault();
    const foundIndex = questions.findIndex((question) =>
      question.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (foundIndex !== -1 && api) {
      api.scrollTo(foundIndex);
      toast({
        title: "Question found",
        description: `Scrolled to question ${foundIndex + 1}`,
      });
      setSearchQuery("");
    } else {
      toast({
        title: "No matching question",
        description: "No question matches your search query.",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSearch} className="mb-4 flex">
        <Input
          type="text"
          placeholder="Search questions..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mr-2"
          disabled={disabled}
        />
        <Button type="submit" disabled={disabled}>
          <Search className="h-4 w-4 mr-2" />
          Search
        </Button>
      </form>
    </div>
  );
};

export default SearchBar;

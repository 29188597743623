import PDFPrinter from '../utils/PDFPrinter';
import ShortAnswerRenderer from './ShortAnswerRenderer';

const ShortAnswerResponse = ({ question, answer, studentName }) => {
  return (
    <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <PDFPrinter question={question} answer={answer} studentName ={studentName} />
      </div>
      <ShortAnswerRenderer question={question} answer={answer} />
    </div>
  );
};

export default ShortAnswerResponse;

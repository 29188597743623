function generateEnrollmentCode() {
    const characters = 'ABCDEFGHJKMNOPQRSTUVWXYZ23456789';
    const codeLength = Math.floor(Math.random() * 2) + 5;  //either five or six
    let code = '';
  
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }
  
    return code;
  }

export default generateEnrollmentCode;
import { useParams } from "react-router-dom";
import BaseLayout from "../../../components/layout/BaseLayout";
import NavigationLayout from "../../../components/layout/NavigationLayout";
import AssignmentCarousel from "../../../components/teacher/SubmissionReview/AssignmentCarousel";
import { useUser } from "../../../Contexts/userContext";

const StudentSubmissionsReviewPage = () => {
  const { userId, assignmentId, courseId } = useParams();
  const { courses, assignments } = useUser();

  const course = courses.find((course) => course.id === courseId);
  const assignmentIds = course.assignments.map(
    (assignmentRef) => assignmentRef._path.segments.slice(-1)[0]
  );
  const courseAssignments = assignments.filter((assignment) =>
    assignmentIds.some((id) => id === assignment.id)
  );

  return (
    <div>
      <BaseLayout>
        <NavigationLayout>
          <AssignmentCarousel
            userId={userId}
            assignments={courseAssignments}
            initialAssignmentId={assignmentId}
          />
        </NavigationLayout>
      </BaseLayout>
    </div>
  );
};

export default StudentSubmissionsReviewPage;

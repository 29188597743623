import { BookText } from "lucide-react";
import SharedAssignmentList from "./SharedAssignmentList";

const CourseAssignmentList = ({
  inProgressAssignments,
  unstartedAssignments,
  userLoading,
  error,
}) => {
  return (
    <SharedAssignmentList
      title="Course Assignments"
      icon={<BookText className="mr-2" />}
      isLoading={userLoading}
      error={error}
      inProgressAssignments={inProgressAssignments}
      unstartedAssignments={unstartedAssignments}
    />
  );
};

export default CourseAssignmentList;

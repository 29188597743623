import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbowTheme } from "./RainbowTheme";

const CodeEditorSyntaxHighlighter = ({ localCode, preRef, language }) => (
  <pre 
    ref={preRef}
    className="w-full h-64 overflow-auto pointer-events-none font-mono p-4"
    aria-hidden="true"
  >
    <SyntaxHighlighter
      language={language}
      style={rainbowTheme}
      customStyle={{
        margin: 0,
        padding: 0,
        background: 'transparent',
      }}
      wrapLines={true}
      lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
    >
      {localCode || ' '}
    </SyntaxHighlighter>
  </pre>
);

export default CodeEditorSyntaxHighlighter;

import { Label } from "../../ui/label.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select.jsx";

const TimeSelector = ({ onTimeChange }) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="timezone" className="text-md">
        Timezone
      </Label>
      <Select onValueChange={(value) => onTimeChange({ target: { value } })}>
        <SelectTrigger id="timezone">
          <SelectValue placeholder="Select a timezone" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="US/Alaska">(GMT-09:00) Alaska</SelectItem>
          <SelectItem value="US/Pacific">
            (GMT-08:00) Pacific Time (US & Canada)
          </SelectItem>
          <SelectItem value="US/Arizona">(GMT-07:00) Arizona</SelectItem>
          <SelectItem value="US/Mountain">
            (GMT-07:00) Mountain Time (US & Canada)
          </SelectItem>
          <SelectItem value="US/Central">
            (GMT-06:00) Central Time (US & Canada)
          </SelectItem>
          <SelectItem value="US/Eastern">
            (GMT-05:00) Eastern Time (US & Canada)
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default TimeSelector;

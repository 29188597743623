import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select.jsx";

const TimeSelect = ({ value, onValueChange }) => {
  const [hours, setHours] = useState("12");
  const [minutes, setMinutes] = useState("00");
  const [ampm, setAmpm] = useState("AM");

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      setHours(hours.toString().padStart(2, "0"));
      setMinutes(minutes.toString().padStart(2, "0"));
      setAmpm(ampm);
    }
  }, [value]);

  const handleTimeChange = (type, newValue) => {
    let updatedHours = parseInt(hours);
    let updatedMinutes = parseInt(minutes);
    let updatedAmpm = ampm;

    if (type === "hours") {
      updatedHours = parseInt(newValue);
    } else if (type === "minutes") {
      updatedMinutes = parseInt(newValue);
    } else if (type === "ampm") {
      updatedAmpm = newValue;
    }

    if (updatedAmpm === "PM" && updatedHours !== 12) {
      updatedHours += 12;
    } else if (updatedAmpm === "AM" && updatedHours === 12) {
      updatedHours = 0;
    }

    const currentDate = new Date(value);
    currentDate.setHours(updatedHours, updatedMinutes);
    onValueChange(currentDate.getTime());
  };

  return (
    <div className="flex space-x-2">
      <Select
        value={hours}
        onValueChange={(value) => handleTimeChange("hours", value)}
      >
        <SelectTrigger className="w-[70px]">
          <SelectValue placeholder="HH" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
            <SelectItem key={hour} value={hour.toString().padStart(2, "0")}>
              {hour.toString().padStart(2, "0")}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Select
        value={minutes}
        onValueChange={(value) => handleTimeChange("minutes", value)}
      >
        <SelectTrigger className="w-[70px]">
          <SelectValue placeholder="MM" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
            <SelectItem key={minute} value={minute.toString().padStart(2, "0")}>
              {minute.toString().padStart(2, "0")}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Select
        value={ampm}
        onValueChange={(value) => handleTimeChange("ampm", value)}
      >
        <SelectTrigger className="w-[70px]">
          <SelectValue placeholder="AM/PM" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="AM">AM</SelectItem>
          <SelectItem value="PM">PM</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default TimeSelect;

import {
  ArrowLeftRight,
  Copy,
  GalleryVerticalEnd,
  MessageCircle,
} from "lucide-react";
import { useEffect, useState } from "react";
import { Badge } from "../../../ui/badge";
import { Button } from "../../../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../ui/tooltip";
import getQuestionIntegrityScore from "../Helpers/QuestionIntegrityScore";

const QuestionDetails = ({
  question,
  questionIntegrityScore,
  report,
  chatLogs,
  onOpenTimelineDialog,
  onOpenChatLogPopup,
  onOpenVersionHistory,
}) => {
  const questionHasText = ["short-answer", "essay", "coding"].includes(
    question.type
  );
  const [questionChats, setQuestionChats] = useState([]);
  const tabSwitchCount = report?.questions?.[question.id]?.tabSwitchCount ?? 0;
  const copyPasteEvents =
    report?.questions?.[question.id]?.copyPasteEvents ?? [];

  useEffect(() => {
    setQuestionChats(chatLogs?.questions?.[question.id] || []);
  }, [chatLogs, question]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-semibold mt-4">Integrity Insights</h1>
        {getQuestionIntegrityScore(questionIntegrityScore)}
      </div>
      <div className="grid grid-cols-1 gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Badge
                variant="outline"
                className="flex items-center justify-center p-2 w-full"
              >
                <ArrowLeftRight className="w-4 h-4 mr-2" />
                Tab Switches: {tabSwitchCount || 0}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <p>
                Number of times the student switched tabs during the question
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        {questionHasText && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={onOpenTimelineDialog}
                  aria-label="View copy/paste events"
                >
                  <Copy className="w-4 h-4 mr-2" />
                  Copy/Pastes: {copyPasteEvents.length || 0}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>View timeline of copy/paste events</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                className="w-full"
                onClick={onOpenChatLogPopup}
                disabled={questionChats.length === 0}
                aria-label="Open chat log"
              >
                <MessageCircle className="w-4 h-4 mr-2" />
                Chat Log ({questionChats.length || 0})
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>View chat log for this question</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        {questionHasText && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={onOpenVersionHistory}
                  aria-label="View version history"
                >
                  <GalleryVerticalEnd className="w-4 h-4 mr-2" />
                  Version History
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>View answer version history</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    </div>
  );
};

export default QuestionDetails;

import { Upload, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import api from "../../../api";
import { Alert, AlertDescription } from "../../ui/alert.jsx";
import { Button } from "../../ui/button.jsx";
import { Input } from "../../ui/input.jsx";
import { Label } from "../../ui/label.jsx";

const FileUpload = ({ question, questionNumber, answers, setAnswers }) => {
  const [error, setError] = useState(null);

  const [fileName, setFileName] = useState(() => {
    return answers[question.id]?.file?.name || "Choose File";
  });

  const fileInputRef = useRef(null);

  useEffect(() => {
    setFileName(answers[question.id]?.name || "Choose File");
  }, [question, answers]);

  const handleFileUpload = async (file) => {
    if (file) {
      const reader = new FileReader();
      const fileName = file.name;
      reader.onload = async (event) => {
        const base64String = event.target.result.split(",")[1];
        try {
          const response = await api.post("/file", {
            base64String,
            fileName,
          });
          const fileURL = response.data;
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [question.id]: {
              file: file,
              name: file.name,
              type: file.type,
              size: file.size,
              url: fileURL,
            },
          }));
          setFileName(file.name);
          setError(null);
        } catch (error) {
          console.error("Error uploading file:", error);
          setError(
            "An error occurred while uploading the file. Please try again."
          );
        }
      };
      reader.readAsDataURL(file);
    } else {
      setAnswers((prevAnswers) => {
        const newAnswers = { ...prevAnswers };
        delete newAnswers[question.id];
        return newAnswers;
      });
      setFileName("Choose File");
      setError(null);
    }
  };

  const getAcceptedFileTypes = () => {
    if (
      !question.acceptedFileTypes ||
      question.acceptedFileTypes.length === 0 ||
      question.acceptedFileTypes.includes("all")
    ) {
      return "All file types are accepted.";
    }
    return question.acceptedFileTypes.join(", ");
  };

  const isFileTypeAllowed = (file) => {
    if (
      !question.acceptedFileTypes ||
      question.acceptedFileTypes.length === 0 ||
      question.acceptedFileTypes.includes("all")
    ) {
      return true;
    }

    const fileExtension = "." + file.name.split(".").pop().toLowerCase();
    return question.acceptedFileTypes.some((type) => {
      const types = type.split(",").map((t) => t.trim().toLowerCase());
      return (
        types.includes(fileExtension) || types.includes(file.type.toLowerCase())
      );
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setError("File size should not exceed 5MB.");
        handleFileUpload(null);
      } else if (!isFileTypeAllowed(selectedFile)) {
        setError(
          `File type not allowed. Accepted file types are: ${getAcceptedFileTypes()}`
        );
        handleFileUpload(null);
      } else {
        handleFileUpload(selectedFile);
      }
    } else {
      handleFileUpload(null);
    }
  };

  const removeFile = () => {
    answers[question.id] = null;
    setFileName("Choose File");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleInputClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="space-y-4">
      <Label
        htmlFor={`file-upload-${questionNumber}`}
        className="text-lg font-semibold"
      >
        {question.label || "Upload File"}
      </Label>
      <div className="relative">
        <div
          className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors duration-200 ${
            fileName
              ? "bg-primary/10 border-primary/30"
              : "border-secondary/50 hover:border-primary/50"
          }`}
          onClick={handleInputClick}
        >
          {fileName ? (
            <div className="flex items-center justify-center space-x-2">
              <span className="text-primary font-medium">{fileName}</span>
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFile();
                }}
                className="text-destructive hover:text-destructive/80"
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ) : (
            <div className="space-y-2">
              <Upload className="mx-auto h-12 w-12 text-secondary" />
              <div className="text-sm text-secondary-foreground">
                Click to upload or drag and drop
              </div>
              <p className="text-xs text-secondary-foreground/70">
                {question.acceptedFileTypes
                  ? `Accepted file types: ${question.acceptedFileTypes.join(
                      ", "
                    )}`
                  : "Any file type accepted"}
              </p>
            </div>
          )}
        </div>
        <Input
          type="file"
          id={`file-upload-${questionNumber}`}
          onChange={handleFileChange}
          accept={
            question.acceptedFileTypes
              ? question.acceptedFileTypes.join(",")
              : "*"
          }
          className="hidden"
          ref={fileInputRef}
        />
      </div>
      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default FileUpload;

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../Contexts/userContext";
import AssignmentInterface from "../components/student/AssignmentInterface/AssignmentInterface";

const AssignmentInterfacePage = () => {
  const { assignmentId } = useParams();
  const {
    user,
    assignments,
    submissions,
    refreshUserInfo,
    loading: userLoading,
  } = useUser();

  const { assignment, initialAnswers } = useMemo(() => {
    const assignment = assignments.find(
      (assignment) => assignment.id === assignmentId
    );
    const submission = submissions.find(
      (submission) => submission.assignmentId === assignment?.id
    );
    const initialAnswers = submission ? submission.answers : {};
    return { assignment, initialAnswers };
  }, [assignmentId, assignments, submissions]);

  if (!assignment) {
    return <div>Assignment not found</div>;
  }

  return (
    <AssignmentInterface
      assignment={assignment}
      initialAnswers={initialAnswers}
      userId={user?.uid}
      refreshUserInfo={refreshUserInfo}
      loading={userLoading}
    />
  );
};

export default AssignmentInterfacePage;

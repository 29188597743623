import { AlertCircle, CheckCircle2, Clock } from "lucide-react";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../shared/utils/Timestamp";
import { Badge } from "../../../ui/badge.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table.jsx";

const SubmissionTable = ({
  submissions,
  title,
  showGrade,
  lateSubmissions,
  userId,
}) => {
  return (
    <Card className="w-full shadow-md">
      <CardHeader className="bg-primary/5">
        <CardTitle className="text-xl font-semibold text-primary">
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow className="bg-muted/50">
              <TableHead className="w-[30%] font-semibold">
                Assignment
              </TableHead>
              <TableHead className="font-semibold">Due</TableHead>
              <TableHead className="font-semibold">Submitted</TableHead>
              {showGrade ? (
                <TableHead className="font-semibold">Grade</TableHead>
              ) : (
                <TableHead className="font-semibold">Points Possible</TableHead>
              )}
              <TableHead className="font-semibold">Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {submissions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} className="h-24 text-center">
                  <span className="text-muted-foreground">
                    There are no items in this category.
                  </span>
                </TableCell>
              </TableRow>
            ) : (
              submissions.map((submission, index) => (
                <TableRow
                  key={index}
                  className="hover:bg-muted/50 transition-colors cursor-pointer"
                >
                  <Link
                    to={`/assignment/submissions/review/${submission.assignmentId}`}
                    className="contents"
                  >
                    <TableCell className="font-medium text-primary">
                      {submission.assignmentTitle}
                    </TableCell>
                    <TableCell>{formatTimestamp(submission.dueDate)}</TableCell>
                    <TableCell>
                      {formatTimestamp(submission.timestamp)}
                    </TableCell>
                    {showGrade ? (
                      <TableCell>
                        <span
                          className={`font-semibold ${
                            submission.totalScore / submission.totalPoints >=
                            0.7
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {submission.totalScore} / {submission.totalPoints}
                        </span>
                      </TableCell>
                    ) : (
                      <TableCell>{submission.totalPoints}</TableCell>
                    )}
                    <TableCell>
                      {lateSubmissions.includes(submission) ? (
                        <Badge
                          variant="destructive"
                          className="flex items-center gap-1"
                        >
                          <AlertCircle className="h-3 w-3" />
                          Late
                        </Badge>
                      ) : submission.timestamp ? (
                        <Badge
                          variant="default"
                          className="bg-green-500 flex items-center gap-1"
                        >
                          <CheckCircle2 className="h-3 w-3" />
                          On Time
                        </Badge>
                      ) : (
                        <Badge
                          variant="secondary"
                          className="flex items-center gap-1"
                        >
                          <Clock className="h-3 w-3" />
                          Pending
                        </Badge>
                      )}
                    </TableCell>
                  </Link>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default SubmissionTable;

import { useState, useEffect } from "react";
import api from "../../../api";
import { generateTimestamp } from "../../shared/utils/Timestamp";

const useChatHistory = (chatLogId) => {
  const [chatLogs, setChatLogs] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChatLogs = async () => {
      try {
        setLoading(true);
        setError(null);
        const chatLogResponse = await api.get(`/chatLogs/${chatLogId}`);
        const fetchedLogs = chatLogResponse.data.questions || {};
        setChatLogs(fetchedLogs);
      } catch (error) {
        console.error("Error fetching chat logs:", error);
        setError("Failed to fetch chat logs. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchChatLogs();
  }, [chatLogId]);

  const updateChatLog = (questionId, type, message) => {
    setChatLogs((prevChatLogs) => ({
      ...prevChatLogs,
      [questionId]: [
        ...(prevChatLogs[questionId] || []),
        { type: type, chat: message, timestamp: generateTimestamp() },
      ],
    }));
  };

  const saveLogs = async () => {
    const stringifiedLogs = JSON.stringify(chatLogs);
    try {
      await api.post("/chatLogs", {
        chatLogs: stringifiedLogs,
        logId: chatLogId,
      });
    } catch (error) {
      console.error("Error saving logs: ", error);
      setError("Failed to save logs. Please try again.");
    }
  };

  return { chatLogs, updateChatLog, loading, error, saveLogs };
};

export default useChatHistory;

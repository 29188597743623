import { AlertCircle, Users } from "lucide-react";
import { useEffect, useState } from "react";
import api from "../../../../api";
import { useUser } from "../../../../Contexts/userContext";
import { Alert, AlertDescription, AlertTitle } from "../../../ui/alert.jsx";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import { createStudentCourseMap } from "./Helper.js";
import RosterSkeleton from "./RosterSkeleton";
import RosterTable from "./RosterTable";

const MasterRoster = ({ courses, coursesLoading }) => {
  const [loading, setLoading] = useState(true);
  const [roster, setRoster] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [studentCourseMap, setStudentCourseMap] = useState({});
  const { user } = useUser();
  const userId = user.uid;

  const fetchStudents = async () => {
    setLoading(true);
    setError(null);

    try {
      const rosterPromises = courses.map((course) =>
        api.get(`/courses/${course.id}/roster`)
      );
      const rosterResponses = await Promise.all(rosterPromises);
      const allStudents = rosterResponses.flatMap((response) => response.data);

      const uniqueStudents = [
        ...new Map(
          allStudents.map((student) => [student.uid, student])
        ).values(),
      ];

      const studentCourseMap = createStudentCourseMap(courses, allStudents);
      setStudentCourseMap(studentCourseMap);

      const sortedRoster = uniqueStudents.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      setRoster(sortedRoster);
    } catch (error) {
      console.error("Error fetching roster:", error);
      setError("Failed to fetch the master roster. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!coursesLoading) {
      fetchStudents();
    }
  }, [userId, coursesLoading]);

  const filteredRoster = roster.filter((student) =>
    `${student.firstName} ${student.lastName} ${student.email}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const renderLink = (student) =>
    `/assignments/status/course/${studentCourseMap[student.uid]}/student/${
      student.uid
    }`;

  if (loading || coursesLoading) {
    return <RosterSkeleton master={true} />;
  }

  return (
    <Card className="w-full max-w-3xl mx-auto shadow-xl border-2 border-primary/20 hover:border-primary/30 transition-all duration-300">
      <CardHeader className="bg-primary text-primary-foreground rounded-md">
        <CardTitle className="text-2xl font-bold flex items-center">
          <Users className="mr-2 h-6 w-6" />
          Master Roster
        </CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        {error ? (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        ) : (
          <RosterTable
            loading={loading}
            filteredRoster={filteredRoster}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            renderLink={renderLink}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default MasterRoster;

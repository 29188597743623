import { useParams } from "react-router-dom";
import BaseLayout from "../../../components/layout/BaseLayout";
import NavigationLayout from "../../../components/layout/NavigationLayout";
import StudentSubmissionReview from "../../../components/student/AssignmentReview/SubmissionReview";
import { useUser } from "../../../Contexts/userContext";

const StudentReviewPage = () => {
  const { assignments, submissions, loading: userLoading } = useUser();
  const { assignmentId } = useParams();

  const assignment = assignments.find(
    (assignment) => assignmentId === assignment.id
  );
  const submission = submissions.find(
    (submission) => submission.assignmentId === assignmentId
  );

  return (
    <div>
      <BaseLayout>
        <NavigationLayout>
          <StudentSubmissionReview
            assignment={assignment}
            submission={submission}
            loading={userLoading}
          />
        </NavigationLayout>
      </BaseLayout>
    </div>
  );
};

export default StudentReviewPage;

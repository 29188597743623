import { useNavigate } from "react-router-dom";
import { useUser } from "../../Contexts/userContext";
import LoadingPage from "../LoadingPage";
import StudentHomepage from "./StudentHomepage";
import TeacherHomepage from "./TeacherHomepage";

function Homepage() {
  const { user, loading } = useUser();
  const navigate = useNavigate();

  if (loading) {
    return <LoadingPage />;
  }

  if (!user) {
    navigate("/login?showPrompt=true");
    return null;
  }

  return user.role === "student" ? <StudentHomepage /> : <TeacherHomepage />;
}

export default Homepage;

import { useState } from "react";

const useDialogs = () => {
  const [isTimelineDialogOpen, setIsTimelineDialogOpen] = useState(false);
  const [isChatLogPopupVisible, setIsChatLogPopupVisible] = useState(false);
  const [isVersionHistoryDialogOpen, setIsVersionHistoryDialogOpen] = useState(false);
  const [chatLogId, setChatLogId] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState("");

  const toggleTimelineDialog = (questionId) => {
    setSelectedQuestionId(questionId);
    setIsTimelineDialogOpen(!isTimelineDialogOpen);
  };

  const toggleChatLogPopup = (chatLogId, questionId) => {
    setChatLogId(chatLogId);
    setSelectedQuestionId(questionId);
    setIsChatLogPopupVisible(!isChatLogPopupVisible);
  };

  const toggleVersionHistoryDialog = (questionId) => {
    setSelectedQuestionId(questionId);
    setIsVersionHistoryDialogOpen(!isVersionHistoryDialogOpen);
  };

  return {
    isTimelineDialogOpen,
    isChatLogPopupVisible,
    isVersionHistoryDialogOpen,
    chatLogId,
    selectedQuestionId,
    toggleTimelineDialog,
    toggleChatLogPopup,
    toggleVersionHistoryDialog,
  };
};

export default useDialogs;

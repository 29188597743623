import { Label } from "../../../ui/label.jsx";
import { Textarea } from "../../../ui/textarea.jsx";

const Instructions = ({ instructions, onChange }) => {
  return (
    <div className="question-field">
      <Label htmlFor="instructions">Instructions:</Label>
      <Textarea
        id="instructions"
        value={instructions}
        placeholder="Enter question instructions"
        onChange={onChange}
      />
    </div>
  );
};

export default Instructions;

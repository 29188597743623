import api from "../../../api";
import { saveVersion } from "../../shared/VersionControl/VersionHistory";
import { generateTimestamp } from "../../shared/utils/Timestamp.js";

const assignmentPreviouslySaved = async (submissionId) => {
  try {
    const response = await api.get(`/submissions/${submissionId}/exists`);
    return response.data.exists;
  } catch (error) {
    console.error(error);
  }
};

const saveVersionHistory = async (userId, assignmentId, questions, answers) => {
  try {
    const savePromises = questions.map((question) => {
      //need to add coding later
      if (question.type === "essay" || question.type === "short-answer") {
        return saveVersion(
          userId,
          assignmentId,
          question.id,
          answers[question.id]
        );
      }
    });

    await Promise.all(savePromises);
  } catch (error) {
    console.error(error);
  }
};

const fetchAnswers = async (userId, assignmentId) => {
  const submissionId = `${userId}_${assignmentId}`;

  try {
    const existenceResponse = await api.get(
      `/submissions/${submissionId}/exists`
    );
    if (existenceResponse.data.exists) {
      const response = await api.get(`/submissions/${submissionId}`);

      return response.data.answers;
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchQuestions = async (assignmentId) => {
  try {
    const response = await api.get(`/assignments/${assignmentId}`);
    const questionData = await response.data.questions;
    return questionData;
  } catch (error) {
    console.error(error);
  }
};

const saveAndSubmit = async (
  userId,
  assignmentId,
  answers,
  integrityReport
) => {
  const submitTime = generateTimestamp();
  const submissionId = `${userId}_${assignmentId}`;
  const saved = await assignmentPreviouslySaved(submissionId);

  try {
    if (saved) {
      await api.put(`/submissions/${submissionId}`, {
        answers,
        submitTime,
        submitted: true,
        integrityReport,
      });
    } else {
      await api.post(`/submissions/`, {
        userId,
        assignmentId,
        answers,
        submitTime,
        submitted: true,
        integrityReport,
      });
    }
  } catch (error) {
    throw new Error("Error submitting assignment");
  }
};

const saveProgress = async (userId, assignmentId, answers) => {
  const submitTime = generateTimestamp();
  const submissionId = `${userId}_${assignmentId}`;
  const saved = await assignmentPreviouslySaved(submissionId);
  const integrityReport = { totalScore: "", questionScores: {} };
  try {
    if (saved) {
      await api.put(`/submissions/${submissionId}`, {
        assignmentId,
        answers,
        submitTime,
        submitted: false,
        integrityReport,
      });
    } else {
      await api.post(`/submissions/`, {
        userId,
        assignmentId,
        answers,
        submitTime,
        submitted: false,
        integrityReport,
      });
    }
  } catch (error) {
    throw new Error("Error saving assignment");
  }
};

const autograde = async (userId, assignmentId, questions, answers) => {
  const grades = {};
  //note: Autograde specifically does not assign values to
  //non MC/SA questions. Because the backend determines the grade status
  //by comparing the scores to question ratio.

  questions.forEach((question) => {
    let score = 0;
    if (question.type === "multiple-choice" || question.type === "select-all") {
      if (answers[question.id] && answers[question.id].trim() !== "") {
        if (question.type === "multiple-choice") {
          const correctAnswer = question.choices[question.correctChoices[0]];
          if (correctAnswer === answers[question.id]) {
            score = question.points;
          }
        } else if (question.type === "select-all") {
          const userAnswers = new Set(answers[question.id]);
          const correctAnswers = new Set(
            question.correctChoices.map((index) => question.choices[index])
          );

          if (
            userAnswers.size === correctAnswers.size &&
            [...userAnswers].every((answer) => correctAnswers.has(answer))
          ) {
            score = question.points;
          }
        }
        grades[question.id] = score;
      } else {
        grades[question.id] = 0;
      }
    }
  });

  await api.post("/grades", {
    userId,
    assignmentId,
    questions,
    scores: grades,
    comments: {},
  });

  return grades;
};

export const InterfaceService = {
  saveVersionHistory,
  assignmentPreviouslySaved,
  fetchAnswers,
  fetchQuestions,
  saveAndSubmit,
  saveProgress,
  autograde,
};

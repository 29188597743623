import React from "react";
import { Card, CardContent, CardHeader } from "../../../ui/card.jsx";
import { Skeleton } from "../../../ui/skeleton.jsx";
import { Tabs, TabsContent } from "../../../ui/tabs.jsx";

const CarouselSkeletonLoader = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <Card className="bg-background shadow-xl rounded-xl overflow-hidden border border-border">
        <CardHeader className="bg-muted p-6">
          <Skeleton className="h-8 w-3/4" />
        </CardHeader>
        <CardContent className="p-6">
          <Tabs defaultValue="question">
            <Skeleton className="h-10 w-48 mb-6" />
            <TabsContent value="question">
              <div className="flex justify-between items-center mb-4">
                <Skeleton className="h-6 w-24" />
                <Skeleton className="h-6 w-24" />
              </div>
              <Card className="mb-6">
                <CardContent className="p-4">
                  <Skeleton className="h-4 w-full mb-2" />
                  <Skeleton className="h-4 w-3/4 mb-2" />
                  <Skeleton className="h-4 w-1/2" />
                </CardContent>
              </Card>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <Skeleton className="h-8 w-3/4 mb-4" />
                  <Skeleton className="h-32 w-full mb-4" />
                  <Skeleton className="h-8 w-1/2 mb-4" />
                  <Skeleton className="h-24 w-full" />
                </div>
                <div>
                  <Skeleton className="h-8 w-3/4 mb-4" />
                  <Skeleton className="h-12 w-full mb-4" />
                  <Skeleton className="h-12 w-full mb-4" />
                  <Skeleton className="h-12 w-full" />
                </div>
              </div>
            </TabsContent>
          </Tabs>
          <div className="flex justify-between items-center mt-8">
            <Skeleton className="h-10 w-32" />
            <Skeleton className="h-10 w-32" />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CarouselSkeletonLoader;

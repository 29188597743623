import React from 'react';
import { Dialog, DialogContent } from "../../../ui/dialog.jsx";
import { Button } from "../../../ui/button.jsx";
import { CheckCircle2 } from "lucide-react";

const SuccessDialog = ({ isOpen, onClose, title, message }) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-[425px]">
      <div className="flex flex-col items-center justify-center p-6 text-center">
        <div className="rounded-full bg-primary/20 p-3 mb-4">
          <CheckCircle2 className="h-6 w-6 text-primary" />
        </div>
        <h2 className="text-2xl font-semibold text-primary mb-2">
          {title}
        </h2>
        {message && (
          <p className="text-muted-foreground mb-4">{message}</p>
        )}
        <Button
          onClick={onClose}
          className="bg-primary text-primary-foreground hover:bg-primary/90"
        >
          Close
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

export default SuccessDialog

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import { Badge } from "../../../ui/badge.jsx";
import { Button } from "../../../ui/button.jsx";
import { TableCell, TableRow } from "../../../ui/table.jsx";

const SubmissionRow = ({
  oneStudent,
  studentId,
  firstName,
  lastName,
  submission,
  initialGrade,
  assignment,
  courseId,
}) => {
  const navigate = useNavigate();
  const [grade, setGrade] = useState(initialGrade);
  const currentTime = new Date();
  const dueDate = assignment.dueDate;

  const renderSubmissionStatus = () => {
    if (!submission || !submission.submitted) {
      return (
        <>
          <Badge variant="destructive">Unsubmitted</Badge>
          {currentTime > dueDate && <Badge variant="destructive">Late</Badge>}
        </>
      );
    } else {
      const submissionTime = submission.timestamp;
      return (
        <>
          <Badge variant="success">Submitted</Badge>
          <Badge
            variant={submissionTime <= dueDate ? "secondary" : "destructive"}
          >
            {submissionTime <= dueDate ? "On-time" : "Late"}
          </Badge>
        </>
      );
    }
  };

  const renderIntegrityScore = () => {
    if (
      typeof submission?.totalIntegrityScore === "number" &&
      submission?.submitted
    ) {
      const score = submission.totalIntegrityScore;
      let backgroundColor = "";
      let textColor = "";

      if (score <= 25) {
        return <Badge variant="destructive">{score}</Badge>;
      } else if (score <= 50) {
        backgroundColor = "bg-orange-400 hover:bg-orange-500";
        textColor = "text-orange-800";
      } else if (score <= 75) {
        backgroundColor = "bg-yellow-300 hover:bg-yellow-400";
        textColor = "text-yellow-800";
      } else if (score > 75) {
        return <Badge variant="success">{score}</Badge>;
      }

      return (
        <Badge className={`${backgroundColor} ${textColor}`}>{score}</Badge>
      );
    }
    return <Badge variant="secondary">N/A</Badge>;
  };

  const renderGradedStatus = () => {
    if (!grade || !submission?.submitted) {
      //   return <Badge variant="secondary">N/A</Badge>;
    } else {
      const status = grade.gradeStatus;

      if (status === "partially") {
        return (
          <Badge className="bg-yellow-300 hover:bg-yellow-400 text-yellow-800">
            Partially
          </Badge>
        );
      } else if (status === "graded") {
        return <Badge variant="success">Graded</Badge>;
      } else {
        return <Badge variant="destructive">Ungraded</Badge>;
      }
    }
  };

  const renderPublishStatus = () => {
    if (!grade || grade.gradeStatus !== "graded" || !submission?.submitted) {
      // return <Badge variant="secondary">N/A</Badge>;
    } else {
      return (
        <Badge variant={grade.published ? "success" : "warning"}>
          {grade.published ? "Published" : "Unpublished"}
        </Badge>
      );
    }
  };

  const renderGrade = () => {
    if (!grade || grade.gradeStatus !== "graded") {
      return `- / ${assignment.totalPoints}`;
    }
    return `${grade.totalScore} / ${assignment.totalPoints}`;
  };

  const handlePublishClick = async () => {
    try {
      if (grade.published) {
        setGrade((prevGrade) => ({ ...prevGrade, published: false }));
        await api.put(`/grades/${studentId}_${assignment.id}/unpublish`);
      } else {
        setGrade((prevGrade) => ({ ...prevGrade, published: true }));
        await api.put(`/grades/${studentId}_${assignment.id}/publish`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGrade = () => {
    if (oneStudent) {
      navigate(
        `/course/${courseId}/user/${studentId}/submissions/${assignment.id}`
      );
    } else {
      navigate(
        `/assignment/${assignment.id}/user/${studentId}/course/${courseId}/submissions`
      );
    }
  };

  return (
    <TableRow>
      <TableCell
        className="py-4 px-6 text-sm font-medium text-gray-900"
        style={submission?.submitted ? { cursor: "pointer" } : undefined}
        onClick={submission?.submitted ? handleGrade : undefined}
      >
        {oneStudent ? assignment.title : `${lastName}, ${firstName}`}
      </TableCell>
      <TableCell>{renderSubmissionStatus()}</TableCell>
      <TableCell>{renderIntegrityScore()}</TableCell>
      <TableCell>{renderGradedStatus()}</TableCell>
      <TableCell>{renderPublishStatus()}</TableCell>
      <TableCell>{renderGrade()}</TableCell>
      <TableCell>
        <div className="flex space-x-2">
          {submission?.submitted && (
            <Button
              onClick={handleGrade}
              className="bg-primary hover:bg-secondary text-white font-medium py-2 px-4 rounded-md"
            >
              {grade.gradeStatus === "graded" ? "Review" : "Grade"}
            </Button>
          )}
          {grade?.gradeStatus === "graded" && (
            <Button
              onClick={handlePublishClick}
              className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 rounded-md"
            >
              {grade.published ? "Unpublish" : "Publish"}
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SubmissionRow;

import React from "react";
import HeaderSkeleton from "./HeaderSkeleton";
import QuestionCardSkeleton from "./QuestionCardSkeleton";
import ChatCardSkeleton from "./ChatCardSkeleton";

const AssignmentInterfaceSkeleton = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <HeaderSkeleton />

          <div className="flex gap-6">
            <QuestionCardSkeleton />
            <ChatCardSkeleton />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AssignmentInterfaceSkeleton;

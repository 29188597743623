import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Contexts/userContext";
import CourseCarousel from "../../shared/CourseCarousel/CourseCarousel";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card.jsx";
import CourseEnroller from "../Enrollment/CourseEnroller";

const StudentCourseList = ({ courses, loading }) => {
  const navigate = useNavigate();
  const { user, refreshUserInfo } = useUser();

  const handleRefresh = async () => {
    try {
      await refreshUserInfo();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCourseClick = (course) => {
    navigate(`/course/${course.id}/student`, { state: { course } });
  };

  if (!loading && courses?.length === 0) {
    return (
      <Card className="w-full">
        <CardContent>
          <div className="w-full max-w-3xl mx-auto px-4 py-16 text-center">
            <h2 className="text-2xl font-semibold mb-4">No Courses Yet</h2>
            <p className="text-gray-500 mb-8">
              You haven't joined any courses. Get started by joining your first
              course!
            </p>
            <CourseEnroller onJoin={handleRefresh} />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-xl font-semibold">
          <div className="flex justify-between items-center">
            <p>Your Courses</p>
            <CourseEnroller onJoin={handleRefresh} />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <CourseCarousel
          courses={courses}
          onCourseClick={handleCourseClick}
          role={"student"}
          loading={loading}
        />
      </CardContent>
    </Card>
  );
};

export default StudentCourseList;

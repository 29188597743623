import DOMPurify from "dompurify";
import CodeEditor from "../Coding/CodeEditor";

const CodingRenderer = ({ question, answer }) => {
  const sanitizedAnswer = DOMPurify.sanitize(answer);
  const doNothing = (code) => {}; //Don't need to do anything because we're not editing the editor.
  //But function reference is necessary
  return (
    <CodeEditor
      initialCode={sanitizedAnswer}
      language={question.codingLanguage}
      disabled={true}
      onChange={doNothing}
    />
  );
};

export default CodingRenderer;

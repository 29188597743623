import { CircleAlert, Download, FileIcon } from "lucide-react";
import { useState } from "react";
import api from "../../../api";
import { Alert, AlertDescription, AlertTitle } from "../../ui/alert.jsx";
import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button.jsx";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card.jsx";
import { formatFileSize } from "../utils/utils";

const FileUploadResponse = ({ question, answer }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await api.get("/file", {
        params: { fileURL: answer.url },
        responseType: "blob",
      });

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = answer.name || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file: ", error);
      setError("Failed to download the file. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <Alert variant="destructive">
        <CircleAlert className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div>
      <div className="space-y-4">
        <Badge
          variant="secondary"
          className="mb-2 text-xs font-semibold bg-secondary text-secondary-foreground"
        >
          File Upload
        </Badge>
        <h3 className="text-lg font-semibold text-foreground">
          {question.text}
        </h3>
        <p className="font-medium text-sm text-muted-foreground">
          Response:
        </p>
      </div>
      <div className="flex justify-center items-center w-full h-full">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <FileIcon className="h-5 w-5" />
              File Details
            </CardTitle>
          </CardHeader>

          {answer ? (
            <>
              <CardContent>
                <div className="space-y-2">
                  <p>
                    <strong>Name:</strong> {answer.name}
                  </p>
                  <p>
                    <strong>Type:</strong> {answer.type}
                  </p>
                  <p>
                    <strong>Size:</strong> {formatFileSize(answer.size)}
                  </p>
                </div>
              </CardContent>
              <CardFooter>
                <Button
                  onClick={handleDownload}
                  disabled={isLoading}
                  className="w-full"
                >
                  {isLoading ? (
                    "Downloading..."
                  ) : (
                    <>
                      <Download className="mr-2 h-4 w-4" /> Download File
                    </>
                  )}
                </Button>
              </CardFooter>
            </>
          ) : (
            <CardContent>
              <p>
                <strong>No submission for this question</strong>
              </p>
            </CardContent>
          )}
        </Card>
      </div>
    </div>
  );
};

export default FileUploadResponse;

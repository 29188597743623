import { useEffect, useState } from "react";
import api from "../../../../api";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card.jsx";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table.jsx";
import SubmissionListSkeleton from "./SubmissionListSkeleton";
import SubmissionRow from "./SubmissionRow";

const AssignmentSubmissions = ({ course, assignment }) => {
  const [submissionsData, setSubmissionsData] = useState([]);
  const assignmentId = assignment?.id;
  const courseId = course.id;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const rosterResponse = await api.get(`/courses/${course.id}/roster`);
        let fetchedRoster = rosterResponse.data;
        fetchedRoster.sort((a, b) => a.lastName.localeCompare(b.lastName));

        const submissionsAndGrades = await Promise.all(
          fetchedRoster.map(async (student) => {
            const submissionId = `${student.uid}_${assignmentId}`;
            const [submissionResponse, gradeResponse] = await Promise.all([
              api.get(`/submissions/${submissionId}`),
              api.get(`/grades/assignment/${assignmentId}/user/${student.uid}`),
            ]);

            return {
              uid: student.uid,
              lastName: student.lastName,
              firstName: student.firstName,
              submission: submissionResponse.data,
              grade: gradeResponse.data,
            };
          })
        );

        setSubmissionsData(submissionsAndGrades);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("An error occurred while fetching the data.");
        setLoading(false);
      }
    };

    fetchData();
  }, [course, assignmentId]);

  if (loading || !assignment) return <SubmissionListSkeleton />;
  if (error) return <div>{error}</div>;

  return (
    <Card className="w-full shadow-lg">
      <CardHeader className="bg-gray-50">
        <CardTitle className="text-2xl font-bold text-primary">
          Submissions for {assignment.title}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Student Name</TableHead>
              <TableHead>Submission Status</TableHead>
              <TableHead>Integrity Score</TableHead>
              <TableHead>Graded Status</TableHead>
              <TableHead>Published Status</TableHead>
              <TableHead>Grade</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {submissionsData.length > 0 ? (
              submissionsData.map((data) => (
                <SubmissionRow
                  key={data.uid}
                  studentId={data.uid}
                  firstName={data.firstName}
                  lastName={data.lastName}
                  oneStudent={false}
                  submission={data.submission}
                  initialGrade={data.grade}
                  assignment={assignment}
                  courseId={courseId}
                />
              ))
            ) : (
              <TableRow className="text-sm text-gray-500 items-center">
                No elements available for this table
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default AssignmentSubmissions;

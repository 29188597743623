import api from "../../../../api";
import { stripHtml } from "../../../shared/utils/HTMLTools";

export async function calculateIntegrityScore(
  userId,
  assignmentId,
  questions,
  answers
) {
  const reportId = `${userId}_${assignmentId}`;

  try {
    const report = await fetchOverview(reportId);
    const questionScores = calculateQuestionScores(report, questions, answers);
    const totalScore = calculateTotalScore(questionScores, questions);
    return { totalScore, questionScores };
  } catch (error) {
    console.error("Error calculating integrity score: ", error);
    throw error;
  }
}

async function fetchOverview(reportId) {
  try {
    const response = await api.get(`/reports/${reportId}/overview`);
    return response.data;
  } catch (error) {
    console.error("Error fetching report overview: ", error);
    throw error;
  }
}

function calculateQuestionScores(report, questions, answers) {
  const scores = {};

  questions.forEach((question) => {
    if (!report) {
      scores[question.id] = 100;
    } else {
      const questionReport = report[question.id] || {};
      const tabSwitches = questionReport.tabSwitchCount || 0;
      const copyPastes = questionReport.copyPasteEvents || [];
      const averagePastedSegmentLength =
        calculateAverageCopyPasteLength(copyPastes);
      const numberOfCopyPastes = copyPastes.length;
      const score = calculateQuestionScore(
        question,
        tabSwitches,
        copyPastes,
        averagePastedSegmentLength,
        numberOfCopyPastes,
        answers[question.id]
      );
      scores[question.id] = Math.round(score * 10) / 10; // to the tenth place
    }
  });

  return scores;
}

function calculateQuestionScore(
  question,
  tabSwitches,
  copyPastes,
  averagePastedSegmentLength,
  numberOfCopyPastes,
  answer
) {
  let score = 100; // Start with full integrity
  const strippedAnswer = stripHtml(answer || "").trim();

  score -= getTabSwitchPenalty(tabSwitches);

  switch (question.type) {
    case "short-answer":
    case "essay":
      score -= getTextBasedPenalty(
        strippedAnswer,
        copyPastes,
        averagePastedSegmentLength,
        numberOfCopyPastes,
        question.type
      );
      break;
    case "coding":
      score -= getCodingPenalty(
        strippedAnswer,
        copyPastes,
        averagePastedSegmentLength,
        numberOfCopyPastes
      );
      break;
    default:
      break;
  }

  return Math.max(Math.min(score, 100), 0);
}

function getTextBasedPenalty(
  answer,
  copyPastes,
  averagePastedSegmentLength,
  numberOfCopyPastes,
  type
) {
  let penalty = 0;
  const totalAnswerLength = answer.length;
  let portionPasted = 0;

  copyPastes.forEach((event) => {
    const pastedSegment = event.pastedSegment;
    if (answer.includes(pastedSegment)) {
      portionPasted += pastedSegment.length / totalAnswerLength;
    } else {
      const superSetOfPastedSegment = getSuperSet(pastedSegment);
      superSetOfPastedSegment.some((set) => {
        if (answer.includes(set)) {
          portionPasted += set.length / totalAnswerLength;
          return true;
        }
        return false;
      });
    }
  });

  penalty += portionPasted === 0 ? 0 : Math.min(portionPasted * 100, 100);
  penalty += 5 * numberOfCopyPastes;

  return penalty;
}

function getCodingPenalty(
  answer,
  copyPastes,
  averagePastedSegmentLength,
  numberOfCopyPastes
) {
  let penalty = getTextBasedPenalty(
    answer,
    copyPastes,
    averagePastedSegmentLength,
    numberOfCopyPastes,
    "coding"
  );
  return penalty * 1.2;
}

function getSuperSet(pastedSegment) {
  const words = pastedSegment.split(" ");
  let superSet = [];

  for (let start = 0; start < words.length; start++) {
    for (let end = words.length; end > start + 2; end--) {
      const subset = words.slice(start, end).join(" ");
      superSet.push(subset);
    }
  }

  return superSet.sort((a, b) => b.length - a.length);
}

function getTabSwitchPenalty(tabSwitches) {
  if (tabSwitches === 0) return 0;
  else {
    return Math.min(tabSwitches * 2, 25);
  }
}

function calculateAverageCopyPasteLength(copyPastes) {
  if (copyPastes.length === 0) return 0;
  const totalLength = copyPastes.reduce(
    (sum, event) => sum + event.pastedSegment.length,
    0
  );
  return totalLength / copyPastes.length;
}

function calculateTotalScore(scores, questions) {
  const weightedScores = Object.values(scores).map((score) => {
    // apply additional weight to scores below 25 and 15
    if (score < 25 && score > 15) {
      return score * 0.75;
    } else if (score < 15) {
      return score * 0.5;
    }
    return score;
  });

  const totalWeightedScore = weightedScores.reduce(
    (sum, score) => sum + score,
    0
  );

  const maxPossibleScore = questions.length * 100;

  const finalScore = Math.max(
    Math.round((totalWeightedScore / maxPossibleScore) * 100),
    0
  );

  return finalScore;
}

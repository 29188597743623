import { useParams } from "react-router-dom";
import BaseLayout from "../../../components/layout/BaseLayout";
import NavigationLayout from "../../../components/layout/NavigationLayout";
import SubmissionCarousel from "../../../components/teacher/SubmissionReview/SubmissionCarousel";
import { useUser } from "../../../Contexts/userContext";

const CourseSubmissionsReviewPage = () => {
  const { userId, assignmentId, courseId } = useParams();
  const { courses, assignments } = useUser();

  const course = courses.find((course) => course.id === courseId);
  const assignment = assignments.find(
    (assignment) => assignment.id === assignmentId
  );
  return (
    <div>
      <BaseLayout>
        <NavigationLayout>
          <SubmissionCarousel
            userId={userId}
            assignment={assignment}
            course={course}
          />
        </NavigationLayout>
      </BaseLayout>
    </div>
  );
};

export default CourseSubmissionsReviewPage;

import CodeEditor from "../../../shared/Coding/CodeEditor";
import { stripHtml } from "../../../shared/utils/HTMLTools";
import { Button } from "../../../ui/button.jsx";
import { Checkbox } from "../../../ui/checkbox.jsx";
import Choice from "../ChoiceLogic/Choice";
import CodingLanguageSelector from "./CodingLanguageSelector";
import FileTypeSelector from "./FileTypeSelector";
import Instructions from "./Instructions";
import Points from "./Points";
import Text from "./Text";
import Type from "./Type";

const Question = ({
  question,
  questions,
  setQuestions,
  index,
  assignmentBotPermissions,
}) => {
  const onTextChange = (e) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...question, text: e.target.value };
    setQuestions(updatedQuestions);
  };

  const onInstructionsChange = (e) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...question, instructions: e.target.value };
    setQuestions(updatedQuestions);
  };

  const onPointsChange = (e) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...question, points: e.target.value };
    setQuestions(updatedQuestions);
  };

  const onTypeChange = (newType) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...question, type: newType };
    setQuestions(updatedQuestions);
  };

  //A future note: For some reason, choices have to be different. I assume the issue is with useState synchronization. However,
  //if we try to just set questions to newQuestions, the state remains the same, therefore not deleting the choice on screen. The only
  // solution I could find was setting question equal to the new index value directly. This patch, although probably bad practice, works.
  //I will keep it this way until someone more educated can help me out. Cheers. 6/26/24.

  const onChoicesChange = (newChoices) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...question, choices: newChoices || [] };
    question = newQuestions[index];
    setQuestions(newQuestions);
  };

  const onCorrectChoicesChange = (correctChoices) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...question, correctChoices: correctChoices || [] };
    question = newQuestions[index];
    setQuestions(newQuestions);
  };

  const handleDelete = () => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleAddChoice = () => {
    const currentChoices = question.choices;
    const updatedChoices = [...currentChoices, []];
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...question,
      choices: updatedChoices,
      correctChoices: question.correctChoices.map((choiceIndex) =>
        choiceIndex >= updatedChoices.length - 1 ? choiceIndex + 1 : choiceIndex
      ),
    };
    setQuestions(updatedQuestions);
  };

  const onAcceptedFileTypesChange = (types) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...question,
      acceptedFileTypes: types,
    };
    setQuestions(updatedQuestions);
  };

  const onSelectedCodingLanguageChange = (type) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...question,
      codingLanguage: type,
    };
    setQuestions(updatedQuestions);
  };

  const onStarterCodeChange = (codeHtml) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...question,
      codeHtml: codeHtml,
      rawStarterCode: stripHtml(codeHtml),
    };
    setQuestions(updatedQuestions);
  };

  const onChatbotPermissionsChange = (checked) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...question,
      chatbotPermitted: checked,
    };
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      <div className="flex items-center mb-2">
        <h2 className="mr-4">Question {index + 1}</h2>
        <label className="flex items-center text-sm text-gray-600 whitespace-nowrap">
          <Checkbox
            checked={question.chatbotPermitted}
            onCheckedChange={onChatbotPermissionsChange}
            className="mr-2"
            disabled={!assignmentBotPermissions}
          />
          Bot Assistance
        </label>
      </div>

      <Text onChange={onTextChange} text={question.text} />
      <Instructions
        onChange={onInstructionsChange}
        instructions={question.instructions}
      />
      <Points onChange={onPointsChange} points={question.points} />
      <Type onChange={onTypeChange} type={question.type} />

      {question.type === "file-upload" && (
        <FileTypeSelector
          acceptedTypes={question.acceptedFileTypes || []}
          onAcceptedTypesChange={onAcceptedFileTypesChange}
        />
      )}
      {question.type === "coding" && (
        <CodingLanguageSelector
          selectedLanguage={question.codingLanguage}
          onLanguageChange={onSelectedCodingLanguageChange}
        />
      )}
      {question.type === "coding" && question.codingLanguage && (
        <CodeEditor
          initialCode={question.codeHtml}
          onChange={onStarterCodeChange}
          language={question.codingLanguage}
        />
      )}

      {(question.type === "multiple-choice" ||
        question.type === "select-all") && (
        <>
          {question.choices.length < 6 && (
            <Button className="mb-4" onClick={handleAddChoice}>
              Add Choice
            </Button>
          )}
          {question.choices.map((choice, index) => (
            <div key={index}>
              <Choice
                choices={question.choices}
                onChoicesChange={onChoicesChange}
                correctChoices={question.correctChoices}
                onCorrectChoicesChange={onCorrectChoicesChange}
                index={index}
                questionType={question.type}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Question;

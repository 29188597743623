import CodeResponse from "./CodeResponse";
import EssayResponse from "./EssayResponse";
import FileUploadResponse from "./FileUploadResponse";
import MultipleChoiceResponse from "./MultipleChoiceResponse";
import SelectAllResponse from "./SelectAllResponse";
import ShortAnswerResponse from "./ShortAnswerResponse";

export const renderQuestion = (question, answers, studentName=null, isGraded=true) => {
  switch (question.type) {
    case "short-answer":
      return <ShortAnswerResponse question={question} answer={answers[question.id]} studentName={studentName} />;
    case "essay":
      return <EssayResponse question={question} answer={answers[question.id]} studentName={studentName} />;
    case "multiple-choice":
      return <MultipleChoiceResponse question={question} answer={answers[question.id]} showCorrectAnswer={isGraded}/>;
    case "select-all":
      return <SelectAllResponse question={question} answer={answers[question.id]} showCorrectAnswer={isGraded}/>;
    case "file-upload":
      return <FileUploadResponse question={question} answer={answers[question.id]} />;
    case "coding": 
      return <CodeResponse question={question} answer={answers[question.id]} />;
    default:
      return <div>Unsupported question type.</div>;
  }
};

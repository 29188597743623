import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../api";

const CACHE_KEY = "userInfoCache";
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

const useUserInfo = (userProp) => {
  const [user, setUser] = useState(userProp);
  const [userData, setUserData] = useState(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRY) {
        return data;
      }
    }
    return {
      courses: [],
      assignments: [],
      submissions: [],
    };
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateCache = useCallback((newData) => {
    setUserData(newData);
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        data: newData,
        timestamp: Date.now(),
      })
    );
  }, []);

  const fetchUserData = useCallback(async () => {
    if (!user) return null;

    let courses, assignments, submissions;

    if (user.role === "student") {
      const enrollmentResponse = await api.get("/enrollments", {
        params: { userId: user.uid },
      });
      courses = enrollmentResponse.data.enrollments;

      const assignmentsPromises = courses.map((course) =>
        api.get(`/courses/${course.id}/assignments`)
      );
      const assignmentsResponses = await Promise.all(assignmentsPromises);
      assignments = assignmentsResponses.flatMap((response) => response.data);

      const submissionPromises = assignments.map((assignment) =>
        api.get(`/submissions/${user.uid}_${assignment.id}`)
      );
      const submissionResponses = await Promise.all(submissionPromises);
      submissions = submissionResponses
        .map((response) => response.data)
        .filter(Boolean);
    } else if (user.role === "teacher") {
      const coursesResponse = await api.get(`/users/${user.uid}/owned-courses`);
      courses = coursesResponse.data;

      const assignmentPromises = courses.map((course) =>
        api.get(`/courses/${course.id}/assignments`)
      );
      const assignmentResponses = await Promise.all(assignmentPromises);
      assignments = assignmentResponses.flatMap((response) => response.data);

      submissions = []; // Teachers don't have submissions
    }

    return { courses, assignments, submissions };
  }, [user]);

  const fetchData = useCallback(
    async (isBackgroundFetch = true) => {
      if (!user || (user.role !== "student" && user.role !== "teacher")) {
        return;
      }
      if (!isBackgroundFetch) {
        setLoading(true);
      }
      setError(null);

      try {
        const fetchedData = await fetchUserData();
        if (fetchedData) {
          updateCache(fetchedData);
        }
      } catch (error) {
        console.error(`Error fetching ${user.role} data:`, error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [user, fetchUserData, updateCache]
  );

  const resetUserInfo = useCallback(() => {
    setUserData({
      courses: [],
      assignments: [],
      submissions: [],
    });
    localStorage.removeItem(CACHE_KEY);
    setError(null);
  }, []);

  const updateSubmissions = useCallback(
    (assignmentId, newAnswers) => {
      setUserData((prevUserData) => {
        const updatedSubmissions = prevUserData.submissions.map((submission) =>
          submission.assignmentId === assignmentId
            ? { ...submission, answers: newAnswers }
            : submission
        );
        const newData = {
          ...prevUserData,
          submissions: updatedSubmissions,
        };
        updateCache(newData);
        return newData;
      });
    },
    [updateCache]
  );

  useEffect(() => {
    if (userProp) {
      setUser(userProp);
    }
  }, [userProp]);

  useEffect(() => {
    if (user) {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRY) {
          setUserData(data);
        } else {
          fetchData();
        }
      } else {
        fetchData(false);
      }
    }
  }, [user, fetchData]);

  useEffect(() => {
    const refreshInterval = setInterval(() => fetchData(true), CACHE_EXPIRY);
    return () => clearInterval(refreshInterval);
  }, [fetchData]);

  const memoizedUserData = useMemo(() => userData, [userData]);

  return {
    ...memoizedUserData,
    loading,
    error,
    refreshUserInfo: (isBackgroundFetch = true) => fetchData(isBackgroundFetch),
    updateSubmissions,
    resetUserInfo,
  };
};

export default useUserInfo;

import { useCallback } from "react";
import { useUser } from "../Contexts/userContext";

export const useAssignments = () => {
  const { user, submissions, assignments } = useUser();

  const getUpcomingAssignments = useCallback(
    (course = null) => {
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
      let upcomingAssignments = assignments.filter((assignment) => {
        const dueDate = new Date(assignment.dueDate);
        return dueDate <= oneWeekFromNow && dueDate >= new Date();
      });

      if (course) {
        upcomingAssignments = getCourseAssignments(course, upcomingAssignments);
      }

      return upcomingAssignments.sort((a, b) => b.dueDate - a.dueDate);
    },
    [assignments]
  );

  const getUnsubmittedAssignments = useCallback(
    (course = null) => {
      const submittedAssignmentIds = new Set(
        submissions
          .filter((submission) => submission.submitted)
          .map((submission) => submission.assignmentId)
      );
      let unsubmittedAssignments = assignments.filter(
        (assignment) => !submittedAssignmentIds.has(assignment.id)
      );
      if (course) {
        unsubmittedAssignments = getCourseAssignments(
          course,
          unsubmittedAssignments
        );
      }

      return unsubmittedAssignments;
    },
    [assignments, submissions]
  );

  const getInProgressAssignments = useCallback(
    (course = null) => {
      if (submissions) {
        const submissionMap = new Map(
          submissions?.map((submission) => [
            submission.assignmentId,
            submission,
          ])
        );

        let inProgress = [];

        for (const assignment of assignments) {
          const submission = submissionMap.get(assignment.id);
          if (submission && !submission.submitted) {
            inProgress.push(assignment);
          }
        }
        if (course) {
          inProgress = getCourseAssignments(course, inProgress);
        }

        return inProgress;
      } else {
        return [];
      }
    },
    [assignments, submissions]
  );

  const getUnstartedAssignments = useCallback(
    (course = null) => {
      if (submissions) {
        const submissionMap = new Map(
          submissions.map((submission) => [submission.assignmentId, submission])
        );

        let unstarted = [];

        for (const assignment of assignments) {
          const submission = submissionMap.get(assignment.id);
          if (!submission) {
            unstarted.push(assignment);
          }
        }
        if (course) {
          unstarted = getCourseAssignments(course, unstarted);
        }
        return unstarted;
      }
      return [];
    },
    [assignments, submissions]
  );

  const getCourseAssignments = (course, assignments) => {
    const courseAssignments = course.assignments.filter(Boolean);
    const courseAssignmentsSet = new Set(
      courseAssignments.map(
        (assignmentRef) => assignmentRef._path.segments.slice(-1)[0]
      )
    );
    return assignments.filter((assignment) =>
      courseAssignmentsSet.has(assignment.id)
    );
  };

  return {
    getUpcomingAssignments,
    getUnsubmittedAssignments,
    getInProgressAssignments,
    getUnstartedAssignments,
    getCourseAssignments,
  };
};
